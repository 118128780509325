import { helper } from '@ember/component/helper';
import moment from 'moment-timezone';

import { get } from 'teamtailor/utils/get';
import { AllModels } from 'teamtailor/models';

export interface GroupedModelsByDate {
  date: string;
  firstInYear?: boolean;
  models: AllModels[];
}

export function groupedModelsByDate([sortedModels, dateProperty]: [
  AllModels[] | undefined,
  string,
]): GroupedModelsByDate[] {
  const models = sortedModels ? sortedModels.slice() : [];

  const modelsByDate: GroupedModelsByDate[] = [];

  models.forEach((model, index) => {
    const date = getDate(model, dateProperty);

    if (date) {
      const formattedDate = date.format('YYYYMMDD');

      const existingGroup = modelsByDate.find(
        (group) => group.date === formattedDate
      );

      if (existingGroup) {
        existingGroup.models.push(model);
      } else {
        modelsByDate.push({
          date: formattedDate,
          firstInYear: isFirstInYear(models, index, dateProperty),
          models: [model],
        });
      }
    }
  });

  return modelsByDate;
}

function isFirstInYear(
  models: AllModels[],
  index: number,
  dateProperty: string
): boolean {
  const currentModelDate = getDate(models[index], dateProperty);
  const previousModelDate = getDate(models[index - 1], dateProperty);

  if (currentModelDate && previousModelDate) {
    const isSameYear = currentModelDate.isSame(previousModelDate, 'year');
    return !isSameYear;
  }

  return true;
}

function getDate(
  model: AllModels | undefined,
  dateProperty: string
): moment.Moment | null {
  if (!model) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const date = get(model, dateProperty as any);

  if (typeof date === 'string' || date instanceof Date) {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      return momentDate;
    }
  }

  return null;
}

export default helper(groupedModelsByDate);
