import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { TableColumn } from 'teamtailor/components/table/container';
import { tracked } from '@glimmer/tracking';

const RECRUITING_FIRMS_COLUMNS: TableColumn[] = [
  {
    label: 'settings.external_recruitment.recruiting_firms.name_singular',
  },
  {
    label: 'settings.external_recruitment.recruiters.name_plural',
  },
];

export default class RecruitingFirmsController extends Controller {
  @service declare current: Current;

  @tracked isOpen = true;

  columns = RECRUITING_FIRMS_COLUMNS;

  initController() {
    this.isOpen = true;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.external-recruitment.recruiting-firms': RecruitingFirmsController;
  }
}
