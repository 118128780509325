import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Core::DropdownMenu\n  @tooltip={{t \"common.more_options\"}}\n  @appearance=\"secondary\"\n  @icon=\"ellipsis-vertical\"\n  class={{class-names\n    \"absolute bottom-12 right-12 opacity-0 group-hover:opacity-100\"\n  }}\n  {{on \"click\" (stop-propagation)}}\n  {{on \"focus\" @onFocus}}\n  as |menu|\n>\n  <menu.button\n    @icon=\"pen\"\n    @text={{t \"common.edit\"}}\n    {{on \"click\" (fn @onEditImage @image)}}\n  />\n  <menu.button\n    @icon=\"tag\"\n    @text={{t \"common.tags\"}}\n    {{on \"click\" (fn @onEditImage @image)}}\n  />\n  <menu.button\n    @icon=\"cloud-arrow-down\"\n    @text={{t \"common.download\"}}\n    {{on \"click\" this.downloadImage}}\n  />\n  <menu.button\n    @icon=\"trash-can\"\n    @text={{t \"common.delete\"}}\n    {{on \"click\" (fn @onRemoveImage @image)}}\n  />\n</Core::DropdownMenu>", {"contents":"<Core::DropdownMenu\n  @tooltip={{t \"common.more_options\"}}\n  @appearance=\"secondary\"\n  @icon=\"ellipsis-vertical\"\n  class={{class-names\n    \"absolute bottom-12 right-12 opacity-0 group-hover:opacity-100\"\n  }}\n  {{on \"click\" (stop-propagation)}}\n  {{on \"focus\" @onFocus}}\n  as |menu|\n>\n  <menu.button\n    @icon=\"pen\"\n    @text={{t \"common.edit\"}}\n    {{on \"click\" (fn @onEditImage @image)}}\n  />\n  <menu.button\n    @icon=\"tag\"\n    @text={{t \"common.tags\"}}\n    {{on \"click\" (fn @onEditImage @image)}}\n  />\n  <menu.button\n    @icon=\"cloud-arrow-down\"\n    @text={{t \"common.download\"}}\n    {{on \"click\" this.downloadImage}}\n  />\n  <menu.button\n    @icon=\"trash-can\"\n    @text={{t \"common.delete\"}}\n    {{on \"click\" (fn @onRemoveImage @image)}}\n  />\n</Core::DropdownMenu>","moduleName":"teamtailor/components/media-library/masonry-list-image/tools/image.hbs","parseOptions":{"srcName":"teamtailor/components/media-library/masonry-list-image/tools/image.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import imageUrl from 'teamtailor/utils/image-url';
import downloadFile from 'teamtailor/utils/download-file';

export default class MediaLibraryMasonryListImageToolsImageComponent extends Component {
  @action
  removeImage() {
    this.args.onRemoveImage(this.args.image);
  }

  @action
  downloadImage() {
    const { image } = this.args;
    const url = imageUrl(image, 'original');
    const imageName = image.filename ? image.filename : 'image';

    fetch(url)
      .then((response) => response.blob())
      .then((image) => {
        downloadFile(URL.createObjectURL(image), imageName);
      });
  }
}
