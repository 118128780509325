import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import SettingsOrganizationRolesEditController from 'teamtailor/controllers/settings/organization/roles/edit';
import CareerSiteLanguageBaseRoute from 'teamtailor/routes/career-site-language-base';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

export default class NewRoute extends CareerSiteLanguageBaseRoute {
  @service declare store: Store;
  @service declare current: Current;

  model() {
    return this.store.createRecord('role', {
      languageCode: this.defaultLanguageCode,
    });
  }

  afterModel() {
    this.controllerName = 'settings.organization.roles.edit';
  }

  resetController(
    controller: SettingsOrganizationRolesEditController,
    isExiting: boolean
  ) {
    if (isExiting && get(controller.model, 'isNew')) {
      get(controller.model, 'pickedQuestions').invoke('unloadRecord');

      if (this.current.company.hasFeature('career_site_languages')) {
        get(controller.model, 'translations').invoke('unloadRecord');
      }

      controller.model.unloadRecord();
    }
  }
}
