import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  type=\"button\"\n  class=\"btn-toggle {{if this.showAdvanced ' open'}}\"\n  {{on \"click\" (toggle \"showAdvanced\" this)}}\n>\n  <Icon @icon=\"xmark\" class=\"btn-toggle__icon\" />\n  {{#if this.showAdvanced}}\n    {{t \"components.show_advanced.hide_advanced_settings\"}}\n  {{else}}\n    {{t \"components.show_advanced.show_advanced_settings\"}}\n  {{/if}}\n\n</button>\n\n{{#ember-smooth/if this.showAdvanced}}\n  {{yield}}\n{{/ember-smooth/if}}", {"contents":"<button\n  type=\"button\"\n  class=\"btn-toggle {{if this.showAdvanced ' open'}}\"\n  {{on \"click\" (toggle \"showAdvanced\" this)}}\n>\n  <Icon @icon=\"xmark\" class=\"btn-toggle__icon\" />\n  {{#if this.showAdvanced}}\n    {{t \"components.show_advanced.hide_advanced_settings\"}}\n  {{else}}\n    {{t \"components.show_advanced.show_advanced_settings\"}}\n  {{/if}}\n\n</button>\n\n{{#ember-smooth/if this.showAdvanced}}\n  {{yield}}\n{{/ember-smooth/if}}","moduleName":"teamtailor/components/show-advanced.hbs","parseOptions":{"srcName":"teamtailor/components/show-advanced.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface ShowAdvancedComponentArgs {
  showAdvanced: boolean;
}

export default class ShowAdvancedComponent extends Component<ShowAdvancedComponentArgs> {
  @tracked showAdvanced = false;
}
