import Model, {
  belongsTo,
  hasMany,
  attr,
  AsyncBelongsTo,
  AsyncHasMany,
} from '@ember-data/model';
import { inject as service } from '@ember/service';
import Server from 'teamtailor/services/server';
import CareerSiteModel from './career-site';
import SectionChangeModel from './section-change';
import NavigationItemModel from './navigation-item';
import { PushPayloadArg } from '.';

export default class CareerSiteHeaderModel extends Model {
  @service declare server: Server;

  @belongsTo('career-site') declare careerSite: AsyncBelongsTo<CareerSiteModel>;
  @belongsTo('section-change')
  declare sectionChange: AsyncBelongsTo<SectionChangeModel>;

  @hasMany('navigation-item')
  declare navigationItems: AsyncHasMany<NavigationItemModel>;

  @attr('string') declare appearance: string;
  @attr('string') declare customBackgroundColor: string;
  @attr('string') declare customNavigationBackground: string;
  @attr('string') declare customNavigationColor: string;
  @attr('string') declare customNavigationHighlightColor: string;
  @attr('string') declare customTextColor: string;
  @attr('string') declare secondaryButton: string;
  @attr('string', { defaultValue: 'center' }) declare logoPosition: string;
  @attr('boolean') declare unpublishedChanges: boolean;
  @attr('boolean', { defaultValue: true, allowNull: true })
  declare showMenuText: boolean;

  @attr('boolean', { defaultValue: true, allowNull: true })
  declare showShareButton: boolean;

  async publish() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'publish',
    });
    this.store.pushPayload(response);
  }

  async availablePagePublications() {
    return this.server.memberAction(this, {
      action: 'available_page_publications',
      method: 'GET',
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'career-site-header': CareerSiteHeaderModel;
  }
}
