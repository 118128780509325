import Server from 'teamtailor/services/server';
import Service, { inject as service } from '@ember/service';
import CurrentService from 'teamtailor/services/current';
import { isEmpty } from '@ember/utils';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

interface JWTPayload {
  exp: number;
}

interface TokenResponse {
  token: string;
}

export default class AboardService extends Service {
  @service declare current: CurrentService;
  @service declare server: Server;

  _token: string = '';
  _expiration: number | null = null;

  @action
  async token() {
    const currentTime = Date.now() / 1000;
    if (!this._token || (this._expiration && this._expiration < currentTime)) {
      await this.fetchToken();
    }

    return this._token;
  }

  @action
  async fetchToken() {
    await this.fetchTokenTask.perform();

    return this._token;
  }

  fetchTokenTask = task(async () => {
    const response = await this.server
      .fetch(`app/companies/${this.current.company.uuid}/api/aboard_token`, {
        method: 'POST',
      })
      .then((response) => response.json() as Promise<TokenResponse>);
    this._token = response.token;
    this._expiration = this.expiresAt(this._token);
  });

  private expiresAt(token: string): number | null {
    if (isEmpty(token)) return null;

    const [, payload] = token.split('.') as [string, string];
    const decodedPayload = JSON.parse(window.atob(payload)) as JWTPayload;
    return decodedPayload.exp;
  }
}

declare module '@ember/service' {
  interface Registry {
    'aboard-service': AboardService;
  }
}
