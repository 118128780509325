import {
  ApolloClientOptions,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { inject as service } from '@ember/service';
import ApolloService from 'ember-apollo-client/services/apollo';
import ENV from 'teamtailor/config/environment';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import AboardService from './aboard-service';
import IntlService from 'ember-intl/services/intl';

export default class AboardApolloService extends ApolloService {
  @service private declare current: Current;
  @service private declare flashMessages: FlashMessageService;
  @service private declare aboardService: AboardService;
  @service private declare intl: IntlService;

  clientOptions(): ApolloClientOptions<NormalizedCacheObject> {
    return {
      connectToDevTools: ENV.environment === 'development',
      link: this.link(),
      cache: new InMemoryCache({
        typePolicies: {
          Job: {
            fields: {
              locations: {
                merge(_existing = [], incoming: unknown[]) {
                  return [...incoming];
                },
              },
            },
          },
        },
      }),

      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },

        query: {
          fetchPolicy: 'network-only',
        },

        mutate: {},
      },
    };
  }

  link(): ApolloLink {
    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            // eslint-disable-next-line no-console
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          });
        }

        if (networkError) {
          this.flashMessages.error(
            this.intl.t('jobs.edit.stages.aboard.error')
          );
        }

        forward(operation);
      }
    );

    const authMiddleware = setContext(async (_request, context) => {
      const token = await this.aboardService.token();

      Object.assign(context, {
        headers: {
          'X-Teamtailor-Token': token,
        },
      });

      return context;
    });

    const httpLink = new HttpLink({
      uri: `${ENV.httpOrHttps}://api.${ENV.ABOARD_HOST}/graphql`,
      credentials: 'include',
    });

    return ApolloLink.from([errorLink, authMiddleware, httpLink]);
  }
}

declare module '@ember/service' {
  interface Registry {
    'aboard-apollo': AboardApolloService;
  }
}
