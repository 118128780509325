import { setStyles, animate as smoothAnimate } from '.';

const newRow = <T>(object: T, opt = {}) => ({ ...opt, object });

type Options = { animate?: boolean } | undefined;

type ElTypes = 'beforeEl' | 'el';

type Row<T> = { object: T; el?: HTMLElement; beforeEl?: HTMLElement };

type OnInsertInfo = { type: ElTypes; index: number };

type InsertOptions<Row> = Options & {
  onInsert?: (row: Row, info: OnInsertInfo) => Promise<void>;
};

export class AnimateArray<T> extends Array {
  constructor(...args: T[]) {
    super(...(args as number[]));

    this.forEach((row, index) => {
      this[index] = newRow(row);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  syncMethodReplacement(): any {
    throw new Error(
      'use async version of this method instead. Use `{animate: false}` option if you dont want to animate`'
    );
  }

  push = this.syncMethodReplacement;
  pushObject = this.syncMethodReplacement;
  removeObject = this.syncMethodReplacement;
  removeAt = this.syncMethodReplacement;
  insertAt = this.syncMethodReplacement;

  async asyncRemoveAt(i: number, { animate = true }: Options = {}) {
    const row: Row<T> = this[i];

    if (animate) {
      const { el } = row;
      const betweenEl = row.beforeEl?.isConnected
        ? row.beforeEl
        : this[i + 1]?.beforeEl;

      await smoothAnimate.to([betweenEl, el], {
        opacity: 0,
        transform: 'scale(0.9)',
      });

      await smoothAnimate.to([el, betweenEl], {
        height: 0,
      });
    }

    super.removeAt(i);
  }

  objectAt(i: number) {
    return super.objectAt(i).object;
  }

  asyncInsertAt(
    i: number,
    object: T,
    { animate = true, onInsert }: InsertOptions<Row<T>> = {}
  ) {
    return new Promise<void>((resolve) => {
      super.insertAt(
        i,
        newRow(object, {
          onInsert: async (row: Row<T>, info: OnInsertInfo) => {
            if (row.el) {
              if (onInsert) {
                await onInsert(row, info);
              } else {
                if (animate) {
                  setStyles([row.beforeEl, row.el], {
                    opacity: 0,
                  });

                  await Promise.all([
                    smoothAnimate.from(row.beforeEl, { height: 0 }),
                    smoothAnimate.from(row.el, {
                      height: 0,
                      transform: 'scale(0.9)',
                    }),
                  ]);

                  setStyles([row.beforeEl, row.el], { opacity: 1 });

                  await Promise.all([
                    smoothAnimate.from(row.beforeEl, { opacity: 0 }),
                    smoothAnimate.from(row.el, {
                      opacity: 0,
                      transform: 'scale(0.9)',
                    }),
                  ]);
                }
              }
            }

            if (info.type === 'el') {
              resolve();
            }
          },
        })
      );
    });
  }

  asyncPushObject(object: T, options?: Options) {
    return this.asyncInsertAt(this.length, object, options);
  }

  asyncRemoveObject(object: T) {
    const i = this.findIndex((row) => object === row.object);

    return this.asyncRemoveAt(i);
  }

  get onlyObjects() {
    return this.map(({ object }) => object);
  }
}
