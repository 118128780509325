import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import ErrorMessageService from 'teamtailor/services/error-message';
import Store from '@ember-data/store';
import FlipperService from 'teamtailor/services/flipper';

export default class PaymentsInAppRoute extends ScrollToTopRoute {
  @service declare current: Current;
  @service declare errorMessage: ErrorMessageService;
  @service declare store: Store;
  @service declare flipper: FlipperService;

  async model() {
    let paymentProcessorSetting =
      await this.current.company.paymentProcessorSetting;

    if (!paymentProcessorSetting?.id) {
      paymentProcessorSetting = this.store.createRecord(
        'payment-processor-setting'
      );
    }

    return paymentProcessorSetting;
  }
}
