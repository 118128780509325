import Service, { inject as service } from '@ember/service';
import ENV from 'teamtailor/config/environment';
import Server from './server';
import IntlService from 'ember-intl/services/intl';
import { enqueueTask } from 'ember-concurrency';
import MomentService from 'ember-moment/service/moment';

const LOCALE_PATHS = Object.freeze({
  ar: '/translations/ar.json',
  az: '/translations/az.json',
  da: '/translations/da.json',
  de: '/translations/de.json',
  el: '/translations/el.json',
  en: '/translations/en.json',
  'en-GB': '/translations/en-gb.json',
  es: '/translations/es.json',
  et: '/translations/et.json',
  fi: '/translations/fi.json',
  fr: '/translations/fr.json',
  'fr-CA': '/translations/fr-ca.json',
  he: '/translations/he.json',
  hu: '/translations/hu.json',
  it: '/translations/it.json',
  lt: '/translations/lt.json',
  lv: '/translations/lv.json',
  nl: '/translations/nl.json',
  no: '/translations/no.json',
  nn: '/translations/nn.json',
  pl: '/translations/pl.json',
  pt: '/translations/pt.json',
  'pt-BR': '/translations/pt-br.json',
  ro: '/translations/ro.json',
  sk: '/translations/sk.json',
  sv: '/translations/sv.json',
  cs: '/translations/cs.json',
  'zh-HK': '/translations/zh-hk.json',
  'zh-TW': '/translations/zh-tw.json',
});

export default class TranslationsService extends Service {
  @service declare server: Server;
  @service declare intl: IntlService;
  @service declare moment: MomentService;

  async setLocale(locale: keyof typeof LOCALE_PATHS) {
    if (locale === this.intl.primaryLocale) {
      return;
    }

    return this.setLocaleTask.perform(locale);
  }

  setLocaleTask = enqueueTask(async (locale: keyof typeof LOCALE_PATHS) => {
    let localePath: string = LOCALE_PATHS[locale];

    if (ENV.environment === 'development') {
      localePath = ENV.EMBER_ASSETS_ROOT_PATH + localePath;
    }

    const momentLocale = locale === 'no' ? 'nb' : locale;
    this.moment.setLocale(momentLocale);

    const translations = await this.server
      .fetch(localePath, {
        credentials: 'omit',
      })
      .then((res) => res.json());

    this.intl.locale = locale;
    this.intl.addTranslations(locale, translations);
  });
}

declare module '@ember/service' {
  interface Registry {
    translations: TranslationsService;
  }
}
