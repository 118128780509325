import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isOpen}}\n  {{hide-scrollbar-without-jump}}\n  {{#in-element this.modalElement insertBefore=null}}\n    <div\n      class=\"pointer-events-auto absolute inset-0 isolate\"\n      ...attributes\n      {{did-insert this.onOpen}}\n    >\n      <div\n        role=\"button\"\n        {{on \"click\" this.handleBackdropClick}}\n        {{ember-smooth/animate in=(hash opacity=0) duration=300}}\n        class={{class-names\n          \"absolute inset-0 bg-zinc-800/50\"\n          @backdropClass\n          (if this.closeOnBackdropClick \"cursor-pointer\" \"cursor-default\")\n        }}\n        {{did-insert (set this \"backdropEl\")}}\n      ></div>\n      <div class=\"grid h-dvh place-items-center\">\n        {{yield this.api}}\n      </div>\n    </div>\n  {{/in-element}}\n{{/if}}\n{{did-insert (fn (optional @onRegister) this.api)}}", {"contents":"{{#if this.isOpen}}\n  {{hide-scrollbar-without-jump}}\n  {{#in-element this.modalElement insertBefore=null}}\n    <div\n      class=\"pointer-events-auto absolute inset-0 isolate\"\n      ...attributes\n      {{did-insert this.onOpen}}\n    >\n      <div\n        role=\"button\"\n        {{on \"click\" this.handleBackdropClick}}\n        {{ember-smooth/animate in=(hash opacity=0) duration=300}}\n        class={{class-names\n          \"absolute inset-0 bg-zinc-800/50\"\n          @backdropClass\n          (if this.closeOnBackdropClick \"cursor-pointer\" \"cursor-default\")\n        }}\n        {{did-insert (set this \"backdropEl\")}}\n      ></div>\n      <div class=\"grid h-dvh place-items-center\">\n        {{yield this.api}}\n      </div>\n    </div>\n  {{/in-element}}\n{{/if}}\n{{did-insert (fn (optional @onRegister) this.api)}}","moduleName":"teamtailor/components/core/modal/base.hbs","parseOptions":{"srcName":"teamtailor/components/core/modal/base.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { EkEvent, KeyboardService, keyResponder, onKey } from 'ember-keyboard';
import { KeyCodes } from 'teamtailor/constants/key-codes';
import { argDefault } from 'teamtailor/utils/arg-default';
import { inject as service } from '@ember/service';
import { animate, setStyles } from 'teamtailor/ember-smooth';
import Transition from '@ember/routing/-private/transition';
import { DEFAULT_BOX_WRAPPER_NAME } from 'teamtailor/constants/modal';

interface CoreModalBaseArgs {
  closeOnBackdropClick?: boolean;
  closeOnEscape?: boolean;
  isOpen?: boolean;
  onClose?: () => Promise<Transition> | Transition | undefined;
  keyboardPriority?: number;
  onBackdropInsert?: (el: HTMLElement) => void;
  defaultTransitionOut?: boolean;
  boxWrapperName?: string;
  boxWrapperEl: HTMLElement;
}

export type CoreModalBaseApi = {
  onClose: () => void;
  isOpen: boolean;
  keyboardPriority: number;
  boxWrapperEl: HTMLElement;
};

@keyResponder
export default class CoreModalBase extends Component<CoreModalBaseArgs> {
  @service declare keyboard: KeyboardService;

  @argDefault closeOnBackdropClick = true;
  @argDefault closeOnEscape = true;
  @argDefault defaultTransitionOut = true;
  @argDefault declare boxWrapperEl: HTMLElement;

  declare backdropEl: HTMLElement;

  @tracked _keyboardPriority = 1;
  get keyboardPriority() {
    return this.args.keyboardPriority ?? this._keyboardPriority;
  }

  set keyboardPriority(val) {
    this._keyboardPriority = val;
  }

  get keyboardActivated() {
    return this.isOpen;
  }

  @tracked _isOpen = true;
  get isOpen() {
    return this.args.isOpen ?? this._isOpen;
  }

  set isOpen(val) {
    this._isOpen = val;
  }

  get modalElement() {
    return document.getElementById('ember-teamtailor-modal');
  }

  @action handleBackdropClick() {
    if (this.closeOnBackdropClick) {
      this.handleClose();
    }
  }

  get api() {
    const parentThis = this;
    return {
      onClose: this.handleClose,
      get isOpen() {
        return parentThis.isOpen;
      },

      get keyboardPriority() {
        return parentThis.keyboardPriority;
      },
    };
  }

  @action
  async handleClose(
    domUpdateCallback?: typeof this.args.onClose
  ): Promise<unknown> {
    domUpdateCallback =
      typeof domUpdateCallback === 'function'
        ? domUpdateCallback
        : this.args.onClose;

    this.backdropEl.style.viewTransitionName = 'core-modal-backdrop';

    if (!this.defaultTransitionOut || !document.startViewTransition) {
      return domUpdateCallback?.();
    }

    const boxWrapperName = this.args.boxWrapperName || DEFAULT_BOX_WRAPPER_NAME;

    this.args.boxWrapperEl.style.viewTransitionName = boxWrapperName;

    const duration = 300;

    const unsetDocStyles = setStyles(document.documentElement, {
      '--view-transition-name-root': 'none',
      '--view-transition-duration': `${duration}ms`,
    });

    const transition = document.startViewTransition(async () => {
      const onCloseReturn = domUpdateCallback?.();
      if (onCloseReturn) {
        if ('followRedirects' in onCloseReturn) {
          await onCloseReturn.followRedirects();
        }

        try {
          await onCloseReturn;
        } catch (e) {
          if (e instanceof Error && e.name !== 'TransitionAborted') {
            throw e;
          }
        }
      }
    });

    await Promise.all([transition.ready, transition.updateCallbackDone]);

    await animate.to(
      document.documentElement,
      {
        transform: 'scale(1.1)',
        opacity: 0,
      },
      {
        duration,
        pseudoElement: `::view-transition-old(${boxWrapperName})`,
      }
    );

    unsetDocStyles({ await: transition.finished });
  }

  @action onOpen() {
    const highestPriorityResponder = this.keyboard.sortedResponders[0];

    if (highestPriorityResponder !== this) {
      const highestActivePriority =
        this.keyboard.sortedResponders[0]?.keyboardPriority ?? 0;

      this.keyboardPriority = highestActivePriority + 1;
    }
  }

  @onKey(KeyCodes.ESC)
  handleEscape(_e: Event, ekEvent: EkEvent) {
    if (this.closeOnEscape) {
      if (this.args.isOpen === undefined) {
        this.isOpen = false;
      }

      ekEvent.stopPropagation();
      this.handleClose();
    }
  }

  /**
   * ArrowLeft and ArrowRight are blocked because of candidate modals navigation with arrows.
   * Modals over candiate modal should not propagate down to candidate modal.
   */
  @onKey('ArrowRight')
  @onKey('ArrowLeft')
  stopKeyResponderPropagation(_e: Event, ekEvent: EkEvent) {
    ekEvent.stopPropagation();
  }
}
