import { compare } from '@ember/utils';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action, setProperties } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { createTrigger, saveTrigger } from 'teamtailor/utils/triggers';
import { get } from 'teamtailor/utils/get';
import TriggerWebhookModel from 'teamtailor/models/trigger/webhook';

export default class JobsEditStagesController extends Controller {
  @service current;
  @service store;
  @service flipper;

  @tracked currentTrigger = null;
  @tracked currentTriggerStage = null;

  @tracked showAddStageModal = false;

  @tracked isOnReject = false;

  get job() {
    return this.model;
  }

  get inboxStage() {
    return this.fixedStages.find((stage) => stage.inbox);
  }

  get hiredStage() {
    return this.fixedStages.find((stage) => stage.hired);
  }

  get stages() {
    return get(this, 'job.jobDetail.stages').filter(
      (item) => !get(item, 'isDeleted')
    );
  }

  get fixedStages() {
    return this.stages.filter((stage) => !stage.isEditable);
  }

  get dragStages() {
    return this.stages.filter((stage) => stage.isEditable);
  }

  get dragStagesSorted() {
    const everyEditableHasRowOrderPosition = this.dragStages.every((s) => {
      return get(s, 'rowOrderPosition') !== undefined;
    });

    const sortField = everyEditableHasRowOrderPosition
      ? 'rowOrderPosition'
      : 'rowOrder';

    return [...this.dragStages].sort((a, b) =>
      compare(get(a, sortField), get(b, sortField))
    );
  }

  setRowOrderPositions(list) {
    list.forEach((stage, index) => {
      const rowOrderPosition = stage.inbox
        ? 0
        : stage.hired
          ? list.length
          : index;

      stage.rowOrderPosition = rowOrderPosition;
    });
  }

  @action
  editTrigger(trigger) {
    if (this.currentTrigger !== trigger) {
      this.currentTrigger = trigger;
    }

    this.currentTriggerStage = get(trigger, 'stage');
  }

  @action
  handleTriggerClose(reason) {
    if (this.currentTrigger) {
      if (reason === 'closed') {
        if (this.currentTrigger instanceof TriggerWebhookModel) {
          const mappedPartnerCriteria = get(
            this.currentTrigger,
            'mappedPartnerCriteria'
          ).toArray();

          mappedPartnerCriteria.filterBy('isNew').invoke('unloadRecord');

          mappedPartnerCriteria
            .filterBy('hasDirtyAttributes')
            .invoke('rollbackAttributes');
        }

        if (get(this.currentTrigger, 'isNew')) {
          this.currentTrigger.unloadRecord();
        } else if (get(this.currentTrigger, 'hasDirtyAttributes')) {
          this.currentTrigger.rollbackAttributes();
        }
      }

      this.currentTrigger = null;
    }

    this.currentTriggerStage = null;
  }

  @action
  handleTriggerDestroy() {
    this.currentTrigger.destroyRecord();
  }

  @action
  handleTriggerSave() {
    if (!get(this.currentTrigger.stage, 'isNew')) {
      saveTrigger(this.currentTrigger);
    }
  }

  @action
  createTrigger(
    stage,
    isOnReject,
    type,
    partnerActivationOrPointerEvent,
    subtype
  ) {
    const isPointerEvent =
      partnerActivationOrPointerEvent instanceof PointerEvent;

    setProperties(this, {
      currentTrigger: createTrigger(
        this,
        stage,
        type,
        isOnReject,
        !isPointerEvent && partnerActivationOrPointerEvent
          ? get(partnerActivationOrPointerEvent, 'id')
          : null,
        subtype
      ),

      currentTriggerStage: stage,
    });
  }

  @action
  addStageWithType(stageNameRecord, stageType) {
    const lastStage = this.dragStagesSorted[this.dragStagesSorted.length - 1];
    const rowOrderPosition = lastStage ? lastStage.rowOrderPosition + 1 : 0;
    const rowOrder = lastStage ? lastStage.rowOrder + 1 : 0;

    get(this, 'job.jobDetail.stages').createRecord({
      name: stageNameRecord.id,
      stageType,
      rowOrderPosition,
      rowOrder,
    });

    this.setRowOrderPositions(this.dragStagesSorted);

    this.showAddStageModal = false;
  }

  @action
  addStage() {
    this.showAddStageModal = true;
  }

  @action
  getStage(lidOrRecord) {
    return typeof lidOrRecord === 'string'
      ? this.stageFromLid(lidOrRecord)
      : lidOrRecord;
  }

  get stageNames() {
    return this.stages.map((stage) => stage.name);
  }

  get unusedStageNamesObjects() {
    return get(this.current.company, 'stageNames').filter(
      (stageName) => !this.stageNames.includes(stageName.id)
    );
  }

  @action
  reorderItems({
    draggedItem,
    sourceList,
    sourceIndex,
    targetList,
    targetIndex,
  }) {
    if (sourceIndex === targetIndex) {
      return;
    }

    sourceList.removeAt(sourceIndex);
    targetList.insertAt(targetIndex, draggedItem);

    this.setRowOrderPositions(targetList);
  }
}
