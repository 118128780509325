import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { getProperties, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import moment from 'moment-timezone';
import { get } from 'teamtailor/utils/get';

export default class JobApplicationModel extends Model {
  @service permissions;

  @belongsTo('job') job;
  @belongsTo('candidate') candidate;
  @belongsTo('promotion') promotion;
  @belongsTo('stage') stage;
  @belongsTo('reject-reason') rejectReason;
  @belongsTo('canned-response') rejectTemplate;
  @belongsTo('stage', {
    inverse: 'activeJobApplications',
  })
  activeStage;

  @belongsTo('stage', {
    inverse: 'rejectedJobApplications',
  })
  rejectedStage;

  @hasMany('activity') activities;
  @hasMany('nps-response', { async: false }) npsResponses;
  @hasMany('prediction', { async: false }) predictions;
  @hasMany('picked-location', { async: false }) pickedLocations;
  @hasMany('job-application-trigger') jobApplicationTriggers;

  @attr aboardOnboardingId;
  @attr('number') candidateId;
  @attr('number') jobId;
  @attr('date') createdAt;
  @attr('date') lastMovedAt;
  @attr('string') externalJobTitle;
  @attr('string') jobInternalName;
  @attr('string') coverLetter;
  @attr('string') referringSite;
  @attr('string') referringUrl;
  @attr('string') metaText;
  @attr('date') rejectedAt;
  @attr('number') rowOrder;
  @attr('number') rowOrderPosition;
  @attr('date') sourcedAt;
  @attr('boolean') unread;
  @attr('number') scoresDisasterCount;
  @attr('number') scoresNegativeCount;
  @attr('number') scoresBlankCount;
  @attr('number') scoresPositiveCount;
  @attr('number') scoresSuperCount;
  @attr('boolean') fromHandheld;
  @attr('number') lastManualMessageId;
  @attr('number') match;
  @attr('string') newHirePageUrl;
  @attr('string') rejectSubject;
  @attr('string') rejectBody;
  @attr('boolean') quickReject;
  @attr('string') rejectDelayTimestamp;
  @attr('date') willBeDeletedAt;
  @attr('boolean', {
    defaultValue: true,
  })
  rejectSendEmail;

  get upcomingTriggers() {
    return get(this, 'jobApplicationTriggers').filter(
      (trigger) => trigger.isPending
    );
  }

  get hasCoverLetter() {
    return !!this.coverLetter;
  }

  get isRejected() {
    return !!this.rejectedAt;
  }

  get isNotRejected() {
    return !this.isRejected;
  }

  get isHired() {
    return get(get(this, 'stage'), 'hired');
  }

  get isOnboarding() {
    return !!this.aboardOnboardingId;
  }

  get isAnonymous() {
    return get(get(this, 'stage'), 'anonymous');
  }

  get isInbox() {
    return get(get(this, 'stage'), 'inbox');
  }

  get isNotInbox() {
    return !get(this, 'isInbox');
  }

  get isNotHired() {
    return !get(this, 'isHired');
  }

  get isActive() {
    return !this.isRejected && !get(this, 'isHired') && this.jobId;
  }

  get isInProcess() {
    return get(this, 'isActive') && !get(this, 'isInbox');
  }

  get hasJob() {
    return !!this.jobId;
  }

  get userHasAccessToJob() {
    return get(get(this, 'job'), 'userHasAccess');
  }

  get jobIdAsString() {
    return this.jobId.toString();
  }

  get jobTitle() {
    return isEmpty(get(this, 'jobInternalName'))
      ? get(this, 'externalJobTitle')
      : get(this, 'jobInternalName');
  }

  get draggingEnabled() {
    return get(this, 'isNotRejected') && get(this, 'userCanAccess');
  }

  get userCanNotAccess() {
    return !get(this, 'userCanAccess');
  }

  get userCanAccess() {
    return (
      !get(this, 'candidate.restricted') ||
      get(this, 'permissions').has('candidate/access_restricted')
    );
  }

  get isFromLeadPage() {
    if (get(this, 'candidate.referringUrl')) {
      return get(this, 'candidate.referringUrl').includes('/campaigns/');
    }

    return false;
  }

  get hasScores() {
    const scoreValues = getProperties(
      this,
      'scoresDisasterCount',
      'scoresNegativeCount',
      'scoresBlankCount',
      'scoresPositiveCount',
      'scoresSuperCount'
    );

    return Math.max(...Object.values(scoreValues)) > 0;
  }

  get isFromPromotion() {
    return this.belongsTo('promotion').id() !== null;
  }

  get isGuideTimeOverDue() {
    let guideTime = get(this, 'stage.guideTime');
    return guideTime
      ? get(this, 'lastMovedAt') <
          moment().subtract(guideTime, 'seconds').toDate()
      : false;
  }

  get overdue() {
    const guideTime = get(this, 'stage.guideTime') || 0;
    const lastMovedAt = moment(get(this, 'lastMovedAt'));
    const deadline = moment().subtract(guideTime, 'seconds');
    return deadline.diff(lastMovedAt, 'minutes');
  }

  get locations() {
    return (
      get(this, 'pickedLocations') &&
      get(this, 'pickedLocations').mapBy('location')
    );
  }

  get referringSiteName() {
    if (get(this, 'referringSite') === 'click.pstmrk.it') {
      return 'Email';
    } else if (
      get(this, 'referringSite') &&
      get(this, 'referringSite').match(/^new_job/i)
    ) {
      return 'Connect email';
    } else {
      return get(this, 'referringSite');
    }
  }

  get teamMembers() {
    return get(get(this, 'job'), 'teamMembers');
  }

  get partnerResults() {
    const partnerResults = get(this, 'job.partnerResults') || [];
    return partnerResults.filter((partnerResult) => {
      return (
        get(partnerResult, 'candidate.id') === `${get(this, 'candidateId')}`
      );
    });
  }

  get sortedPartnerResults() {
    return get(this, 'partnerResults').sortBy('assessmentScore').reverse();
  }

  get highestPartnerResultScore() {
    const sortedPartnerResults = get(this, 'sortedPartnerResults');
    return sortedPartnerResults?.firstObject?.assessmentScore || 0;
  }

  get timeSinceCreatedAt() {
    const now = moment();
    const then = moment(get(this, 'createdAt'));

    return now.diff(then, 'minutes');
  }

  reject = async ({ quickReject = false } = {}) => {
    let stage = get(this, 'stage');
    setProperties(this, {
      rejectedAt: get(this, 'rejectedAt') || new Date(),
      activeStage: null,
      rejectedStage: stage,
      quickReject,
    });
    await this.save();

    get(this.stage, 'decrementCount');
    return this.store.findRecord(
      'stage-job-application-count',
      get(this.stage, 'id')
    );
  };

  unreject = async () => {
    let stage = get(this, 'rejectedStage');
    setProperties(this, {
      rejectedAt: null,
      rejectedStage: null,
      activeStage: stage,
    });

    await this.save();
    return this.store.findRecord(
      'stage-job-application-count',
      get(this.stage, 'id')
    );
  };

  markAsRead = async () => {
    this.unread = false;
    const stage = await get(this, 'stage');
    if (get(this, 'isRejected')) {
      this.rejectedStage = stage;
    } else {
      this.activeStage = stage;
    }

    await this.save();
  };
}
