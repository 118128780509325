import Modifier from 'ember-modifier';
import { inject as service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import IntlService from 'ember-intl/services/intl';
import { createPopper } from '@popperjs/core';

type OnSelect = (emoji: string) => void;

interface EmojiPickerSignature {
  Element: HTMLElement;
  Args: {
    Named: {
      onSelect: OnSelect;
      modal?: boolean;
    };
  };
}

// This is a copy of the EmojiMartData interfaces from @emoji-mart/data
interface EmojiMartData {
  categories: Category[];
  emojis: { [key: string]: Emoji };
  aliases: { [key: string]: string };
  sheet: Sheet;
}
interface Emoji {
  id: string;
  name: string;
  keywords: string[];
  native: string;
  skins: Skin[];
  version: number;
  emoticons?: string[];
}

interface Skin {
  unified: string;
  native: string;
  x?: number;
  y?: number;
}

export interface Sheet {
  cols: number;
  rows: number;
}

interface Category {
  id: string;
  emojis: string[];
}

function cleanup(instance: EmojiPickerModifier) {
  const { dialogElement, buttonElement, onButtonClick } = instance;

  if (dialogElement) {
    dialogElement.remove();
  }

  if (buttonElement) {
    buttonElement.removeEventListener('click', onButtonClick);
  }
}

export default class EmojiPickerModifier extends Modifier<EmojiPickerSignature> {
  @service declare intl: IntlService;

  declare onSelect?: OnSelect;
  declare modal?: boolean;

  dialogElement?: HTMLDialogElement;
  buttonElement?: HTMLElement;

  modify(
    button: HTMLElement,
    _: [],
    { onSelect, modal }: { onSelect: OnSelect; modal: false }
  ) {
    this.onSelect = onSelect;
    this.buttonElement = button;
    this.modal = modal;

    this.dialogElement = this.createDialog();

    this.buttonElement.addEventListener('click', this.onButtonClick);

    registerDestructor(this, cleanup);
  }

  createDialog() {
    const dialog = document.createElement('dialog');
    dialog.classList.add('emoji-picker');
    document.body.appendChild(dialog);
    return dialog;
  }

  onButtonClick = (event: Event) => {
    event.stopPropagation();

    if (!this.dialogElement) {
      return;
    }

    if (!this.dialogElement.open) {
      this.showPicker();
    } else {
      this.hidePicker();
    }
  };

  get translations() {
    return {
      search: this.intl.t('components.emoji_picker.search'),
      search_no_results_1: this.intl.t('components.emoji_picker.no_results'),
      search_no_results_2: this.intl.t('components.emoji_picker.no_results'),
      pick: this.intl.t('components.emoji_picker.pick_an_emoji'),
      categories: {
        activity: this.intl.t('components.emoji_picker.activity'),
        flags: this.intl.t('components.emoji_picker.flags'),
        foods: this.intl.t('components.emoji_picker.food_drink'),
        frequent: this.intl.t('components.emoji_picker.recently_used'),
        nature: this.intl.t('components.emoji_picker.nature'),
        objects: this.intl.t('components.emoji_picker.objects'),
        people: this.intl.t('components.emoji_picker.people'),
        places: this.intl.t('components.emoji_picker.travel_places'),
        search: this.intl.t('components.emoji_picker.search_result'),
        symbols: this.intl.t('components.emoji_picker.symbols'),
      },
    };
  }

  async showPicker() {
    if (!this.dialogElement || !this.buttonElement || !this.onSelect) {
      return;
    }

    const { Picker, data } = await loadEmojiMart();

    const picker = new Picker({
      data,
      theme: 'auto',
      skinTonePosition: 'none',
      previewPosition: 'none',
      onClickOutside: () => {
        this.hidePicker();
      },

      onEmojiSelect: (emoji: Emoji) => {
        if (this.onSelect) {
          this.onSelect(emoji.native);
        }

        this.hidePicker();
      },
    });

    this.dialogElement.appendChild(picker as unknown as Node);
    if (this.modal) {
      this.dialogElement.showModal();
    } else {
      this.dialogElement.show();
    }

    createPopper(this.buttonElement, this.dialogElement);
  }

  hidePicker() {
    if (this.dialogElement?.open) {
      this.dialogElement.innerHTML = '';
      this.dialogElement.close();
    }
  }
}

async function loadEmojiMart() {
  const [emojiMart, emojiMartData] = await Promise.all([
    import('emoji-mart'),
    import('@emoji-mart/data'),
  ]);

  return {
    Picker: emojiMart.Picker,
    Data: emojiMart.Data,
    init: emojiMart.init,
    getEmojiDataFromNative: emojiMart.getEmojiDataFromNative,
    data: Object.assign({}, emojiMartData) as EmojiMartData,
  };
}
