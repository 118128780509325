import JobOfferBaseController from './base-controller';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { debounce } from '@ember/runloop';
import { isEmpty } from '@ember/utils';
import toggleInList from 'teamtailor/utils/toggle-in-list';
import { get } from 'teamtailor/utils/get';

export default class JobOfferNewController extends JobOfferBaseController {
  @service intl;
  @service ttAlert;
  @service router;
  @service user;
  @service flashMessages;
  @service store;
  @service current;

  displayJobPicker = false;

  get salary() {
    return this.fields.findBy('label', 'salary').answers[0];
  }

  get draftButtonDisabled() {
    return (
      !get(get(this.jobOffer, 'jobApplication'), 'isTruthy') ||
      !this.haveQuestionLabels
    );
  }

  redirectAfterSave(offer) {
    this.router.transitionTo(
      'jobs.job.stages.index.candidate.job-offer.show',
      get(offer, 'jobApplication.jobId'),
      get(offer, 'candidate.id'),
      get(offer, 'id')
    );
  }

  async saveOffer() {
    set(this.jobOffer, 'jobOfferForm.formQuestionsRaw', this.fields);
    set(this.jobOffer, 'status', 'pending');
    if (isEmpty(get(this.jobOffer, 'jobOfferForm.name'))) {
      set(
        this.jobOffer,
        'jobOfferForm.name',
        this.intl.t('candidates.job_offer.job_offer')
      );
    }

    await this.jobOffer.save();

    return this.jobOffer;
  }

  sendOffer() {
    this.saveOffer().then((offer) => {
      this.jobOffer.send().then(() => {
        this.redirectAfterSave(offer);
        this.flashMessages.success(
          this.intl.t('components.candidate_modal.job_offers.item.offer_sent')
        );
      });
    });
  }

  setSalaryFromJob() {
    const job = get(this.jobOffer, 'jobApplication.job');
    if (get(job, 'minSalary')) {
      this.salary.textValue = `${get(job, 'minSalary')} ${get(
        job,
        'currency'
      )}/${get(job, 'salaryTimeUnit')}`;
      this.currency = get(job, 'currency');
      this.salaryTimeUnit = get(job, 'salaryTimeUnit');

      if (!this.jobOffer.jobOfferForm.options) {
        set(this.jobOffer, 'jobOfferForm.options', {});
      }

      set(this.jobOffer, 'jobOfferForm.options.salary', get(job, 'minSalary'));
      set(this.jobOffer, 'jobOfferForm.options.currency', get(job, 'currency'));
      set(
        this.jobOffer,
        'jobOfferForm.options.salaryTimeUnit',
        get(job, 'salaryTimeUnit')
      );

      this.onUpdateFields(this.fields);
    }
  }

  toggleUser(user) {
    toggleInList(this.jobOffer.users, user);
  }

  @action
  onSaveOffer() {
    this.saveOffer().then((offer) => {
      this.redirectAfterSave(offer);
      this.flashMessages.success(
        this.intl.t('candidates.job_offer.offer_saved')
      );
    });
  }

  @action
  previewOffer() {
    this.saveOffer().then((offer) => {
      set(offer, 'status', 'preview');
      window.open(offer.previewUrl, '_blank');
    });
  }

  @action
  onUpdateFields(fields) {
    this.fields = fields.map((field, index) => ({
      ...field,
      sortIndex: index,
    }));
    if (this.jobOfferApprovalsEnabled) {
      this.debouncedUpdateApprovalFlow();
    }
  }

  @action
  updateApprovalFlow() {
    this.setApprovalFlow(this.jobOffer.jobApplication, this.jobOffer);
  }

  @action
  debouncedUpdateApprovalFlow() {
    debounce(this.updateApprovalFlow, 750);
  }

  @action
  handleChangeJob(jobApplication) {
    this.jobOffer.jobApplication = jobApplication;
    this.setSalaryFromJob();
    set(this.jobOffer, 'users', [this.current.user]);
    if (this.jobOfferApprovalsEnabled) {
      this.setApprovalFlow(jobApplication);
    }
  }

  @action
  async handleUserPick({ user }) {
    if (user.constructor.modelName === 'user') {
      this.toggleUser(user);
    } else {
      const userModel = await get(user, 'content');
      this.toggleUser(userModel);
    }
  }

  @action
  async sendForApproval() {
    const offer = await this.saveOffer();
    await this.jobOffer.sendForApproval();
    this.redirectAfterSave(offer);
    this.flashMessages.success(
      this.intl.t(
        'components.candidate_modal.job_offers.item.offer_sent_for_approval'
      )
    );
  }
}
