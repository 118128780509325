import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  type=\"button\"\n  local-class={{class-names \"root\" root--isSelected=this.reactionItem.reaction}}\n  {{on \"click\" (fn @handleClick @group.value)}}\n>\n  <EmberTooltip\n    @text={{t\n      \"dashboard.widgets.referral_activities.users_reacted\"\n      users=this.names\n    }}\n  />\n\n  {{#each this.firstThreeReactions as |reaction|}}\n    <img\n      alt={{reaction.user.nameOrEmail}}\n      local-class=\"img\"\n      src={{reaction.user.avatarUrl}}\n    />\n  {{/each}}\n  {{#if this.numberOfOtherReactions}}\n    <div local-class=\"otherReactions\">\n      <span local-class=\"number\">\n        +{{this.numberOfOtherReactions}}\n      </span>\n    </div>\n  {{/if}}\n\n  <span local-class=\"emoji\">\n    {{@group.value}}\n  </span>\n</button>", {"contents":"<button\n  type=\"button\"\n  local-class={{class-names \"root\" root--isSelected=this.reactionItem.reaction}}\n  {{on \"click\" (fn @handleClick @group.value)}}\n>\n  <EmberTooltip\n    @text={{t\n      \"dashboard.widgets.referral_activities.users_reacted\"\n      users=this.names\n    }}\n  />\n\n  {{#each this.firstThreeReactions as |reaction|}}\n    <img\n      alt={{reaction.user.nameOrEmail}}\n      local-class=\"img\"\n      src={{reaction.user.avatarUrl}}\n    />\n  {{/each}}\n  {{#if this.numberOfOtherReactions}}\n    <div local-class=\"otherReactions\">\n      <span local-class=\"number\">\n        +{{this.numberOfOtherReactions}}\n      </span>\n    </div>\n  {{/if}}\n\n  <span local-class=\"emoji\">\n    {{@group.value}}\n  </span>\n</button>","moduleName":"teamtailor/components/reactions/list-item.hbs","parseOptions":{"srcName":"teamtailor/components/reactions/list-item.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';

export default class ReactionsListItemComponent extends Component {
  @service current;

  get group() {
    return this.args.group;
  }

  get items() {
    return this.group.items;
  }

  get names() {
    const usernames = this.items.map((reaction) => {
      const user = get(reaction, 'user');
      return get(user, 'usernameOrEmail');
    });

    return usernames.join(', ');
  }

  get firstThreeReactions() {
    if (this.items.length > 3) {
      return this.items.slice(0, 3);
    }

    return this.items;
  }

  get numberOfOtherReactions() {
    if (this.items.length > 3) {
      return this.items.length - 3;
    }

    return 0;
  }

  get reactionItem() {
    return {
      value: this.group.value,
      reaction: this.items.find(
        (reaction) => reaction.belongsTo('user').id() === this.current.user.id
      ),
    };
  }
}
