import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

const dayInMinutes = 1440;
const hourInMinutes = 60;

export default class FormatDuration extends Helper {
  @service intl;

  compute(
    [duration],
    {
      seconds = false,
      displayDays = true,
      displayHours = true,
      displayMinutes = true,
    } = {}
  ) {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let timeParts = [];

    if (seconds) {
      duration = duration / 60;
    }

    if (duration < hourInMinutes) {
      return this.intl.t('common.x_minutes', { count: Math.floor(duration) });
    } else {
      days = Math.floor(duration / dayInMinutes);
      duration = duration - days * dayInMinutes;

      hours = Math.floor(duration / hourInMinutes);
      minutes = Math.floor(duration - hours * hourInMinutes);

      if (displayDays && days > 0) {
        timeParts.push(this.intl.t('common.x_days', { count: days }));
      }

      if (displayHours && hours > 0) {
        timeParts.push(this.intl.t('common.x_hours', { count: hours }));
      }

      if (displayMinutes && minutes > 0) {
        timeParts.push(this.intl.t('common.x_minutes', { count: minutes }));
      }

      if (timeParts.length === 3) {
        return `${timeParts[0]}, ${timeParts[1]} ${this.intl.t('common.and')} ${
          timeParts[2]
        }`;
      } else {
        return timeParts.join(` ${this.intl.t('common.and')} `);
      }
    }
  }
}

export function createFormatDurationInstance(owner) {
  const instance = new FormatDuration();
  instance.intl = owner.lookup('service:intl');
  return instance;
}
