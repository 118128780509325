import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

const validatePresenceOfNameIfNoLogin = () => {
  return (key, newValue, oldValue, changes, user) => {
    if (get(user, 'hasLogin')) {
      return true;
    }

    let inviteEmail = get(user, 'inviteEmail');
    if (typeof changes.inviteEmail != 'undefined') {
      inviteEmail = changes.inviteEmail;
    }

    if (!isEmpty(inviteEmail)) {
      return true;
    }

    return isEmpty(newValue) ? buildMessage(key, { type: 'present' }) : true;
  };
};

const validatePresenceOfEmailIfNoLogin = () => {
  return (key, newValue, oldValue, changes, user) => {
    if (user.role !== 'no_access' && !get(user, 'hasLogin')) {
      return isEmpty(newValue) ? buildMessage(key, { type: 'present' }) : true;
    }

    return true;
  };
};

export { validatePresenceOfEmailIfNoLogin, validatePresenceOfNameIfNoLogin };
