import Model, { belongsTo, AsyncBelongsTo } from '@ember-data/model';
import {
  CandidateModel,
  PartnerActivationModel,
  ActivityModel,
} from 'teamtailor/models';

export default class AssessmentModel extends Model {
  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @belongsTo('activity') declare activity: AsyncBelongsTo<ActivityModel>;
  @belongsTo('partner-activation')
  declare partnerActivation: AsyncBelongsTo<PartnerActivationModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    assessment: AssessmentModel;
  }
}
