import Model, { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import UserModel from './user';
import { inject as service } from '@ember/service';
import ServerService from 'teamtailor/services/server';
import { PushPayloadArg } from 'teamtailor/models';

export default class LoginModel extends Model {
  @service declare server: ServerService;

  @hasMany('user') declare users: AsyncHasMany<UserModel>;

  @attr('string') declare email: string;
  @attr('boolean') declare ssoLogin: boolean;
  @attr('boolean', { defaultValue: false }) declare twoFactorActivated: boolean;
  @attr('string') declare username: string;
  @attr('string') declare twoFactorProvisioningUri: string;
  @attr('string') declare twoFactorSecret: string;
  @attr('boolean', { defaultValue: false }) declare ssoLoginExempt: boolean;
  @attr('date') declare lastTtUpdateCheck: Date;

  async activateTwoFactorAuthentication(data: { totp: string }) {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'activate_two_factor_authentication',
      method: 'POST',
      options: { data },
    });
    this.store.pushPayload(response);
  }

  async disableTwoFactorAuthentication(data?: { totp: string }) {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'disable_two_factor_authentication',
      method: 'POST',
      options: { data },
    });
    this.store.pushPayload(response);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    login: LoginModel;
  }
}
