import { isEmpty } from '@ember/utils';
import {
  FilterBaseValueType,
  FilterOption,
  FilterType,
  FilterValue,
} from 'teamtailor/components/fancy-filters';
import { prepareAnyOperator } from 'teamtailor/utils/operators/any-operator';
import { prepareExistsOperator } from 'teamtailor/utils/operators/exists-operator';
import { QueryType } from './base-query';
import { NO_DEPARTMENT_ID } from 'teamtailor/models/department';
import Store from '@ember-data/store';
import { CustomFieldModel, ModelKey } from 'teamtailor/models';
import { JSONValue } from 'teamtailor/components/fancy-filters/candidate-filters-json';
import { prepareRangeOperator } from 'teamtailor/utils/operators/range-operator';
import { prepareEqualsOperator } from 'teamtailor/utils/operators/equals-operator';
import { prepareLikeOperator } from 'teamtailor/utils/operators/like-operator';
import moment from 'moment-timezone';
import SharedReportStoreService from 'teamtailor/services/shared-report-store';
import IntlService from 'ember-intl/services/intl';

export interface ReportFilterOptionBase extends FilterOption {
  eventFieldName?: string;
  pageviewFieldName?: string;
  combinedFieldName?: string;
  pageviewAdditionalFilters?: string[];
  modelKey?: string;
  modelName?: ModelKey;
  queryTypes: QueryType[];
  translateValue?: boolean;
  filterOptions?: { [key: string]: string };
}
export interface ReportFilterOptionWithEvent extends ReportFilterOptionBase {
  eventFieldName: string;
}
export interface ReportFilterOptionWithPageview extends ReportFilterOptionBase {
  pageviewFieldName: string;
  pageviewAdditionalFilters?: string[];
}
export interface ReportFilterOptionWithCombined extends ReportFilterOptionBase {
  combinedFieldName: string;
}

export type ReportFilterOption = (
  | ReportFilterOptionWithEvent
  | ReportFilterOptionWithPageview
) &
  ReportFilterOptionWithCombined;

export interface ReportFilterSettingsBase {
  name: string;
  translatedName?: string;
  queryTypes: QueryType[];
  id?: string;
  modelName?: ModelKey;
  modelKey?: string;
  eventFieldName?: string;
  pageviewFieldName?: string;
  combinedFieldName?: string;
  pageviewAdditionalFilters?: string[];
  type?: FilterType;
  options?: any[];
  modelQueryParams?: Record<string, unknown>;
  filterOptions?: { [key: string]: string };
}

export interface ReportFilterSettingsWithEvent
  extends ReportFilterSettingsBase {
  eventFieldName: string;
}
export interface ReportFilterSettingsWithPageview
  extends ReportFilterSettingsBase {
  pageviewFieldName: string;
  pageviewAdditionalFilters?: string[];
}
export interface ReportFilterSettingsWithCombined
  extends ReportFilterSettingsBase {
  combinedFieldName: string;
}

export type ReportFilterSettings = (
  | ReportFilterSettingsWithEvent
  | ReportFilterSettingsWithPageview
) &
  ReportFilterSettingsWithCombined;

export type ReportFilterSettingsWithOptions = ReportFilterSettings & {
  options: any[];
};

export type ReportSelectFilterSettingsWithModel = ReportFilterSettings & {
  id: string;
  modelName: ModelKey;
};
export type ReportSelectFilterSettingsWithOptions = ReportFilterSettings & {
  options: any[];
};

export type ReportSelectFilterSettings =
  | ReportSelectFilterSettingsWithModel
  | ReportSelectFilterSettingsWithOptions;

export function createSelect(
  settings: ReportSelectFilterSettings,
  store: Store | SharedReportStoreService,
  groupAnalytics: boolean
) {
  const { name, options, id, modelName } = settings;
  function deserializeRawValue(value: FilterValue | undefined) {
    let arrayValue: FilterBaseValueType[] = [];
    if (Array.isArray(value)) {
      arrayValue = value as FilterBaseValueType[];
    } else if (value !== undefined) {
      arrayValue = [value as FilterBaseValueType];
    }

    return (arrayValue as JSONValue[]).map((v: JSONValue) => {
      if (name === 'department_id' && isEmpty(v)) {
        v = NO_DEPARTMENT_ID;
      }

      return v;
    });
  }

  return {
    ...settings,
    operators: [
      prepareAnyOperator(),
      prepareExistsOperator(true, true),
      prepareExistsOperator(false, true),
    ],

    deserializeRawValue,
    deserialize(value: any) {
      const rawValue = deserializeRawValue(value) as string[];

      let finalValue: FilterValue;

      if (options) {
        finalValue = rawValue.map((v: string) => {
          if (id) {
            return options.find((option) => option[id] === v);
          }

          return options.find((option) => option === v);
        });
      } else if (modelName) {
        finalValue = store.query(modelName, {
          ids: rawValue,
          groupAnalytics: groupAnalytics || undefined,
        }) as any;
      } else {
        throw new Error('No options or model name provided');
      }

      return finalValue;
    },

    serialize: (value?: FilterValue) => {
      if (!Array.isArray(value)) {
        value = [value] as FilterValue;
      }

      return (value as FilterValue[]).map((v: any) => {
        if (id) {
          if (name === 'department_id' && v[id] === NO_DEPARTMENT_ID) {
            return null;
          }

          return v ? v[id] : '';
        }

        return v || '';
      });
    },
  } as ReportFilterOption;
}

export function createDate(settings: ReportFilterSettings) {
  return {
    ...settings,
    type: 'date',
    operators: [
      prepareRangeOperator('less_than'),
      prepareRangeOperator('greater_than'),
      prepareRangeOperator('equals'),
      prepareExistsOperator(true, true),
      prepareExistsOperator(false, true),
    ],

    serialize: (value?: FilterValue) => {
      if (!value || isEmpty(value) || typeof value === 'string') {
        return value;
      }

      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      return moment(value.toString()).format('YYYY-MM-DD');
    },
  } as ReportFilterOption;
}

export function createBoolean(settings: ReportFilterSettingsWithOptions) {
  const deserializeRawValue = (rawValue: any) => {
    if (rawValue === null) {
      return 'any';
    }

    if (rawValue === 'true') {
      return 'yes';
    }

    if (rawValue === 'false') {
      return 'no';
    }

    return rawValue;
  };

  return {
    ...settings,
    type: 'bool',
    translateValue: true,
    operators: [prepareEqualsOperator()],
    deserializeRawValue,

    deserialize(value: any) {
      return deserializeRawValue(value) as string[];
    },

    serialize: (value?: FilterValue) => {
      if (value === 'yes') {
        return 'true';
      }

      if (value === 'no') {
        return 'false';
      }

      return null;
    },
  } as ReportFilterOption;
}

export function createString(settings: ReportFilterSettings) {
  return {
    ...settings,
    type: 'string',
    operators: [
      prepareEqualsOperator(),
      prepareLikeOperator(),
      prepareLikeOperator(false),
      prepareExistsOperator(true, true),
      prepareExistsOperator(false, true),
    ],
  } as ReportFilterOption;
}

export function createRange(settings: ReportFilterSettings) {
  return {
    ...settings,
    type: 'range',
    operators: [
      prepareRangeOperator('rounded_equals'),
      prepareRangeOperator('less_than'),
      prepareRangeOperator('greater_than'),
      prepareRangeOperator('between'),
      prepareExistsOperator(true, true),
      prepareExistsOperator(false, true),
    ],

    deserialize: (value: FilterValue | undefined) => {
      if (value === undefined || typeof value !== 'number') {
        return value;
      }

      return parseFloat(value.toString());
    },

    serialize: (value?: FilterValue) => {
      if (value === undefined || typeof value !== 'number') {
        return value;
      }

      return value.toString();
    },
  } as ReportFilterOption;
}

export function createCategoryFromArray(
  category: string,
  filters: (ReportFilterOption | undefined)[]
): ReportFilterOption[] {
  const cleanedFilters = filters.filter(
    (filter) => filter !== undefined
  ) as ReportFilterOption[];

  return cleanedFilters.map(
    (filter) =>
      Object.assign(filter, {
        category,
      }) as ReportFilterOption
  );
}

export function createCategory(
  category: string,
  ...filters: (ReportFilterOption | undefined)[]
): ReportFilterOption[] {
  return createCategoryFromArray(category, filters);
}

export const initializeFilters = async (
  store: Store | SharedReportStoreService,
  groupAnalytics: boolean,
  intl: IntlService,
  loadCustomFields: boolean = false
) => {
  let filters = [
    // Candidate filters:
    ...createCategory(
      'candidate_filters',

      createDate({
        name: 'candidate_created_at',
        eventFieldName: 'candidateCreatedAt',
        combinedFieldName: 'candidateCreatedAt',
        queryTypes: ['event'],
      }),
      createString({
        name: 'candidate_referring_site',
        eventFieldName: 'candidateReferringSite',
        pageviewFieldName: 'referrerHost',
        combinedFieldName: 'candidateReferringSite',
        queryTypes: ['event', 'pageview'],
      }),
      createSelect(
        {
          id: 'id',
          name: 'candidate_role_id',
          eventFieldName: 'candidateRoleId',
          combinedFieldName: 'candidateRoleId',
          type: '[Role]',
          queryTypes: ['event'],
          modelName: 'role',
        },
        store,
        groupAnalytics
      ),
      createSelect(
        {
          id: 'id',
          name: 'candidate_department_id',
          type: '[Model]',
          eventFieldName: 'candidateDepartmentId',
          pageviewFieldName: 'departmentId',
          combinedFieldName: 'candidateDepartmentId',
          queryTypes: ['event', 'pageview'],
          modelName: 'department',
          modelKey: 'name',
        },
        store,
        groupAnalytics
      ),
      createSelect(
        {
          id: 'id',
          name: 'candidate_tag_id',
          type: '[CandidateTag]',
          eventFieldName: 'candidateTagId',
          combinedFieldName: 'candidateTagId',
          queryTypes: ['event'],
          modelName: 'tag',
          modelKey: 'name',
        },
        store,
        groupAnalytics
      )
      // To add later when we implement cohorts.
      // {
      //   name: 'review_rating',
      //   type: 'average_rating',
      //   eventFieldName: 'reviewRating',
      //   operators: [prepareEqualsOperator()],
      //   icon: 'star',
      //   triggerIconStyle: 'solid',
      //   queryTypes: ['event'],
      // }
    ),

    // Job filters:
    ...createCategory(
      'job_filters',

      createSelect(
        {
          id: 'id',
          name: 'job',
          type: '[Job]',
          eventFieldName: 'jobId',
          pageviewFieldName: 'jobId',
          combinedFieldName: 'jobId',
          queryTypes: ['pageview', 'event'],
          modelName: 'job',
        },
        store,
        groupAnalytics
      ),
      createSelect(
        {
          id: 'id',
          name: 'job_role_id',
          type: '[Role]',
          eventFieldName: 'jobRoleId',
          combinedFieldName: 'jobRoleId',
          queryTypes: ['event'],
          modelName: 'role',
        },
        store,
        groupAnalytics
      ),
      createString({
        name: 'job_title',
        eventFieldName: 'jobTitle',
        combinedFieldName: 'jobTitle',
        queryTypes: ['event'],
      }),
      createSelect(
        {
          id: 'id',
          name: 'requisition_id',
          type: '[Requisition]',
          eventFieldName: 'requisitionId',
          pageviewFieldName: 'requisitionId',
          combinedFieldName: 'requisitionId',
          queryTypes: ['event', 'pageview'],
          modelName: 'requisition',
        },
        store,
        groupAnalytics
      ),
      createSelect(
        {
          id: 'id',
          name: 'job_department_id',
          type: '[Model]',
          eventFieldName: 'jobDepartmentId',
          pageviewFieldName: 'departmentId',
          combinedFieldName: 'jobDepartmentId',
          queryTypes: ['event', 'pageview'],
          modelName: 'department',
          modelKey: 'name',
        },
        store,
        groupAnalytics
      ),

      createSelect(
        {
          id: 'id',
          name: 'job_tag_id',
          type: '[JobTag]',
          eventFieldName: 'jobTagId',
          combinedFieldName: 'jobTagId',
          queryTypes: ['event'],
          modelName: 'tag',
          modelKey: 'name',
        },
        store,
        groupAnalytics
      )
    ),

    // Job application filters:

    ...createCategory(
      'job_application_filters',

      createDate({
        name: 'job_application_created_at',
        eventFieldName: 'jobApplicationCreatedAt',
        combinedFieldName: 'jobApplicationCreatedAt',
        queryTypes: ['event'],
      }),
      createDate({
        name: 'job_application_rejected_at',
        eventFieldName: 'jobApplicationRejectedAt',
        combinedFieldName: 'jobApplicationRejectedAt',
        queryTypes: ['event'],
      }),
      createString({
        name: 'job_application_referring_site',
        eventFieldName: 'jobApplicationReferringSite',
        pageviewFieldName: 'referrerHost',
        combinedFieldName: 'jobApplicationReferringSite',
        queryTypes: ['event', 'pageview'],
      })
      // Todo: Add these later when we implement cohorts.
      // Or when we have some candidate / job application / job etc representation in clickhouse.
      // createSeconds({
      //   name: 'job_application_time_in_inbox',
      //   eventFieldName: 'jobApplicationTimeInInbox',
      //   queryTypes: ['event'],
      // }),
      // createSeconds({
      //   name: 'job_application_time_to_hired',
      //   eventFieldName: 'jobApplicationTimeToHired',
      //   queryTypes: ['event'],
      // }),
      // createSeconds({
      //   name: 'job_application_time_to_reject',
      //   eventFieldName: 'jobApplicationTimeToReject',
      //   queryTypes: ['event'],
      // })
    ),

    // "Visitor" filters:

    ...createCategory(
      'page_visits_filters',

      createSelect(
        {
          id: 'value',
          name: 'device_type',
          type: '[Enum]',
          eventFieldName: 'jobApplicationDeviceType',
          pageviewFieldName: 'deviceType',
          combinedFieldName: 'deviceType',
          queryTypes: ['pageview', 'event'],
          options: [
            { value: 'mobile' },
            { value: 'desktop' },
            { value: 'tablet' },
            { value: 'unknown' },
          ],
        },
        store,
        groupAnalytics
      ),
      createString({
        name: 'page_visits_source',
        eventFieldName: 'jobApplicationSource',
        pageviewFieldName: 'source',
        combinedFieldName: 'source',
        queryTypes: ['pageview', 'event'],
      }),
      createString({
        name: 'page_visits_path',
        pageviewFieldName: 'path',
        combinedFieldName: 'path',
        queryTypes: ['pageview'],
      }),

      createSelect(
        {
          id: 'id',
          name: 'page_visits_geolocation_country',
          type: '[Model]',
          pageviewFieldName: 'geolocationCountry',
          combinedFieldName: 'geolocationCountry',
          modelName: 'country',
          modelKey: 'name',
          queryTypes: ['pageview'],
        },
        store,
        groupAnalytics
      ),
      createString({
        name: 'page_visits_geolocation_city',
        pageviewFieldName: 'geolocationCity',
        combinedFieldName: 'geolocationCity',
        queryTypes: ['pageview'],
      }),

      createSelect(
        {
          id: 'id',
          name: 'page_visits_page',
          type: '[InfiniteModel]',
          pageviewFieldName: 'pageId',
          combinedFieldName: 'pageId',
          queryTypes: ['pageview'],
          modelName: 'page',
          modelKey: 'displayTitle',
        },
        store,
        groupAnalytics
      )
    ),

    ...createCategory(
      'other_filters',

      groupAnalytics
        ? createSelect(
            {
              id: 'id',
              name: 'company_id',
              type: '[Company]',
              eventFieldName: 'companyId',
              pageviewFieldName: 'companyId',
              combinedFieldName: 'companyId',
              queryTypes: ['pageview', 'event'],
              modelName: 'company',
            },
            store,
            groupAnalytics
          )
        : undefined,

      createSelect(
        {
          id: 'id',
          name: 'department_id',
          type: '[Model]',
          eventFieldName: 'departmentId',
          pageviewFieldName: 'departmentId',
          combinedFieldName: 'departmentId',
          queryTypes: ['event', 'pageview'],
          modelName: 'department',
          modelKey: 'name',
        },
        store,
        groupAnalytics
      ),
      createSelect(
        {
          id: 'id',
          name: 'location_id',
          type: '[Model]',
          eventFieldName: 'locationId',
          pageviewFieldName: 'locationId',
          combinedFieldName: 'locationId',
          queryTypes: ['pageview', 'event'],
          modelName: 'location',
          modelKey: 'nameOrCity',
        },
        store,
        groupAnalytics
      ),
      createSelect(
        {
          id: 'id',
          name: 'promotion_channel',
          type: '[Model]',
          eventFieldName: 'jobApplicationPromotionChannelId',
          combinedFieldName: 'jobApplicationPromotionChannelId',
          queryTypes: ['event'],
          modelName: 'channel',
          modelKey: 'name',
        },
        store,
        groupAnalytics
      ),
      createSelect(
        {
          id: 'id',
          name: 'promotion',
          type: '[Model]',
          eventFieldName: 'jobApplicationPromotionId',
          pageviewFieldName: 'promotionId',
          combinedFieldName: 'promotionId',
          queryTypes: ['pageview', 'event'],
          modelName: 'promotion',
          modelKey: 'humanName',
        },
        store,
        groupAnalytics
      ),
      createSelect(
        {
          id: 'id',
          name: 'user',
          type: '[InfiniteModel]',
          eventFieldName: 'userId',
          combinedFieldName: 'userId',
          queryTypes: ['event'],
          modelName: 'user',
          modelKey: 'nameOrEmail',
          modelQueryParams: { allowed_to_login: true, role: 'all' },
        },
        store,
        groupAnalytics
      )

      // referral_job_id, Job

      // reject_reason_id, RejectReason
      // reject_reason_rejected_by_company, radio
      // reject_reason_reason, string // Maybe?

      // interview_kit_id, InterviewKit

      // nurture_campaign_id, NurtureCampaign
    ),
  ];

  if (loadCustomFields) {
    const customFields = (
      await store.query('custom-field', { groupAnalytics })
    ).toArray() as CustomFieldModel[];

    const candidateCustomFields: ReportFilterOption[] = [];
    const jobCustomFields: ReportFilterOption[] = [];
    customFields.forEach((customField: CustomFieldModel) => {
      let typeFilter: ReportFilterOption | undefined,
        pageviewFieldNamePrefix: string | undefined;
      const ownerType = customField.ownerType.toLowerCase();

      let eventFieldNamePrefix = 'candidateCustomField';

      let name = `${ownerType}_custom_field_${customField.id}`;
      const translatedName = customField.name;

      const queryTypes: QueryType[] = ['event'];
      if (ownerType === 'job') {
        queryTypes.push('pageview');
        eventFieldNamePrefix = 'jobCustomField';
        pageviewFieldNamePrefix = 'jobCustomField';
      }

      switch (customField.type) {
        case 'CustomField::Email':
        case 'CustomField::Phone':
        case 'CustomField::Url':
        case 'CustomField::Text':
          typeFilter = createString({
            name,
            filterOptions: { customFieldId: customField.id },
            translatedName,
            queryTypes,
            eventFieldName: `${eventFieldNamePrefix}`,
            combinedFieldName: `${eventFieldNamePrefix}`,
            pageviewFieldName: pageviewFieldNamePrefix
              ? `${pageviewFieldNamePrefix}`
              : undefined,
          });
          break;
        case 'CustomField::Number':
          typeFilter = createRange({
            name,
            filterOptions: { customFieldId: customField.id },
            translatedName,
            queryTypes,
            eventFieldName: `${eventFieldNamePrefix}`,
            combinedFieldName: `${eventFieldNamePrefix}`,
            pageviewFieldName: pageviewFieldNamePrefix
              ? `${pageviewFieldNamePrefix}`
              : undefined,
          });
          break;
        case 'CustomField::Date':
          typeFilter = createDate({
            name,
            filterOptions: { customFieldId: customField.id },
            translatedName,
            queryTypes,
            eventFieldName: `${eventFieldNamePrefix}`,
            combinedFieldName: `${eventFieldNamePrefix}`,
            pageviewFieldName: pageviewFieldNamePrefix
              ? `${pageviewFieldNamePrefix}`
              : undefined,
          });
          break;
        case 'CustomField::Checkbox':
          typeFilter = createBoolean({
            name,
            filterOptions: { customFieldId: customField.id },
            translatedName,
            queryTypes,
            options: ['yes', 'no', 'any'],
            eventFieldName: `${eventFieldNamePrefix}`,
            combinedFieldName: `${eventFieldNamePrefix}`,
            pageviewFieldName: pageviewFieldNamePrefix
              ? `${pageviewFieldNamePrefix}`
              : undefined,
          });
          break;
        case 'CustomField::Select':
        case 'CustomField::MultiSelect':
          typeFilter = createSelect(
            {
              id: 'id',
              name,
              filterOptions: { customFieldId: customField.id },
              translatedName,
              eventFieldName: `${eventFieldNamePrefix}`,
              combinedFieldName: `${eventFieldNamePrefix}`,
              options: customField.options.toArray(),
              pageviewFieldName: pageviewFieldNamePrefix
                ? `${pageviewFieldNamePrefix}`
                : undefined,

              type: '[Option]',
              queryTypes,
              modelName: 'custom-field/option',
            },
            store,
            groupAnalytics
          );
          break;
      }

      if (ownerType === 'candidate') {
        candidateCustomFields.push(typeFilter);
      } else if (ownerType === 'job') {
        jobCustomFields.push(typeFilter);
      }
    });

    filters = filters.concat(
      createCategoryFromArray(
        'candidate_custom_field_filters',
        candidateCustomFields
      )
    );

    filters = filters.concat(
      createCategoryFromArray('job_custom_field_filters', jobCustomFields)
    );
  }

  return filters.map((filter) => ({
    translatedName: intl.t(`insights.reports.builder.filters.${filter.name}`),
    ...filter,
  }));
};
// job_application_referring_url, Types::stringFilterInput

// message_canned_response_id, ID // Maybe later
// message_questionnaire_id, Questionaire // ? should add to group bys etc if we do this

// move_direction, Types::stringFilterInput // Maybe later?

// TODO: solve this somehow :shrug:
// stage_id, ID
// stage_index, Types::IntegerFilterInput
// stage_name, Types::stringFilterInput
// stage_normalized_name, Types::stringFilterInput
// stage_type, Types::stringFilterInput
// stage_from_id, ID
// stage_from_index, Types::IntegerFilterInput
// stage_from_name, Types::stringFilterInput
// stage_from_normalized_name, Types::stringFilterInput
// stage_from_time_in_stage, Types::IntegerFilterInput
// stage_from_type, Types::stringFilterInput
// stage_is_reject, Types::BooleanFilterInput

// TODO: Solve this somehow :thinking:
// nps_response_score, Types::IntegerFilterInput
// nps_response_feedback, Types::stringFilterInput

// nurture_campaign_step_id, ID // Maybe latah alligatah
// nurture_campaign_exit_reason, Types::stringFilterInput // Maybe latah alligatah
