import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes class=\"flex items-center\">\n  <HighCharts @chartOptions={{this.chartOptions}} @content={{this.chartData}} />\n</div>", {"contents":"<div ...attributes class=\"flex items-center\">\n  <HighCharts @chartOptions={{this.chartOptions}} @content={{this.chartData}} />\n</div>","moduleName":"teamtailor/components/insights/molecules/nps-score-by-stage.hbs","parseOptions":{"srcName":"teamtailor/components/insights/molecules/nps-score-by-stage.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { calculateScore } from 'teamtailor/utils/nps';
import { primaryColor } from 'teamtailor/components/insights/charts/colors';

export default class extends Component {
  @service intl;

  get chartOptions() {
    return {
      chart: {
        type: 'bar',
        height: '300px',
      },

      xAxis: {
        lineWidth: 0,
        categories: this.scoreByStage.mapBy('name'),
      },

      plotOptions: {
        bar: {
          minPointLength: 3,
        },
      },

      yAxis: {
        min: -100,
        max: 100,
        title: {
          enabled: false,
        },

        plotLines: [
          {
            zIndex: 9,
            color: '#78868E',
            width: 1,
            value: 0,
          },
        ],
      },

      legend: {
        enabled: false,
      },

      tooltip: {
        pointFormat: `${this.intl.t(
          'models.report_nps_per_stage.score'
        )}: <b>{point.y}</b><br>${this.intl.t(
          'models.report_nps_per_stage.participants'
        )}: {point.responses}`,
      },
    };
  }

  get chartData() {
    return [
      {
        name: '',
        data: this.scoreByStage.map(({ name, responses, score }) => ({
          name,
          responses,
          y: score,
          color: primaryColor,
        })),
      },
    ];
  }

  get scoreByStage() {
    const groupedData = this.args.npsResponses.reduce((acc, item) => {
      const groupKey = `${item.stageId}-${item.rejected}`;

      if (acc[groupKey] === undefined) {
        acc[groupKey] = [];
      }

      acc[groupKey].push(item);

      return acc;
    }, {});

    return Object.values(groupedData)
      .sort(([{ stageId }]) => {
        const stage = this.args.stages.findBy('id', stageId);
        return stage ? stage.rowOrder : -1;
      })
      .map((rows) => {
        const { rejected, stageId } = rows.firstObject;
        const stage = this.args.stages.findBy('id', stageId);
        const stageName = stage
          ? stage.name
          : this.intl.t('components.activity_item.deleted_stage');

        return {
          score: calculateScore(rows.mapBy('score')),
          responses: rows.length,
          name: rejected
            ? `${stageName} (${this.intl
                .t('candidate.rejected')
                .toLowerCase()})`
            : stageName,
        };
      });
  }
}
