import { gql } from '@apollo/client/core';
import { ICandidate } from 'teamtailor/components/meeting/types';
import FeatureModel from 'teamtailor/models/feature';

export const HIRED_CANDIDATES_QUERY = gql`
  query ($userId: ID!, $filter: CandidatesFilterInput) {
    candidates(userScope: { userId: $userId }, filter: $filter) {
      id
      initials
      nameOrEmail
      color

      avatarImage {
        appUserAvatar {
          url
        }
      }
    }
  }
`;

export const MESSAGE_COUNT_QUERY = gql`
  query NumMessagesQuery($dateRange: DateRangeAttributes!) {
    events: eventQuery(dateRange: $dateRange, eventTypes: [MESSAGE]) {
      aggregated {
        count: countOccurrences(filters: { eventType: { equals: MESSAGE } })
      }
    }
  }
`;

export const WRAP_UP_QUERY = gql`
  query ($userId: ID!) {
    wrapUp(userScope: { userId: $userId }) {
      activeUsersCount
      completedHiresCount
      connectsCount
      interviewsCount
      npsScore
      postedJobsCount
      receivedApplicationsCount
      translatedStartDate
    }
  }
`;

export type WrapUpRecord = {
  activeUsersCount: number;
  completedHiresCount: number;
  connectsCount: number;
  interviewsCount: number;
  npsScore: number;
  pageVisitsCount: number;
  postedJobsCount: number;
  receivedApplicationsCount: number;
  translatedStartDate: Date;
};

export type HiredCandidate = Pick<
  ICandidate,
  'id' | 'nameOrEmail' | 'initials' | 'color' | 'avatarImage'
>;

export type FeatureRecord = Pick<
  FeatureModel,
  'id' | 'icon' | 'name' | 'category' | 'iconPrefix'
>;

export type ReturnModel = WrapUpRecord & {
  hiredCandidates: HiredCandidate[];
} & {
  features?: FeatureRecord[];
  messageCount?: number;
};
