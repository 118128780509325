import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#unless this.fetchOddsOfHiringTask.isRunning}}\n\n  {{#if this.canShowForecast}}\n    <Stage::OddsOfHiring::HiringForecast @odds={{this.odds}} />\n  {{else}}\n    <Stage::OddsOfHiring::AverageJobMetrics\n      @averageTimeToHire={{this.averageTimeToHire}}\n    />\n  {{/if}}\n\n{{/unless}}", {"contents":"{{#unless this.fetchOddsOfHiringTask.isRunning}}\n\n  {{#if this.canShowForecast}}\n    <Stage::OddsOfHiring::HiringForecast @odds={{this.odds}} />\n  {{else}}\n    <Stage::OddsOfHiring::AverageJobMetrics\n      @averageTimeToHire={{this.averageTimeToHire}}\n    />\n  {{/if}}\n\n{{/unless}}","moduleName":"teamtailor/components/stage/odds-of-hiring/index.hbs","parseOptions":{"srcName":"teamtailor/components/stage/odds-of-hiring/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import InsightsService from 'teamtailor/services/insights';
import { gql } from '@apollo/client/core';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { trackedTask } from 'ember-resources/util/ember-concurrency';
import IntlService from 'ember-intl/services/intl';
import FlipperService from 'teamtailor/services/flipper';
import { get } from 'teamtailor/utils/get';
import { StageModel } from 'teamtailor/models';

const QUERY = gql`
  query OddsOfHiringQuery($jobId: ID!) {
    similarJobs: oddsOfHiringQuery(jobId: $jobId, similarJobs: true) {
      odds {
        numDays
        modifiers {
          key
          value
        }
        value
      }
      averageTimeToHire
    }
  }
`;

interface OddsOfHiringInterface {
  jobId: string;
  stage: StageModel;
}

export type ModifierType = 'CONFIDENCE' | 'TIME_IN_STAGE';

export type Modifier = {
  key: ModifierType;
  value: number;
};

export type OddsType = {
  numDays: number;
  modifiers: Modifier[];
  value: number;
};

interface OddsOfHiringQueryResult {
  oddsOfHiringQuery: {
    within30Days?: number;
    within60Days?: number;
    within90Days?: number;
    odds: OddsType[];
    averageTimeToHire: number;
  };
  similarJobs: {
    odds: OddsType[];
    averageTimeToHire: number;
  };
}

export default class OddsOfHiringComponent extends Component<OddsOfHiringInterface> {
  @service declare insights: InsightsService;
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;

  @tracked odds: OddsType[] = [];
  @tracked averageTimeToHire = 0;

  get canShowForecast() {
    return this.hasRequiredNumberOfApplications;
  }

  get hasRequiredNumberOfApplications() {
    const activeStages = get(this.args.stage.jobDetail, 'stages').filter(
      (stage) => !stage.hired
    );
    const totalActiveApplications = activeStages.reduce((acc, stage) => {
      const applicationCount = stage.activeJobApplications.length;
      return acc + applicationCount;
    }, 0);

    return totalActiveApplications >= 1;
  }

  fetchOdds = task(async () => {
    const { jobId } = this.args;
    const result = await this.insights.query<OddsOfHiringQueryResult>({
      query: QUERY,
      variables: {
        jobId,
      },

      context: { jobId },
    });

    const oddsOfHiring = get(this.flipper, 'odds_of_hiring_similar_jobs')
      ? result.similarJobs
      : result.oddsOfHiringQuery;

    const { odds, averageTimeToHire } = oddsOfHiring;

    this.averageTimeToHire = averageTimeToHire;
    this.odds = odds;
  });

  fetchOddsOfHiringTask = trackedTask(this, this.fetchOdds, () => [
    this.args.jobId,
  ]);
}
