import Route from '@ember/routing/route';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import associationUnloader from 'teamtailor/utils/association-unloader';

export default class IndexRoute extends Route {
  @service store;
  @service candidatePreview;

  queryParams = {
    activity_end_date: { refreshModel: true },
    activity_start_date: { refreshModel: true },
    consent_future_jobs: { refreshModel: true },
    created_start_date: { refreshModel: true },
    created_end_date: { refreshModel: true },
    custom_fields: { refreshModel: true },
    expired_consent: { refreshModel: true },
    internal: { refreshModel: true },
    sourced: { refreshModel: true },
    sourced_external: { refreshModel: true },
    location_id: { refreshModel: true },
    missing_consent: { refreshModel: true },
    partner_id: { refreshModel: true },
    partner_results_max_score: { refreshModel: true },
    partner_results_min_score: { refreshModel: true },
    partner_results_status: { refreshModel: true },
    query: { refreshModel: true },
    questions: { refreshModel: true },
    ratings: { refreshModel: true },
    removal_requested: { refreshModel: true },
    reviewed_by: { refreshModel: true },
    showRejected: { refreshModel: true },
    tags: { refreshModel: true },
    sidebarToShow: { refreshModel: false },
  };

  async model(params) {
    const job = this.modelFor('jobs.job');
    let candidateIds = null;
    let requestParams = null;

    if (this._hasModifiedFilters(params)) {
      requestParams = {
        job_id: job.id,
        only_ids: true,
        activity_end_date: params.activity_end_date,
        activity_start_date: params.activity_start_date,
        consent_future_jobs: params.consent_future_jobs,
        created_start_date: params.created_start_date,
        created_end_date: params.created_end_date,
        custom_fields: params.custom_fields,
        expired_consent: params.expired_consent,
        internal: params.internal,
        sourced: params.sourced,
        sourced_external: params.sourced_external,
        location_id: params.location_id,
        missing_consent: params.missing_consent,
        partner_id: params.partner_id,
        partner_results_max_score: params.partner_results_max_score,
        partner_results_min_score: params.partner_results_min_score,
        partner_results_status: params.partner_results_status,
        query: params.query,
        questions: params.questions,
        ratings: params.ratings,
        removal_requested: params.removal_requested,
        reviewed_by: params.reviewed_by,
        tags: params.tags,
        rejected: params.showRejected ? 'yes' : undefined,
      };

      const adapter = this.store.adapterFor('application');
      const url = adapter.buildURL('candidates');
      const response = await adapter.ajax(url, 'GET', { data: requestParams });
      candidateIds = response.candidates.map((id) => parseInt(id, 10));
    }

    const paramsForStage = { ...params, showRejected: undefined };

    return {
      job,
      candidateIds,
      params: this._hasModifiedFilters(paramsForStage) ? paramsForStage : null,
    };
  }

  async afterModel({ job }) {
    this.store.query('meeting-event-status', {
      job_id: job.id,
    });

    return get(job, 'jobDetail.triggers');
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.stageListInstances = {};
      controller.isAddingNewStage = false;
      // Remove the currently bulkd selected candidates
      controller.selectedJobApplications = [];
      controller.sidebarToShow = null;
      this.candidatePreview.stageCards = [];
      let options = {
        parentName: 'job',
        parentId: controller.model.job.id,
      };
      associationUnloader(this.store, options, 'meeting-event-status');
    }
  }

  _hasModifiedFilters(params) {
    return (
      [
        ...params.tags,
        ...params.custom_fields,
        ...params.ratings,
        ...params.questions,
      ].length > 0 ||
      params.query ||
      params.reviewed_by ||
      params.location_id ||
      params.showRejected ||
      params.expired_consent ||
      params.missing_consent ||
      params.removal_requested ||
      params.consent_future_jobs ||
      params.created_start_date ||
      params.created_end_date ||
      params.activity_start_date ||
      params.activity_end_date ||
      params.internal ||
      params.sourced ||
      params.sourced_external ||
      params.partner_id ||
      params.partner_results_max_score ||
      params.partner_results_min_score ||
      params.partner_results_status
    );
  }
}
