import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import CurrentService from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import toggleInList from 'teamtailor/utils/toggle-in-list';

interface ErrorResponse {
  errors: string[];
}

export default class SettingsDataRequestController extends Controller {
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare current: CurrentService;

  @action
  async saveForm() {
    const promises = [this.model?.save(), this.current.company.save()];

    try {
      await Promise.all(promises);
      this.flashMessages.success(this.intl.t('common.setting_saved'));
    } catch (e) {
      let error = e as ErrorResponse;
      if (error.errors.length) {
        this.model.rollbackAttributes();
        this.flashMessages.error(JSON.stringify(error.errors));
      }
    }
  }

  @action
  toggleAutomaticCandidateDataRequests() {
    this.current.company.toggleProperty('automaticCandidateDataRequests');
  }

  @action
  toggleAllowChanges() {
    this.model.toggleProperty('allowChanges');
  }

  @action
  toggleTable(table: string) {
    toggleInList(this.model.tables, table);
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.data-requests': SettingsDataRequestController;
  }
}
