import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { TableColumn } from 'teamtailor/components/table/container';
import Current from 'teamtailor/services/current';

const AUDIT_LOG_COLUMNS: TableColumn[] = [
  {
    label: 'settings.audit_log.actor',
  },
  {
    label: 'settings.audit_log.source',
  },
  {
    label: 'settings.audit_log.ip_address',
  },
  {
    label: 'settings.audit_log.action',
  },
  {
    label: 'settings.audit_log.timestamp',
  },
  {
    label: 'common.description',
  },
];

export default class EventsController extends Controller {
  @service declare current: Current;

  queryParams = ['page'];

  columns = AUDIT_LOG_COLUMNS;

  get events() {
    return this.model;
  }

  get auditLog() {
    return this.current.company.auditLog;
  }

  get currentPage() {
    return this.events.meta.current_page;
  }

  get totalPages() {
    return this.events.meta.total_pages;
  }

  get showPagination() {
    return this.totalPages > 1;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.audit-log.events': EventsController;
  }
}
