import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';

export default class IndexController extends Controller {
  @service analytics;
  @service intl;

  @tracked sortProperty = 'numberOfRejects';
  @tracked sortDirection = 'desc';

  get data() {
    return this.model.rejectReasons.value;
  }

  get allStats() {
    return [
      this.totalRejectsStats,
      this.totalRejectedByUsStats,
      this.totalRejectedByThemStats,
      this.totalRejectedUnknownStats,
    ];
  }

  get totalRejectsStats() {
    return {
      value: this.data.totalRejects,
      title: this.intl.t('reports.total_rejects'),
    };
  }

  get totalRejectedByUsStats() {
    return {
      value: this.data.totalRejectedByUs,
      title: this.intl.t('reports.rejected_by_us'),
    };
  }

  get totalRejectedByThemStats() {
    return {
      value: this.data.totalRejectedByThem,
      title: this.intl.t('reports.rejected_by_candidate'),
    };
  }

  get totalRejectedUnknownStats() {
    return {
      value: this.data.totalRejectedUnknown,
      title: this.intl.t('reports.no_reason'),
    };
  }

  get reasons() {
    return this.data?.reducedRows ?? [];
  }

  get sortedReasons() {
    const sorted = this.reasons.sortBy(this.sortProperty);
    return this.sortDirection === 'desc' ? sorted.reverse() : sorted;
  }

  get rejectedByUsChartData() {
    if (!this.data) {
      return {};
    }

    return {
      title: this.intl.t('reports.rejected_by_us'),
      data: this.data.rejectedByUs.map((reason) => ({
        name: reason.name,
        value: reason.numberOfRejects,
      })),
    };
  }

  get rejectedByCandidateChartData() {
    if (!this.data) {
      return {};
    }

    return {
      title: this.intl.t('reports.rejected_by_candidate'),
      data: this.data.rejectedByThem.map((reason) => ({
        name: reason.name,
        value: reason.numberOfRejects,
      })),
    };
  }

  @action
  handleExport() {
    jsonToCsvFile(
      'reject-reasons',
      this.analytics.startDate,
      this.analytics.endDate,
      this.data.rows,
      (row) => ({
        reject_reason_id: row.rejectReasonId,
        rejected_by_company: row.rejectedByCompany,
        candidate_ids: row.candidateIds,
        job_application_ids: row.applicationIds,
        reason: row.name,
      })
    );
  }

  @action
  handleSort(sortProperty) {
    if (this.sortProperty === sortProperty) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortDirection = 'desc';
      this.sortProperty = sortProperty;
    }
  }
}
