import { get } from './get';

export default function applyDefaultPickedQuestionValues(attributes) {
  const { question } = attributes;

  const startWith = Number(get(question, 'startWith'));
  const endWith = Number(get(question, 'endWith'));

  if (attributes.rangeMinAnswer === undefined) {
    attributes.rangeMinAnswer = startWith;
  }

  if (attributes.rangeMaxAnswer === undefined) {
    attributes.rangeMaxAnswer = endWith;
  }

  if (attributes.rangeSingleAnswer === undefined) {
    if (Number.isInteger(startWith) && Number.isInteger(endWith)) {
      attributes.rangeSingleAnswer = Math.round((startWith + endWith) / 2);
    } else {
      attributes.rangeSingleAnswer = Number.isInteger(startWith)
        ? startWith
        : Number.isInteger(endWith)
          ? endWith
          : 0;
    }
  }

  if (attributes.multipleChoiceAnswers === undefined) {
    attributes.multipleChoiceAnswers = [];
  }

  return attributes;
}
