import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { get } from 'teamtailor/utils/get';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';

export default class SettingsIntegrationsPartnerActivationActivateRoute extends ScrollToTopRoute {
  @service ttAlert;
  @service intl;
  @service user;
  @service router;
  @service store;

  async model() {
    const partnerActivation = this.modelFor(
      'settings.integrations.partner-activation'
    );
    const { fields, validateEndpoint, oauthHasForm } =
      await partnerActivation.webhookActivationConfig();

    return hash({
      partnerActivation,
      fields,
      oauthHasForm,
      hasValidationEndpoint: !!validateEndpoint,
      validateEndpoint,
    });
  }

  redirect(model) {
    if (
      get(model, 'partnerActivation.partner.activationType') === 'oauth' &&
      !model.fields.oauthHasForm
    ) {
      this.router.transitionTo(
        'settings.integrations.partner-activation.oauth'
      );
    }
  }
}
