import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import TtAlertService from 'teamtailor/services/tt-alert';
import IntlService from 'ember-intl/services/intl';
import FlashMessageService from 'teamtailor/services/flash-message';
import { ApiKeyModel } from 'teamtailor/models';
import { TableColumn } from 'teamtailor/components/table/container';

const API_KEYS_COLUMNS: TableColumn[] = [
  {
    label: 'settings.integrations.api_keys.name',
  },
  {
    label: 'settings.integrations.api_keys.permissions',
  },
  {
    label: 'settings.integrations.api_keys.api_key',
  },
];

type ApiKeysScopeType = 'open' | 'internal' | 'admin' | 'analytics';

export default class ApiKeysController extends Controller {
  @service declare store: Store;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;

  @tracked newScope: ApiKeysScopeType = 'open';
  @tracked showModal = false;
  @tracked newName = '';
  @tracked newWrite = false;
  @tracked newRead = false;

  columns = API_KEYS_COLUMNS;

  get savedApiKeys() {
    return this.model.filter((item: { isNew: boolean }) => !item.isNew);
  }

  get sortedApiKeys() {
    return this.savedApiKeys
      .sort(
        (a: ApiKeyModel, b: ApiKeyModel) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .reverse();
  }

  get selectedNewScopeLabel() {
    switch (this.newScope) {
      case 'open':
        return this.intl.t('settings.integrations.public');
      case 'internal':
        return this.intl.t('settings.integrations.internal');
      case 'admin':
        return this.intl.t('settings.integrations.api_keys.admin');
      case 'analytics':
        return this.intl.t('settings.integrations.api_keys.analytics');
    }

    return '';
  }

  @action
  updateNewRead(value: boolean) {
    this.newRead = value;
  }

  @action
  updateNewWrite(value: boolean) {
    this.newWrite = value;
  }

  @action
  changeScope(scope: ApiKeysScopeType) {
    this.newScope = scope;
  }

  @action
  create() {
    const model = this.store.createRecord('api-key', {
      scope: this.newScope,
      name: this.newName,
      write: this.newWrite,
      read: this.newRead,
    });

    const promise = model.save();
    promise.then(() => {
      this.newName = '';
      this.newWrite = false;
      this.newRead = false;
      this.newScope = 'open';
      this.showModal = false;
    });
    return promise;
  }

  @action
  delete(apiKey: { destroyRecord: () => Promise<unknown> }) {
    this.ttAlert.confirm(
      this.intl.t('settings.integrations.api_keys.are_you_sure_deleting'),
      () => {
        apiKey.destroyRecord().then(() => {
          this.flashMessages.success(
            this.intl.t('settings.integrations.api_keys.api_key_removed')
          );
        });
      },
      () => {}
    );
  }

  @action
  toggleModal() {
    this.showModal = !this.showModal;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.integrations.api-keys': ApiKeysController;
  }
}
