import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let\n  (component\n    \"core/anchored-element/root\"\n    addWrappers=this.addWrappers\n    inline=@inline\n    onClickOutside=@onClickOutside\n    extraClickOutsideElements=@extraClickOutsideElements\n    innerIsScrollable=@innerIsScrollable\n    innerClass=@innerClass\n    onInsertInnerEl=@onInsertInnerEl\n    onResizeInnerEl=@onResizeInnerEl\n    focusTrapOptions=this.focusTrapOptions\n    focusTrapAdditionalElements=@focusTrapAdditionalElements\n    focusTrap=this.focusTrap\n    onInsert=this.onInsert\n    disabledMiddlewares=@disabledMiddlewares\n  )\n  as |Root|\n}}\n  {{#if @inline}}\n    <Root ...attributes>\n      {{yield}}\n    </Root>\n  {{else}}\n    {{#in-element this.destinationElement insertBefore=null}}\n      <Root ...attributes>\n        {{yield}}\n      </Root>\n    {{/in-element}}\n  {{/if}}\n{{/let}}", {"contents":"{{#let\n  (component\n    \"core/anchored-element/root\"\n    addWrappers=this.addWrappers\n    inline=@inline\n    onClickOutside=@onClickOutside\n    extraClickOutsideElements=@extraClickOutsideElements\n    innerIsScrollable=@innerIsScrollable\n    innerClass=@innerClass\n    onInsertInnerEl=@onInsertInnerEl\n    onResizeInnerEl=@onResizeInnerEl\n    focusTrapOptions=this.focusTrapOptions\n    focusTrapAdditionalElements=@focusTrapAdditionalElements\n    focusTrap=this.focusTrap\n    onInsert=this.onInsert\n    disabledMiddlewares=@disabledMiddlewares\n  )\n  as |Root|\n}}\n  {{#if @inline}}\n    <Root ...attributes>\n      {{yield}}\n    </Root>\n  {{else}}\n    {{#in-element this.destinationElement insertBefore=null}}\n      <Root ...attributes>\n        {{yield}}\n      </Root>\n    {{/in-element}}\n  {{/if}}\n{{/let}}","moduleName":"teamtailor/components/core/anchored-element.hbs","parseOptions":{"srcName":"teamtailor/components/core/anchored-element.hbs"}});
import { action } from '@ember/object';
import { isEmpty, isNone } from '@ember/utils';
import {
  computePosition,
  flip,
  offset,
  autoUpdate,
  size,
  shift,
  Placement,
  Side,
  Alignment,
  hide,
  MiddlewareState,
} from '@floating-ui/dom';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { argDefault } from 'teamtailor/utils/arg-default';

export type CoreAnchoredElementApi = {
  updatePosition: () => void;
  positionState?: PositionState;
};

type CoreAnchoredElementArgs = {
  focusTrapOptions?: {
    clickOutsideDeactivates?: boolean;
  };
  triggerElement?: HTMLElement;
  triggerId?: string;
  initialPlacement?: Placement;
  matchTriggerWidth: boolean | 'min-width';
  fallbackPlacements?: 'horizontal' | 'vertical' | 'all' | Placement[];
  maxWidth?: number;
  maxHeight?: number;
  width?: number;
  useAnimationFrame?: boolean;
  onRegister?: (api: CoreAnchoredElementApi) => void;
  inline?: boolean;
  destinationElement?: HTMLElement | string;
  focusTrap?: boolean;
  onUpdatePosition?: (positionState: PositionState) => void;
  autoUpdatePosition?: boolean;
  triggerOffset: number;
  minWidth?: number;
  onlyGrowWidth?: boolean;
  addWrappers?: boolean;
  resetMaxHeightOnUpdatePosition?: boolean;
  disabledMiddlewares?: 'flip'[];
};

export type PositionState = MiddlewareState & {
  availableWidth: number;
  availableHeight: number;
};

export default class CoreAnchoredElement extends Component<CoreAnchoredElementArgs> {
  declare anchorElement: HTMLElement;

  @argDefault focusTrap = true;
  @argDefault autoUpdatePosition = true;
  @argDefault triggerOffset = 4;
  @argDefault addWrappers = true;
  @argDefault resetMaxHeightOnUpdatePosition = true;

  get focusTrapOptions() {
    return {
      clickOutsideDeactivates: true,
      fallbackFocus: this.anchorElement,
      setReturnFocus: this.triggerElement,

      ...(this.args.focusTrapOptions || {}),
    };
  }

  get triggerElement() {
    return (
      this.args.triggerElement ?? document.getElementById(this.args.triggerId!)
    );
  }

  get initialPlacement() {
    return this.args.initialPlacement || 'bottom-start';
  }

  get fallbackPlacements() {
    const fallbackShortcuts = {
      horizontal: ['right', 'left'],
      vertical: ['top', 'bottom'],
      all: ['top', 'bottom', 'left', 'right'],
    } as const;

    const resolvedFallbacks =
      typeof this.args.fallbackPlacements === 'string'
        ? fallbackShortcuts[this.args.fallbackPlacements]
        : (this.args.fallbackPlacements ?? fallbackShortcuts.vertical);

    const [initialSide, initialLogical] = this.initialPlacement.split('-') as [
      Side,
      Alignment,
    ];

    const oppositeSideMap = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    } as const;
    const oppositeSide = oppositeSideMap[initialSide];

    return resolvedFallbacks.flatMap<Placement>((side) =>
      side === oppositeSide ? [`${side}-${initialLogical}`, side] : side
    );
  }

  sharedArgs = {
    padding: 10,
  };

  widestWidthYet = 0;

  @tracked positionState?: PositionState;

  get flipDisabled() {
    return this.args.disabledMiddlewares?.includes('flip');
  }

  @action async updatePosition() {
    const { sharedArgs } = this;

    const isFirstRender = !this.floatingUiCleanup;

    if (!isFirstRender) {
      // reset so all calculations are like first one that doesnt have max-width etc. set (needed for when user searches, for example)
      if (this.resetMaxHeightOnUpdatePosition) {
        this.anchorElement.style.maxHeight = '';
      }

      this.anchorElement.style.height = '';
      this.anchorElement.style.left = '';
      this.anchorElement.style.top = '';
      this.anchorElement.style.maxWidth = '';
      this.anchorElement.style.minWidth = '';
      this.anchorElement.style.transform = '';
      this.anchorElement.style.width = '';
    }

    const offsetVal = this.triggerOffset;

    if (this.triggerElement) {
      const middleWares = [
        offset(offsetVal),
        shift(sharedArgs),
        flip({
          ...sharedArgs,
          fallbackPlacements: this.fallbackPlacements,
        }),
        hide(sharedArgs),
      ].filter(
        (middleWare) =>
          !this.args.disabledMiddlewares?.includes(middleWare.name as 'flip')
      );

      await computePosition(this.triggerElement, this.anchorElement, {
        placement: this.initialPlacement,
        middleware: [
          ...middleWares,
          size({
            ...sharedArgs,
            apply: (positionState: PositionState) => {
              const {
                rects,
                availableHeight,
                availableWidth,
                x,
                y,
                middlewareData,
              } = positionState;

              this.positionState = positionState;

              if (middlewareData.hide?.referenceHidden) {
                this.anchorElement.style.display = 'none';
              } else {
                this.anchorElement.style.display = '';
              }

              // round to avoid blurry text in some cases
              const xRounded = Math.round(x);
              const yRounded = Math.round(y);

              this.anchorElement.style.top = `${yRounded}px`;
              this.anchorElement.style.left = `${xRounded}px`;

              // setting styles via ember is to slow and results in incorrect positioning when dropdown is higher than viewport, so setting it manually
              // with this fixed we should be able to set styles via ember: https://github.com/floating-ui/floating-ui/issues/1766
              this.anchorElement.style.maxHeight = `${
                this.args.maxHeight
                  ? Math.min(this.args.maxHeight, availableHeight)
                  : availableHeight
              }px`;
              this.anchorElement.style.maxWidth = `${
                this.args.maxWidth ?? availableWidth
              }px`;

              const boundingClientRect =
                this.anchorElement.getBoundingClientRect();
              const refOrAvailableWidth = Math.min(
                rects.reference.width,
                availableWidth
              );

              if (this.matchTriggerWidth === true) {
                this.anchorElement.style.width = `${refOrAvailableWidth}px`;
              } else {
                let minWidthToSet: number | undefined;
                if (this.matchTriggerWidth === 'min-width') {
                  minWidthToSet = refOrAvailableWidth;
                }

                if (
                  this.args.minWidth &&
                  this.args.minWidth > (minWidthToSet || 0)
                ) {
                  minWidthToSet = this.args.minWidth;
                }

                if (this.args.onlyGrowWidth) {
                  minWidthToSet = Math.max(
                    minWidthToSet ?? 0,
                    this.widestWidthYet
                  );

                  if (this.widestWidthYet < boundingClientRect.width) {
                    this.widestWidthYet = boundingClientRect.width;
                  }
                }

                if (!isNone(minWidthToSet)) {
                  this.anchorElement.style.minWidth = `${minWidthToSet}px`;
                }

                if (!isNone(this.args.width)) {
                  this.anchorElement.style.width = `${this.args.width}px`;
                }
              }

              this.args.onUpdatePosition?.(positionState);
            },
          }),
        ],
      });
    }
  }

  get destinationElement() {
    return typeof this.args.destinationElement === 'string'
      ? document.querySelector(this.args.destinationElement)
      : (this.args.destinationElement ??
          document.getElementById('application-end-wormhole'));
  }

  get matchTriggerWidth() {
    if (!isEmpty(this.args.matchTriggerWidth)) {
      return this.args.matchTriggerWidth;
    }

    const fallbacksWithoutLogical = this.fallbackPlacements.map(
      (placement) => placement.split('-')[0]
    );

    const canBeLeftOrRight = ['left', 'right'].some(
      (placement) =>
        fallbacksWithoutLogical.includes(placement) ||
        placement === this.initialPlacement.split('-')[0]
    );

    return canBeLeftOrRight ? false : 'min-width';
  }

  setupPositioning() {
    const { sharedArgs } = this;

    this.anchorElement.style.setProperty(
      '--floating-ui-padding',
      `${sharedArgs.padding}px`
    );

    requestAnimationFrame(() => {
      this.floatingUiCleanup = autoUpdate(
        this.triggerElement!,
        this.anchorElement,
        this.updatePosition,
        { animationFrame: this.args.useAnimationFrame }
      );
    });
  }

  floatingUiCleanup?: () => void;

  get api(): CoreAnchoredElementApi {
    const parentThis = this;
    return {
      updatePosition: this.updatePosition,
      get positionState() {
        return parentThis.positionState;
      },
    };
  }

  @action onInsert(element: HTMLElement) {
    this.anchorElement = element;
    if (!this.args.inline && this.autoUpdatePosition) {
      this.setupPositioning();
    }

    this.args.onRegister?.(this.api);
  }

  willDestroy() {
    super.willDestroy();
    this.floatingUiCleanup?.();
  }
}
