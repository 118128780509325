import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{did-update this.didUpdatePredicateArg @predicate}}\n{{did-insert this.setupAwaitInPromise}}\n{{did-insert this.onRegister}}\n\n{{#if (or @hasElseBlock this.predicate)}}\n  <div class=\"flow-root\" {{did-insert this.didEnterWrapperDiv}} ...attributes>\n    {{yield this.api}}\n  </div>\n{{/if}}\n{{this.setIsInitialRender}}", {"contents":"{{did-update this.didUpdatePredicateArg @predicate}}\n{{did-insert this.setupAwaitInPromise}}\n{{did-insert this.onRegister}}\n\n{{#if (or @hasElseBlock this.predicate)}}\n  <div class=\"flow-root\" {{did-insert this.didEnterWrapperDiv}} ...attributes>\n    {{yield this.api}}\n  </div>\n{{/if}}\n{{this.setIsInitialRender}}","moduleName":"teamtailor/components/ember-smooth/if/glimmer.hbs","parseOptions":{"srcName":"teamtailor/components/ember-smooth/if/glimmer.hbs"}});
import ENV from 'teamtailor/config/environment';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { afterRender, animate, raf, setStyles } from 'teamtailor/ember-smooth';
import { runInDebug } from '@ember/debug';
import { argDefault } from 'teamtailor/utils/arg-default';
import { justAssert } from 'teamtailor/utils/justAssert';
import { scheduleOnce } from '@ember/runloop';

type SmoothIfSignature = {
  Args: {
    predicate: boolean;
    devJumpCheck?: boolean;
    debug?: 'console' | true;
    awaitIn?: boolean | 'else';
    onRegister?: (api: {
      predicate: boolean;
      inDone: (() => void) | undefined;
    }) => void;
    axis?: 'x' | 'y';
    hasElseBlock?: boolean;
    onInitialRender?: boolean;
    delayWhenClipping?: boolean;
  };
};

export default class SmoothIfBaseComponent extends Component<SmoothIfSignature> {
  @argDefault devJumpCheck = true;
  @argDefault delayWhenClipping = true;

  isInitialRender = true;

  @action setIsInitialRender() {
    if (!this.isInitialRender) return;
    scheduleOnce('afterRender', this, 'setIsInitialRenderFalse');
  }

  @action setIsInitialRenderFalse() {
    this.isInitialRender = false;
  }

  _untrackedPredicate = false;
  @tracked _predicate = false;
  get predicate() {
    return this._predicate;
  }

  set predicate(val) {
    this._predicate = !!val;
    this._untrackedPredicate = !!val;
  }

  constructor(owner: unknown, args: SmoothIfSignature['Args']) {
    super(owner, args);
    if (!!args.predicate !== !!this._untrackedPredicate) {
      this.predicate = args.predicate;
    }
  }

  @tracked el?: HTMLElement;

  get axis() {
    return this.args.axis ?? 'y';
  }

  get y() {
    return this.axis === 'y';
  }

  @action async didEnterWrapperDiv(wrapperEl: HTMLElement) {
    this.el = wrapperEl;

    const inAnimation = this.in();
    this.addRunningAnimation(inAnimation);
    await inAnimation;
  }

  get shouldDoDevJumpCheck() {
    return false;
  }

  get onInitialRender() {
    return !!this.args.onInitialRender;
  }

  @action async in() {
    if (this.isInitialRender && !this.onInitialRender) {
      return;
    }

    if (this.awaitInPromise) {
      let resetStyles: ReturnType<typeof setStyles> | undefined;

      if (this.y) {
        resetStyles = setStyles(this.el, { overflow: 'clip', height: 0 });
      }

      await this.awaitInPromise;
      this.setupAwaitInPromise();

      resetStyles?.();
    }

    let parent: HTMLElement | null | undefined, parentRectBefore: DOMRect;

    runInDebug(() => {
      if (this.shouldDoDevJumpCheck) {
        parent = this.el?.parentElement;
        parentRectBefore = parent!.getBoundingClientRect();
      }
    });

    const widthOrHeight = this.y ? 'height' : 'width';

    await Promise.all([
      animate.from(
        this.el,
        { [widthOrHeight]: 0 } as { [k in typeof widthOrHeight]: number },
        {
          debug: this.args.debug,
        }
      ),
      animate.from(
        this.el,
        { opacity: 0, scale: '0.99' },
        {
          duration: 150,
          ...(this.delayWhenClipping && {
            delay: 150,
            easing: 'ease-in-cubic',
          }),

          debug: this.args.debug,
        }
      ),
    ]);
    if (this.shouldDoDevJumpCheck) {
      await this.checkParentRectBeforeAfter(this.el, () => {
        justAssert(this.el);

        const rectAfter = parent!.getBoundingClientRect();

        if (
          Math.round(parentRectBefore.height) !== Math.round(rectAfter.height)
        ) {
          setStyles(
            [
              this.el.previousElementSibling,
              this.el,
              this.el.nextElementSibling,
            ] as HTMLElement[],
            { outline: '4px solid red', outlineOffset: '-2px' }
          );

          this.error(JUMP_DETECTED_ERROR_MESSAGE);
        }
      });
    }
  }

  @tracked awaitInPromiseResolve?: () => void;
  awaitInPromise?: Promise<void>;

  @action setupAwaitInPromise() {
    if (this.args.awaitIn) {
      this.awaitInPromise = new Promise((resolve) => {
        this.awaitInPromiseResolve = () => {
          resolve();
          this.setupAwaitInPromise();
        };
      });
    }
  }

  get api() {
    const parentThis = this;
    return {
      get predicate() {
        return parentThis.predicate;
      },

      get inDone() {
        return parentThis.awaitInPromiseResolve;
      },
    };
  }

  runningAnimations: Promise<(void | Animation[])[] | void>[] = [];

  @action async addRunningAnimation(
    promise: Promise<(void | Animation[])[] | void>
  ) {
    this.runningAnimations.push(promise);
    await promise;
    this.runningAnimations = this.runningAnimations.filter(
      (p) => p !== promise
    );
  }

  @action async out() {
    justAssert(this.el);

    await Promise.all([
      animate.to(
        this.el,
        { opacity: 0, scale: '0.99' },
        {
          duration: 150,
          debug: this.args.debug,
          setStylesAfter: false,
        }
      ),
      animate.to(this.el, this.y ? { height: 0 } : { width: 0 }, {
        ...(this.delayWhenClipping && { delay: 100 }),
        debug: this.args.debug,
        setStylesAfter: false,
      }),
    ]);

    if (this.shouldDoDevJumpCheck) {
      await this.checkParentRectBeforeAfter(this.el, () => {
        justAssert(this.el);
        const parent = this.el.parentElement;
        const rectBefore = parent?.getBoundingClientRect();
        const commentEl = document.createComment('');
        this.el.replaceWith(commentEl);
        const rectAfter = parent?.getBoundingClientRect();
        commentEl.replaceWith(this.el);

        if (Math.round(rectBefore!.height) !== Math.round(rectAfter!.height)) {
          setStyles(
            [
              this.el.previousElementSibling,
              this.el,
              this.el.nextElementSibling,
            ] as HTMLElement[],
            { outline: '2px solid red' }
          );

          this.error(JUMP_DETECTED_ERROR_MESSAGE);
        }
      });
    }
  }

  async checkParentRectBeforeAfter(
    el: HTMLElement | undefined,
    cb: (el?: HTMLElement) => Promise<void> | void
  ) {
    justAssert(el);
    if (ENV.environment === 'development' && this.devJumpCheck) {
      await new Promise<void>((resolve) => {
        runInDebug(async () => {
          if (this.runningAnimations.length > 1 || !this.el?.isConnected) {
            resolve();
            return;
          }

          await raf(); // to avoid jankyness in dev

          await cb(el);
          resolve();
        });
      });
    }
  }

  @action async didUpdatePredicateArg([predicateArg]: [boolean]) {
    if (this.runningAnimations.length) {
      await Promise.all(this.runningAnimations);
      await afterRender();
    }

    const _untrackedPredicateBeforeChanged = this._untrackedPredicate;

    if (!!predicateArg !== !!this._untrackedPredicate) {
      if (predicateArg) {
        this.predicate = predicateArg;
      } else {
        if (!this.args.hasElseBlock) {
          await this.addRunningAnimation(this.out());
        }

        this.predicate = predicateArg;
      }
    }

    if (
      this.el?.isConnected &&
      predicateArg !== _untrackedPredicateBeforeChanged
    ) {
      const before = this.el.getBoundingClientRect();
      scheduleOnce(
        'afterRender',
        this,
        this.predicateChangedAfterRender,
        predicateArg,
        before
      );
    }
  }

  @action async predicateChangedAfterRender(
    predicateArg: unknown,
    before: DOMRect
  ) {
    justAssert(this.el);
    if (!this.el.isConnected) {
      return;
    }

    if (
      (!predicateArg && this.args.awaitIn === 'else') ||
      (predicateArg && this.args.awaitIn === true)
    ) {
      await this.awaitInPromise;
    }

    const after = this.el.getBoundingClientRect();

    const currentAnimations = this.el.getAnimations();
    if (!currentAnimations.length) {
      const heightChanged =
        Math.round(before.height) !== Math.round(after.height);
      const widthChanged = Math.round(before.width) !== Math.round(after.width);
      await Promise.all([
        animate.from(
          this.el,
          {
            ...(heightChanged && { height: before.height }),
            ...(widthChanged && { width: before.width }),
          },
          {
            style: { overflow: 'hidden' },
            setStylesAfter: false,
            debug: this.args.debug,
            duration: 150,
          }
        ),
        animate.from(
          this.el,
          {
            scale: '0.99',
          },
          {
            duration: 200,
            setStylesAfter: false,
            debug: this.args.debug,
          }
        ),
      ]);

      if (this.shouldDoDevJumpCheck) {
        await this.checkParentRectBeforeAfter(this.el, () => {
          justAssert(this.el);

          const rectAfter = this.el.getBoundingClientRect();

          if (Math.round(after.height) !== Math.round(rectAfter.height)) {
            setStyles(
              [
                this.el.previousElementSibling,
                this.el,
                this.el.nextElementSibling,
              ] as HTMLElement[],
              { outline: '4px solid red', outlineOffset: '-2px' }
            );

            this.error(JUMP_DETECTED_ERROR_MESSAGE);
          }
        });
      }
    }
  }

  @action onRegister() {
    this.args.onRegister?.(this.api);
  }

  @action error(textArg: string) {
    const text = `${textArg}\n\n(This is only shown in dev environment)`;
    if (this.el) {
      const el = document.createElement('div');
      el.getAnimations().forEach((a) => a.cancel());

      el.removeAttribute('style');
      el.style.backgroundColor = 'red';
      el.style.color = 'white';
      el.style.marginBlock = '16px';
      el.style.padding = '8px';
      el.innerHTML = text.replaceAll('\n', '<br>');
      this.el.after(el);
    }
  }
}

const JUMP_DETECTED_ERROR_MESSAGE = `jump detected. Possibly because of margins on this or surrounding elements and/or collapsing margins. Elements that could be or contain the cause are outlined.

Things to check/try on them and their children:

        1. remove margins
        2. use flex (not grid) gap on parent instead of margins on this and surrounding elements
        3. there is an element that resizes shortly after being shown (like redactor wysiwyg, pdf viewer, etc.). Maybe use the \`awaitIn=true\` argument to wait for resizing being done
        4. if none of the above works you can disable this check by setting {{#ember-smooth/if devJumpCheck=false}}`;
