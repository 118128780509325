import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex h-full flex-1 flex-col pb-16 pt-10\">\n  {{#if this.isLoading}}\n    <LoadingSpinner />\n  {{else}}\n    {{#if (is-empty this.channelsWithUnreadMessages)}}\n      <EmptyState\n        @header={{t \"global_comments.read_only.title\"}}\n        @emoji=\"🚜\"\n        @color=\"yellow\"\n      >\n        {{t \"global_comments.read_only.description\"}}\n      </EmptyState>\n    {{else}}\n      {{#each this.channelsWithUnreadMessages as |unreadJob|}}\n        <Toolbox::GlobalComments::Unread::UnreadJob @unreadJob={{unreadJob}} />\n      {{/each}}\n    {{/if}}\n  {{/if}}\n</div>", {"contents":"<div class=\"flex h-full flex-1 flex-col pb-16 pt-10\">\n  {{#if this.isLoading}}\n    <LoadingSpinner />\n  {{else}}\n    {{#if (is-empty this.channelsWithUnreadMessages)}}\n      <EmptyState\n        @header={{t \"global_comments.read_only.title\"}}\n        @emoji=\"🚜\"\n        @color=\"yellow\"\n      >\n        {{t \"global_comments.read_only.description\"}}\n      </EmptyState>\n    {{else}}\n      {{#each this.channelsWithUnreadMessages as |unreadJob|}}\n        <Toolbox::GlobalComments::Unread::UnreadJob @unreadJob={{unreadJob}} />\n      {{/each}}\n    {{/if}}\n  {{/if}}\n</div>","moduleName":"teamtailor/components/toolbox/global-comments/unread/unread-channel.hbs","parseOptions":{"srcName":"teamtailor/components/toolbox/global-comments/unread/unread-channel.hbs"}});
import Component from '@glimmer/component';
import LatestReadJobComment from 'teamtailor/models/latest-read-job-comment';

type Args = {
  jobId: string;
  latestUnreadJobComments: LatestReadJobComment[];
};

export default class UnreadChannelComponent extends Component<Args> {
  get channelsWithUnreadMessages() {
    return this.args.latestUnreadJobComments.filter((entry) => entry.unread);
  }
}
