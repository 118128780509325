import { debounce as runloopDebounce } from '@ember/runloop';
import Component from '@glimmer/component';

type MethodDescriptor<
  T extends (...args: unknown[]) => unknown = (...args: unknown[]) => unknown,
> = TypedPropertyDescriptor<T>;

export function debounce(wait?: number) {
  return function <
    Target extends Component<Target['args']>,
    Method extends (...args: any[]) => any,
  >(_target: Target, _key: keyof Target, desc: MethodDescriptor<Method>) {
    const { value } = desc;

    return {
      ...desc,
      value(this: Target, ...args: Parameters<Method>): any {
        // @ts-expect-error the ember runloop types we have now are wrong..
        runloopDebounce(this, value, ...args, wait);
      },
    };
  };
}
