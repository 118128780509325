import Route from '@ember/routing/route';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';

export default class PromoteChannelRoute extends Route {
  @service declare store: Store;

  model(params: { channel_id: string }) {
    return this.store.findRecord('channel', params.channel_id);
  }
}
