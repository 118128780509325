import { inject as service } from '@ember/service';
import ApplicationInstance from '@ember/application/instance';
import { isPresent } from '@ember/utils';
import moment, { Moment } from 'moment-timezone';
import FlipperService from 'teamtailor/services/flipper';
import Current from 'teamtailor/services/current';
import { DocumentNode } from 'graphql';
import AnalyticsService from 'teamtailor/services/analytics';
import InsightsService from 'teamtailor/services/insights';
import { FetchPolicy } from '@apollo/client/core';
import DateRange from 'teamtailor/utils/date-range';
import { get } from 'teamtailor/utils/get';

export const CLICKHOUSE_PAGEVIEWS_TRANSITION_DATE = '2022-07-12';
export const CLICKHOUSE_PAGEVIEWS_EARLY_BIRD_TRANSITION_DATE = '2021-07-12';

export interface FetchOptions {
  dateRange?: DateRange;
  before?: string;
  after?: string;
  fetchPolicy?: FetchPolicy;
  ignoreJobsFilter?: boolean;
  jobIds?: string[];
  companyIds?: string[];
  context?: { [key: string]: number | string };
}

export interface CompareFetchOptions extends FetchOptions {
  dateRange: DateRange;
}

interface ExtraVariables {
  filters?:
    | { jobId?: { exists: boolean } }
    | { path?: { match: string } }
    | null;
  limit?: number;
  onlyJobAds?: boolean;
  jobId?: number;
  userId?: string;
  jobApplicationIds?: number[];
  eventType?: string;
  dateRange?: any;
  cohortDateRange?: any;
  before?: string;
  after?: string;
}
interface ConstructorOptions {
  container: ApplicationInstance;
  query: DocumentNode;
  callback: (result?: any) => any;
  extraVariables?: ExtraVariables;
}

export const getClickhousePageviewsTransitionDate = (
  flipper: FlipperService
) => {
  const earlyBird = get(flipper, 'clickhouse_early_bird');
  if (earlyBird) {
    return CLICKHOUSE_PAGEVIEWS_EARLY_BIRD_TRANSITION_DATE;
  }

  return CLICKHOUSE_PAGEVIEWS_TRANSITION_DATE;
};

export default class ReportAnalyticsRequest {
  @service declare current: Current;
  @service declare insights: InsightsService;
  @service declare analytics: AnalyticsService;
  @service declare flipper: FlipperService;

  container: ApplicationInstance;
  cutoffEndDate?: Moment;
  cutoffStartDate?: Moment;
  query!: DocumentNode;
  callback!: (result?: any) => any;
  extraVariables?: ExtraVariables;

  constructor({
    container,
    query,
    callback,
    extraVariables,
  }: ConstructorOptions) {
    this.container = container;
    this.query = query;
    this.callback = callback;
    this.extraVariables = extraVariables || {};
  }

  async fetch(options: FetchOptions = {}) {
    const { before, after, fetchPolicy, context } = options;
    let { dateRange, jobIds, companyIds } = options;

    if (!jobIds && !options.ignoreJobsFilter) {
      jobIds = this.analytics.selectedJobIds;
    }

    if (!companyIds) {
      companyIds = this.analytics.availableCompanyIds;
    }

    if (before) {
      this.cutoffEndDate = moment(before).subtract(1, 'days');
    }

    this.cutoffStartDate = after ? moment(after) : undefined;

    if (!dateRange) {
      dateRange = this.defaultDateRange;
    } else {
      dateRange = new DateRange(
        this.prepareStartDate(dateRange.startDate),
        this.prepareEndDate(dateRange.endDate)
      );
    }

    if (moment(dateRange.endDate).isBefore(dateRange.startDate)) {
      return this.callback(null);
    }

    const variables = {
      dateRange: dateRange.asObject,
      jobIds,
      companyIds: companyIds.map((id) => id.toString()),
      ...this.extraVariables,
    };

    const result = await this.insights.query({
      query: this.query,
      fetchPolicy,
      variables,

      context,
    });

    return this.callback(result);
  }

  get defaultDateRange() {
    const startDate = this.getStartDate();
    const endDate = this.getEndDate();

    return new DateRange(startDate, endDate);
  }

  getStartDate() {
    return this.prepareStartDate(this.analytics.startDate);
  }

  getEndDate() {
    return this.prepareEndDate(this.analytics.endDate);
  }

  prepareStartDate(startDate: string | Date | Moment) {
    const wentLiveAt =
      get(this.current, 'company.went_live_at') || this.analytics.minDate;

    let limitedStartDate = startDate;
    if (isPresent(wentLiveAt) && moment(startDate).isBefore(wentLiveAt)) {
      limitedStartDate = wentLiveAt;
    }

    if (
      this.cutoffStartDate &&
      moment(limitedStartDate).isBefore(this.cutoffStartDate)
    ) {
      limitedStartDate = this.cutoffStartDate;
    }

    return limitedStartDate;
  }

  prepareEndDate(endDate: string | Date | Moment) {
    if (this.cutoffEndDate && moment(endDate).isAfter(this.cutoffEndDate)) {
      return this.cutoffEndDate;
    }

    return endDate;
  }
}
