import Route from '@ember/routing/route';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';

export default class PromotionRoute extends Route {
  @service declare store: Store;

  model(params: { promotion_id: string }) {
    return this.store.findRecord('promotion', params.promotion_id);
  }
}
