import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import SettingsOrganizationDivisionsIndexController from 'teamtailor/controllers/settings/organization/divisions';

export default class SettingsOrganizationDivisionsRoute extends ScrollToTopRoute {
  queryParams = {
    search: { refreshModel: true },
    page: { refreshModel: true },
    sort_direction: { refreshModel: true },
  };

  resetController(
    controller: SettingsOrganizationDivisionsIndexController,
    isExiting: boolean
  ) {
    if (isExiting) {
      controller.page = 1;
    }
  }
}
