import { getBadgeColor } from 'teamtailor/helpers/badge-color';
import { get } from 'teamtailor/utils/get';
import CustomizeTableColumn from 'teamtailor/utils/candidate-table/column';
import Store from '@ember-data/store';
import QuestionModel from 'teamtailor/models/question';
import { FilterType } from 'teamtailor/components/fancy-filters';
import PartnerActivationModel from 'teamtailor/models/partner-activation';
import CustomFieldModel from 'teamtailor/models/custom-field';
import CompanyModel from 'teamtailor/models/company';
import UserModel from 'teamtailor/models/user';
import uniqBy from 'teamtailor/utils/uniq-by';

export type NestedOptions = {
  name: string;
  id?: string;
  partnerName?: string;
  question?: QuestionModel;
};

type Options = {
  sortable?: boolean;
  dot?: string;
  image?: string;
} & Partial<CustomizeTableColumn>;

type Type = FilterType;

const columnKeyToInstanceMap: Record<string, CustomizeTableColumn> = {};

export const getAvailableColumns = async (
  container: { store: Store; company: CompanyModel; user: UserModel },
  columnsToLoad: 'all' | 'only-selected'
): Promise<CustomizeTableColumn[]> => {
  const { store, user, company } = container;

  const includeExternalRecruiters =
    company.hasFeature('external_recruitment') &&
    !get(user, 'externalRecruiter');

  function createSelect(name: string, type: FilterType, options?: Options) {
    return {
      type,
      name,
      ...options,
    };
  }

  function createMultiSelect(
    name: string,
    type: Type,
    options?: Options,
    modelName = null
  ) {
    return {
      name,
      type,
      ...options,
      modelName,
    };
  }

  function createPolar(name: string, type: FilterType = 'polar') {
    return {
      name,
      type,
      options: ['yes', 'no'],
      translateValue: true,
    };
  }

  function createStatusBoolean(
    name: string,
    {
      type,
      dot,
      displayWithBadges = false,
    }: { type?: Type; dot: string; displayWithBadges?: boolean }
  ) {
    return column(name, {
      ...createPolar(name, type),
      dot,
      displayWithBadges,
    });
  }

  function createRange(name: string): Partial<CustomizeTableColumn> {
    return {
      name,
      type: 'range',
    };
  }

  type PartnerColumn = { nested: NestedOptions };

  function createNested(
    name: string,
    nestedOption: { column?: { name: string } } & Omit<NestedOptions, 'name'> &
      Options
  ): PartnerColumn {
    return {
      nested: { ...nestedOption, name },
    };
  }

  function createExists(type: Type) {
    return {
      type,
      options: null,
    };
  }

  const getSelectedCandidateDisplayColumns = async () => {
    const columns = await get(user, 'selectedCandidateDisplayColumns');
    return columns;
  };

  const customFieldsGenerator = async (
    category: string,
    columnsToLoad: 'all' | 'only-selected'
  ) => {
    let customFields: CustomFieldModel[] | null;

    switch (columnsToLoad) {
      case 'only-selected': {
        customFields = (
          await Promise.all(
            (await getSelectedCandidateDisplayColumns()).map((selectedCol) =>
              get(selectedCol, 'customField')
            )
          )
        ).filter(Boolean);
        break;
      }

      case 'all':
        await store.findAll('custom-field', { reload: true });
        customFields = company.searchableCandidateCustomFields;
        break;
    }

    return customFields
      .sortBy('rowOrder')
      .map<CustomizeTableColumn>((customField) => {
        const out = column(customField.name, {
          category,
          id: customField.id,
          doNotTranslateName: true,
          isCustomField: true,
        });

        switch (customField.type) {
          case 'CustomField::Checkbox':
            out.type = 'polar';
            out.displayWithBadges = true;
            break;
          case 'CustomField::Number':
            out.type = 'integer';
            break;
          case 'CustomField::Select':
          case 'CustomField::MultiSelect':
            out.type = '[Option]';
            out.displayWithBadges = true;
            break;
          case 'CustomField::Date':
            out.type = 'date';
            break;
          case 'CustomField::Text':
          case 'CustomField::Email':
          case 'CustomField::Phone':
          case 'CustomField::Url':
            out.type = 'string';
            break;
        }

        return out;
      });
  };

  function createQuestion(question: QuestionModel) {
    let typeColumn: Partial<CustomizeTableColumn>,
      createMethod: typeof createMultiSelect | typeof createSelect;

    switch (question.type) {
      case 'Question::Boolean':
        typeColumn = createPolar('boolean');
        break;
      case 'Question::Choice':
        if (question.multiple) {
          createMethod = createMultiSelect;
        } else {
          createMethod = createSelect;
        }

        typeColumn = createMethod('choices', '[Alternative]');

        break;
      case 'Question::Range':
        typeColumn = createRange('range');
        break;
      case 'Question::Text':
        typeColumn = { name: 'text', type: 'string' };
        break;
      case 'Question::Number':
        typeColumn = createRange('number');
        break;
      case 'Question::Date':
        typeColumn = createDate('date');
        break;
      case 'Question::Video':
      case 'Question::File':
        typeColumn = createExists('file');
        break;
      default:
        return false;
    }

    return {
      ...typeColumn,
      doNotTranslateName: true,
      translatedName: question.title,
    };
  }

  function createDate(name: string, options = {}) {
    return column(name, {
      type: 'date',
      ...options,
    });
  }

  function column(name: string, options?: Options) {
    let key = name;

    if (options?.id) {
      key += `-id:${options.id}`;
    }

    if (options?.nested?.name) {
      key += `-nestedName:${options.nested.name}`;
    }

    if (options?.nested?.id) {
      key += `-nestedId:${options.nested.id}`;
    }

    if (key in columnKeyToInstanceMap) {
      return columnKeyToInstanceMap[key]!;
    }

    const columnInstance = new CustomizeTableColumn(container, {
      name,
      type: 'string',
      ...options,
    });

    columnKeyToInstanceMap[key] = columnInstance;

    return columnInstance;
  }

  const questionsToColumns = (questions: QuestionModel[], category: string) => {
    const childColumns = questions.map((question) => {
      return column('question', {
        ...createQuestion(question),
        ...createNested('question', {
          id: question.id,
          question,
        }),
      });
    });

    const parentColumn = new CustomizeTableColumn(container, {
      ...createNested('select_question', {}),
      category,
      name: 'select_question',
      childColumns,
    });

    childColumns.forEach((column) => {
      column.parent = parentColumn;
    });

    return childColumns;
  };

  const questionGenerator = async (
    category: string,
    columnsToLoad: 'only-selected' | 'all'
  ) => {
    let loadedQuestions: QuestionModel[];

    switch (columnsToLoad) {
      case 'only-selected': {
        loadedQuestions = (
          await Promise.all(
            (await getSelectedCandidateDisplayColumns()).map((selectedCol) =>
              get(selectedCol, 'question')
            )
          )
        ).filter(Boolean);
        break;
      }

      case 'all':
        loadedQuestions = (
          await store.findAll('question', { reload: true })
        ).toArray();

        break;
    }

    const childColumns = questionsToColumns(loadedQuestions, category);

    return createCategory(category, ...childColumns);
  };

  function createPartner(
    id: string,
    partnerActivation: PartnerActivationModel,
    category: string
  ): CustomizeTableColumn[] {
    const childColumns = [
      column(
        'status',
        createNested('partner', {
          id,
          column: { name: 'status' },
          partnerName: partnerActivation.partnerName,
          displayWithBadges: true,
        })
      ),
      column(
        'score',
        createNested('partner', {
          id,
          partnerName: partnerActivation.partnerName,
          column: { name: 'assessment_score' },
        })
      ),
    ];

    const parentColumn = column('partner', {
      id,
      category,
      image: get(partnerActivation.partner, 'logotypeSquare'),
      doNotTranslateName: true,
      translatedName: partnerActivation.partnerName,
      childColumns,
    });

    childColumns.forEach((column) => {
      column.parent = parentColumn;
      column.hideIcon = true;
    });

    return childColumns;
  }

  const partnerGenerator = async (
    category: string,
    columnsToLoad: 'only-selected' | 'all'
  ) => {
    let partnerActivations: PartnerActivationModel[];

    switch (columnsToLoad) {
      case 'only-selected': {
        await store.findAll('partner-activation');
        partnerActivations = (
          await Promise.all(
            (await getSelectedCandidateDisplayColumns()).map(
              async (selectedCol) => {
                const partner = await get(selectedCol, 'partner');

                return partner ? get(partner, 'partnerActivation') : null;
              }
            )
          )
        ).filter(Boolean);
        break;
      }

      case 'all':
        partnerActivations = (
          await store.findAll('partner-activation', {
            reload: true,
          })
        ).toArray();
        break;
    }

    partnerActivations = uniqBy(partnerActivations, 'partnerName');

    return createCategory(
      category,
      ...partnerActivations.reduce<CustomizeTableColumn[]>(
        (acc, partnerActivation) => {
          return [
            ...acc,
            ...createPartner(
              partnerActivation.partnerId,
              partnerActivation,
              category
            ),
          ];
        },
        []
      )
    );
  };

  function createCategory(
    category: string,
    ...columns: CustomizeTableColumn[]
  ) {
    return columns.map((column) => {
      column.category = category;
      return column;
    });
  }

  const [customFieldRes, partnerRes, questionRes] = await Promise.allSettled([
    customFieldsGenerator('candidate_details', columnsToLoad),
    partnerGenerator('partner_results', columnsToLoad),
    questionGenerator('answers_questions', columnsToLoad),
  ]);

  return [
    ...createCategory(
      'candidate_details',
      column('name_or_email', {
        selectable: true,
        sortable: true,
        defaultSortOrder: 'asc',
        tableClassSuffix: 'name',
        isDisabled: true,
        isDefault: true,
      }),
      column('candidate_status', {
        sortable: false,
        tableClassSuffix: 'statuses',
        isDefault: true,
      }),
      column('application_status', {
        sortable: false,
        tableClassSuffix: 'statuses',
        isDefault: true,
      }),
      column('rating', {
        sortable: true,
        defaultSortOrder: 'desc',
        sortColumnName: 'average_rating',
        tableClassSuffix: 'stars',
        isDefault: true,
      }),
      column('created_at', {
        sortable: true,
        defaultSortOrder: 'desc',
        tableClassSuffix: 'created',
        tableRowIcon: 'clock',
        type: 'date',
        isDefault: true,
      }),
      column('last_activity_at', {
        sortable: true,
        defaultSortOrder: 'desc',
        tableClassSuffix: 'activity',
        tableRowIcon: 'bolt',
        type: 'date',
        isDefault: true,
      }),
      column('tags', {
        tableClassSuffix: 'tags',
        type: '[CandidateTag]',
        isDefault: true,
      }),
      column('first_name'),
      column('last_name'),
      column('email'),
      column('phone'),
      column('referring_url'),
      column('language_code', {
        type: '[Language]',
        displayWithBadges: true,
        sortable: true,
      }),
      column('department_id', {
        type: '[Department]',
        displayWithBadges: true,
      }),
      column('role_id', {
        type: '[Role]',
        displayWithBadges: true,
      }),
      column('location_ids', {
        type: '[Location]',
        displayWithBadges: true,
        sortable: true,
      }),
      column('job_ids', { type: '[Job]', displayWithBadges: true }),
      column('reviewed_by', { type: 'ReviewedBy', displayWithBadges: true }),
      ...('value' in customFieldRes ? customFieldRes.value : [])
    ),
    ...createCategory(
      'dates',
      createDate('connected_at', {
        sortable: true,
      }),
      createDate('sourced_at', {
        sortable: true,
      }),
      createDate('last_application_at', {
        sortable: true,
      }),
      createDate('consent_expires_at', {
        sortable: true,
      }),
      createDate('consent_missing_at', {
        sortable: true,
      }),
      createDate('consent_future_jobs_at', {
        sortable: true,
      }),
      createDate('data_requested_at', {
        sortable: true,
      }),
      createDate('removal_requested_at', {
        sortable: true,
      }),
      createDate('will_be_deleted_at', {
        sortable: true,
      })
    ),

    ...('value' in partnerRes ? partnerRes.value : []),
    ...('value' in questionRes ? questionRes.value : []),

    ...(includeExternalRecruiters
      ? createCategory(
          'external_recruiter',
          createStatusBoolean('sourced_external', {
            dot: getBadgeColor('sourced_external'),
            displayWithBadges: true,
          }),
          column('external_recruiter_id'),
          column('recruiting_firm_id')
        )
      : []),
  ];
};

export const loadAvailableColumns = (
  container: { store: Store; company: CompanyModel; user: UserModel },
  columnsToLoad: 'all' | 'only-selected' = 'only-selected'
) => {
  return getAvailableColumns(container, columnsToLoad);
};
