import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { Promise } from 'rsvp';
import { get } from 'teamtailor/utils/get';

export default class FourOhFourRoute extends Route {
  @service router;
  @service session;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');

    let requestedPath = transition.intent.url;
    if (!/^\/companies\//.test(requestedPath)) {
      if (get(this, 'session.isAuthenticated')) {
        let { companies } = get(this, 'session.data.authenticated');
        let newUrl = `/companies/${companies[0].uuid}${requestedPath}`;
        return new Promise(() => {
          window.location.replace(newUrl);
        });
      }
    }

    super.beforeModel(...arguments);
    return undefined;
  }
}
