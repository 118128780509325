import moment from 'moment-timezone';

export function initialize() {
  moment.locale('en-short', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: 'now',
      ss: '%ss',
      m: 'now',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mo',
      MM: '%dmo',
      y: '1y',
      yy: '%dy',
    },
  });

  moment.locale('en-GB-short', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: 'now',
      ss: '%ss',
      m: 'now',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mo',
      MM: '%dmo',
      y: '1y',
      yy: '%dy',
    },
  });

  moment.locale('az-short', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: 'now',
      ss: '%ss',
      m: 'now',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mo',
      MM: '%dmo',
      y: '1y',
      yy: '%dy',
    },
  });

  moment.locale('fi-short', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: 'nyt',
      ss: '%ssek',
      m: 'nyt',
      mm: '%dmin',
      h: '1t',
      hh: '%dt',
      d: '1pv',
      dd: '%dpv',
      M: '1kk',
      MM: '%dkk',
      y: '1v',
      yy: '%dv',
    },
  });

  moment.locale('fr-short', {
    relativeTime: {
      future: 'dans %s',
      past: '%s',
      s: '1m',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1j',
      dd: '%dj',
      M: '1mois',
      MM: '%dmois',
      y: '1an',
      yy: '%dans',
    },
  });

  moment.locale('fr-CA-short', {
    relativeTime: {
      future: 'dans %s',
      past: '%s',
      s: '1m',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1j',
      dd: '%dj',
      M: '1mois',
      MM: '%dmois',
      y: '1an',
      yy: '%dans',
    },
  });

  moment.locale('de-short', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: 'jetzt',
      m: '1min',
      mm: '%dmin',
      h: '1std',
      hh: '%dstd',
      d: '1t',
      dd: '%dt',
      M: '1m',
      MM: '%dm',
      y: '1j',
      yy: '%dj',
    },
  });

  moment.locale('it-short', {
    relativeTime: {
      future: 'tra %s',
      past: '%s fa',
      s: 'ora',
      ss: '%dss',
      m: 'ora',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1g',
      dd: '%dg',
      M: '1me',
      MM: '%dme',
      y: '1a',
      yy: '%da',
    },
  });

  moment.locale('nb-short', {
    relativeTime: {
      future: 'om %s',
      past: '%s',
      s: 'nå',
      ss: '%ss',
      m: 'nu',
      mm: '%dm',
      h: '1tim',
      hh: '%dtim',
      d: '1dag',
      dd: '%ddag',
      M: '1mån',
      MM: '%dmån',
      y: '1år',
      yy: '%dår',
    },
  });

  moment.locale('sv-short', {
    relativeTime: {
      future: 'om %s',
      past: '%s',
      s: 'nu',
      ss: '%ss',
      m: 'nu',
      mm: '%dm',
      h: '1tim',
      hh: '%dtim',
      d: '1dag',
      dd: '%ddag',
      M: '1mån',
      MM: '%dmån',
      y: '1år',
      yy: '%dår',
    },
  });

  moment.locale('es-short', {
    relativeTime: {
      future: 'en %s',
      past: '%s',
      s: 'ahora',
      ss: '%s segundos',
      m: 'ahora',
      mm: '%d minutos',
      h: '1 hora',
      hh: '%d horas',
      d: '1 día',
      dd: '%d días',
      M: '1 mes',
      MM: '%d meses',
      y: '1 año',
      yy: '%d años',
    },
  });

  moment.locale('da-short', {
    relativeTime: {
      future: 'om %s',
      past: '%s',
      s: 'nu',
      ss: '%ss',
      m: 'nu',
      mm: '%dm',
      h: '1t',
      hh: '%dt',
      d: '1d',
      dd: '%dd',
      M: '1md',
      MM: '%dmd',
      y: '1å',
      yy: '%då',
    },
  });

  moment.locale('et-short', {
    relativeTime: {
      future: 'pärast %s',
      past: '%s',
      s: 'praegu',
      ss: '%ss',
      m: 'praegu',
      mm: '%dm',
      h: '1tund',
      hh: '%dtund',
      d: '1päev',
      dd: '%dpäev',
      M: '1kuu',
      MM: '%dkuu',
      y: '1a',
      yy: '%daa',
    },
  });

  moment.locale('lv-short', {
    relativeTime: {
      future: 'pēc %s',
      past: '%s',
      s: 'tagad',
      ss: '%ss',
      m: 'min',
      mm: '%dm',
      h: '1stunda',
      hh: '%dstunda',
      d: '1dienas',
      dd: '%ddienas',
      M: '1mēneši',
      MM: '%dmēn',
      y: '1gads',
      yy: '%dga',
    },
  });

  moment.locale('lt-short', {
    relativeTime: {
      future: 'po %s',
      past: '%s',
      s: 'dabar',
      ss: '%ss',
      m: 'min',
      mm: '%dm',
      h: '1val',
      hh: '%dval',
      d: '1dieną',
      dd: '%ddieną',
      M: '1mėn',
      MM: '%dmėn',
      y: '1metai',
      yy: '%dmet',
    },
  });

  moment.locale('pt-short', {
    relativeTime: {
      future: 'em %s',
      past: '%s',
      s: 'agora',
      ss: '%ss',
      m: 'agora',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mês',
      MM: '%dm',
      y: '1a',
      yy: '%da',
    },
  });

  moment.locale('pl-short', {
    relativeTime: {
      future: 'za %s',
      past: '%s temu',
      s: 'w tej chwili',
      ss: '%ss',
      m: 'kilka sekund temu',
      mm: '%dm',
      h: '1g',
      hh: '%dg',
      d: '1d',
      dd: '%dd',
      M: '1m',
      MM: '%dm',
      y: '1r',
      yy: '%dl',
    },
  });

  moment.locale('nl-short', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: 'nu',
      ss: '%ss',
      m: 'nu',
      mm: '%dm',
      h: '1uur',
      hh: '%duur',
      d: '1dag',
      dd: '%ddag',
      M: '1maa',
      MM: '%dmaa',
      y: '1j',
      yy: '%dj',
    },
  });

  moment.locale('pt-BR-short', {
    relativeTime: {
      future: 'em %s',
      past: '%s',
      s: 'agora',
      ss: '%ss',
      m: '1 min',
      mm: '%d min',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1 mês',
      MM: '%d meses',
      y: '1a',
      yy: '%da',
    },
  });

  moment.locale('he-short', {
    relativeTime: {
      future: '%sבעוד ',
      past: '%sלפני ',
      s: '1שניה',
      m: '1ד',
      mm: '%d ד',
      h: '1ש',
      hh: '%d ש',
      d: '1י',
      dd: '%d י',
      M: '1ח',
      MM: '%d ח',
      y: '1שנה',
      yy: '%d שנה',
    },
  });

  moment.locale('hu-short', {
    relativeTime: {
      future: '%s-ban',
      past: '%s',
      s: 'most',
      ss: '%s mp',
      m: 'most',
      mm: '%d perc',
      h: '1 óra',
      hh: '%d óra',
      d: '1 nap',
      dd: '%d nap',
      M: '1 hónap',
      MM: '%d hónap',
      y: '1 év',
      yy: '%d év',
    },
  });

  moment.locale('ar', {
    relativeTime: {
      future: 'خلال %s',
      past: 'منذ %s',
      s: 'عدة ثوانٍ',
      ss: '%d ثوانٍ',
      m: 'دقيقة',
      mm: '%d دقائق',
      h: 'ساعة',
      hh: '%d ساعات',
      d: 'a يوم',
      dd: '%d أيام',
      M: 'شهر',
      MM: '%d شهور',
      y: 'سنة',
      yy: '%d سنوات',
    },
  });

  moment.locale('sk', {
    relativeTime: {
      future: ' za %s',
      past: ' pred %s',
      s: 'pár s',
      m: 'm',
      mm: '%d m',
      h: 'h',
      hh: '%d h',
      d: 'd',
      dd: '%d d',
      M: 'm',
      MM: '%d m',
      y: 'r',
      yy: '%d ',
    },
  });

  moment.locale('cs-short', {
    relativeTime: {
      future: 'za %s',
      past: 'před %s',
      s: 'několik sekund',
      m: 'm',
      mm: '%d m',
      h: 'h',
      hh: '%d h',
      d: 'd',
      dd: '%d d',
      M: 'měs.',
      MM: '%d měs.',
      y: 'r',
      yy: '%d r',
    },
  });

  moment.locale('ro-short', {
    relativeTime: {
      future: 'în %s',
      past: 'acum%s',
      s: '1mom',
      m: '1m',
      mm: '%dmm',
      h: '1h',
      hh: '%dhh',
      d: '1z',
      dd: '%dzz',
      M: '1M',
      MM: '%d MM',
      y: '1A',
      yy: '%d AA',
    },
  });

  moment.locale('zh-HK-short', {
    relativeTime: {
      future: '%s后',
      past: '%s 前',
      s: '数秒',
      m: '1分',
      mm: '%d分',
      h: '1小时',
      hh: '%d小时',
      d: '1天',
      dd: '%d天',
      M: '1个月',
      MM: '%d个月',
      y: '1年',
      yy: '%d年',
    },
  });

  moment.locale('el', {
    relativeTime: {
      future: 'σε %s',
      past: 'πριν %s',
      s: 'δ',
      m: 'λ',
      mm: '%d λ',
      h: 'ω',
      hh: '%d ω',
      d: 'η',
      dd: '%d η',
      M: 'μ',
      MM: '%d μ',
      y: 'ε',
      yy: '%d ε',
    },
  });

  moment.locale('zh-TW', {
    relativeTime: {
      future: '%s後',
      past: '%s前',
      s: '幾秒鐘',
      m: '1分鐘',
      mm: '%d分鐘',
      h: '1小時',
      hh: '%d小時',
      d: '1天',
      dd: '%d天',
      M: '1個月',
      MM: '%d個月',
      y: '1年',
      yy: '%d年',
    },
  });
}

export default {
  name: 'moment',
  initialize,
};
