import Component from '@glimmer/component';

import {
  RemoteParticipant,
  LocalParticipant,
  RemoteVideoTrack,
} from 'twilio-video';

import { AudioVideoFacade } from 'amazon-chime-sdk-js';
import {
  AteendeeIdsToTilesMap,
  MeetingAttendee,
  RemoteParticipantScreenShare,
} from 'teamtailor/utils/video-meetings/utils';

export interface VideoMeetingViewsBaseArgs {
  localParticipant: LocalParticipant;
  dominantParticipant?: RemoteParticipant | MeetingAttendee;
  previousDominantParticipant?: RemoteParticipant | MeetingAttendee;
  remoteParticipants: RemoteParticipant[];
  remoteScreenTrack?: RemoteVideoTrack;
  participantCount: number;
  screen: boolean;
  sharing: boolean;
  // Chime
  isNewProvider: boolean;
  audioVideoFacade?: AudioVideoFacade;
  tilesMap?: AteendeeIdsToTilesMap;
  remoteParticipantScreenShare?: RemoteParticipantScreenShare;
}

export default class VideoMeetingViewsBase<
  T = Record<string, unknown>,
> extends Component<VideoMeetingViewsBaseArgs & T> {
  get participantCount(): number {
    return this.args.participantCount || 0;
  }

  get speakerParticipant(): RemoteParticipant | MeetingAttendee | undefined {
    return (
      this.args.dominantParticipant ||
      this.args.previousDominantParticipant ||
      this.args.remoteParticipants[0]
    );
  }
}
