import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'teamtailor/config/environment';
import './inflector-rules';
import * as Sentry from '@sentry/ember';

import '@teamtailor/design-tokens/src/output/theme-light.css';
import '@teamtailor/design-tokens/src/output/theme-dark.css';
import 'flatpickr/dist/flatpickr.css';
import { polyfillUIID } from 'teamtailor/utils/uuid-polyfill';

polyfillUIID();

Sentry.init({
  release: config['ember-cli-app-version']?.version,
  enabled: config.environment === 'production',
  environment: config.APP_NAME,
  dsn: 'https://e2aa8a9d628e4e25ba286eca14175cc4@errors.teamtailor.com/13',
  sampleRate: 0.25,
  tracesSampleRate: 0.2,
  ignoreErrors: [
    'TransitionAborted',
    'UnauthorizedError',
    'returned a 401',
    'returned a 404 Payload',
    'to be present in the adapter provided payload, but it was not found',
  ],
});

export default class App extends Application {
  modulePrefix = config.modulePrefix;

  podModulePrefix = config.podModulePrefix;

  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
