import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';

const AVAILABLE_ACTIVATION_COLUMNS = [
  {
    label: 'settings.integrations.marketplace_activations.name',
  },
  {
    label: 'settings.integrations.marketplace_activations.type',
  },
  {
    label: '',
  },
];

export default class MarketplaceActivationsController extends Controller {
  @service current;
  @service intl;
  @service router;
  @service ttAlert;
  @service flashMessages;

  columns = AVAILABLE_ACTIVATION_COLUMNS;

  get availableChannelActivations() {
    const channelActivations = get(
      this.current.company,
      'channelActivations'
    ).filter((channelActivation) => {
      const hasActivationForm =
        get(channelActivation, 'channelActivationFields') ||
        get(channelActivation, 'channel.hasCustomSettings');

      return (
        hasActivationForm || get(channelActivation, 'channel.isAlwaysIncluded')
      );
    });

    return channelActivations
      .sort((a, b) => {
        const nameA = get(a, 'channel.name')?.toLowerCase() || '';
        const nameB = get(b, 'channel.name')?.toLowerCase() || '';

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
      .map((channelActivation) => ({
        model: channelActivation,
        route: 'channel-activation',
        name: this.activationName(channelActivation),
        type: 'Channel',
        buttonType: this.channelActivationActionType(channelActivation),
        displayButton:
          get(channelActivation, 'channel.activationType') === 'customer' ||
          get(channelActivation, 'channel.activationType') === 'oauth',
      }));
  }

  get availablePartnerActivations() {
    const partnerActivations = get(this.current.company, 'partnerActivations');
    const customerActivation = partnerActivations.filter(
      (partnerActivation) =>
        get(partnerActivation, 'partner.activationType') !== 'contact_support'
    );
    return customerActivation
      .sort((a, b) => {
        const nameA = a.partner?.name?.toLowerCase() || '';
        const nameB = b.partner?.name?.toLowerCase() || '';

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
      .map((partnerActivation) => ({
        model: partnerActivation,
        route: 'partner-activation',
        name: this.activationName(partnerActivation),
        type: 'Partner',
        buttonType: 'view',
        displayButton:
          get(partnerActivation, 'partner.activationType') === 'customer' ||
          get(partnerActivation, 'partner.activationType') === 'oauth',
      }));
  }

  get availableActivations() {
    return [
      ...this.availableChannelActivations,
      ...this.availablePartnerActivations,
    ];
  }

  get marketplaceUrl() {
    return this.router.urlFor('marketplace');
  }

  channelActivationActionType(activation) {
    const channel = get(activation, 'channel');
    if (
      get(channel, 'hasCustomSettings') ||
      get(channel, 'activationConfig') ||
      get(channel, 'activationType') === 'oauth'
    ) {
      return 'view';
    } else {
      return 'deactivate';
    }
  }

  activationName(activation) {
    return get(activation, 'channel.name') || get(activation, 'partner.name');
  }

  @action
  async confirmDeactivate(activation) {
    this.ttAlert.customConfirm({
      title: this.intl.t(
        'settings.integrations.marketplace_activations.confirm_deactivation'
      ),

      text: this.intl.t(
        'settings.integrations.marketplace_activations.deactivation_description',
        {
          activationName: this.activationName(activation),
        }
      ),

      confirmButtonText: this.intl.t(
        'settings.integrations.marketplace_activations.deactivation_confirmation'
      ),

      cancelButtonText: this.intl.t(
        'settings.integrations.marketplace_activations.deactivation_cancel'
      ),

      confirmCallback: async () => {
        await activation.destroyRecord();
        this.flashMessages.success(
          this.intl.t(
            'settings.integrations.marketplace_activations.channel_deleted',
            { activationName: this.activationName(activation) }
          )
        );
        this.router.transitionTo(
          'settings.integrations.marketplace-activations'
        );
      },

      cancelCallback: () => {},
    });
  }

  @action
  viewSettings(activation) {
    const customRoutesChannels = [
      'indeed_always_included',
      'indeed',
      'linkedin_limited_listings',
      'facebook_jobs',
    ];

    const activationRoute = get(activation, 'route');
    let route = `settings.integrations.${activationRoute}`;

    const channel = get(activation.model, 'channel');
    if (channel && get(channel, 'hasCustomSettings')) {
      const customRoute =
        customRoutesChannels.find(
          (customRoute) => customRoute === get(channel, 'internalName')
        ) || 'custom_form_settings';

      route += `.${customRoute}`;
    }

    this.router.transitionTo(route, get(activation, 'model'));
  }
}
