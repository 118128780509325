import Store from '@ember-data/store';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { afterRender } from 'teamtailor/ember-smooth';
import { CloseReason } from 'teamtailor/components/trigger-modal';
import { get } from 'teamtailor/utils/get';
import EditController from './edit';

export default class NewController extends EditController {
  @service declare router: RouterService;
  @service declare store: Store;

  queryParams = ['type', 'stageId', 'partnerActivationId', 'subtype'];
  stageId = null;
  type = null;
  partnerActivationId = null;

  @tracked defaultTransitionOut = false;

  @action
  async handleClose(closeReason: CloseReason) {
    this.defaultTransitionOut = closeReason !== 'saved';
    if (this.defaultTransitionOut) {
      this.router.transitionTo('jobs.job.stages.stage-triggers');
    } else {
      await afterRender();
      const triggerCardEl = document.querySelector(
        `.stage-trigger-card-${get(this.model, 'id')}`
      );
      this.transitionToTriggerCard(triggerCardEl);
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'jobs.job.stages.stage-triggers.new': NewController;
  }
}
