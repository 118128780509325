import Modifier from 'ember-modifier';
import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { registerDestructor } from '@ember/destroyable';

function cleanup(instance) {
  let { element, scheduleResize } = instance;

  if (element && scheduleResize) {
    element.removeEventListener('input', scheduleResize);
    instance.element = null;
  }
}

export default class AutoresizeModifier extends Modifier {
  element = null;
  isEnabled = true;

  @action
  resize() {
    let { element } = this;

    element.style.height = 'auto';

    let isBorderBox =
      window.getComputedStyle(element).boxSizing === 'border-box';
    let requiredHeight = element.scrollHeight;

    if (isBorderBox) {
      let borderHeight = element.offsetHeight - element.clientHeight;
      requiredHeight += borderHeight;
    }

    element.style.height = `${requiredHeight}px`;
  }

  modify(element, [isEnabled, value]) {
    this.isEnabled =
      typeof isEnabled == 'boolean' ? isEnabled : (value ?? true);

    this.scheduleResize();
    if (!this.element) {
      this.element = element;

      this.element.addEventListener('input', this.scheduleResize);
      registerDestructor(this, cleanup);
    }
  }

  @action
  scheduleResize() {
    if (this.isEnabled) {
      scheduleOnce('afterRender', this, 'resize');
    }
  }
}
