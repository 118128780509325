import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import CandidateModel from 'teamtailor/models/candidate';
import UserModel from 'teamtailor/models/user';

export default class DataRequestModel extends Model {
  @belongsTo('candidate') declare candidate: AsyncBelongsTo<CandidateModel>;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;

  @attr declare handledAt: Date;
  @attr declare createdAt: Date;
  @attr declare tables: string[];
  @attr declare messageSubject: string;
  @attr declare messageBody: string;

  get isPending() {
    return !this.handledAt;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'data-request': DataRequestModel;
  }
}
