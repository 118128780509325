import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"flex items-center justify-between rounded-8 border border-neutral-strong px-4 py-4\"\n  ...attributes\n>\n  <Button\n    class=\"size-24\"\n    @appearance=\"secondary\"\n    @icon=\"minus\"\n    @onClick={{fn this.onButtonsClick -1}}\n    @tooltip={{t \"components.counter.minus_1\"}}\n  />\n\n  <Input\n    id=\"counter-input\"\n    class=\"h-24 border-none text-center [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none\"\n    min={{this.min}}\n    @value={{this.value}}\n    @type=\"number\"\n    {{on \"change\" this.handleChange}}\n  />\n\n  <Button\n    class=\"size-24\"\n    @appearance=\"secondary\"\n    @icon=\"plus\"\n    @onClick={{fn this.onButtonsClick 1}}\n    @tooltip={{t \"components.counter.plus_1\"}}\n  />\n</div>", {"contents":"<div\n  class=\"flex items-center justify-between rounded-8 border border-neutral-strong px-4 py-4\"\n  ...attributes\n>\n  <Button\n    class=\"size-24\"\n    @appearance=\"secondary\"\n    @icon=\"minus\"\n    @onClick={{fn this.onButtonsClick -1}}\n    @tooltip={{t \"components.counter.minus_1\"}}\n  />\n\n  <Input\n    id=\"counter-input\"\n    class=\"h-24 border-none text-center [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none\"\n    min={{this.min}}\n    @value={{this.value}}\n    @type=\"number\"\n    {{on \"change\" this.handleChange}}\n  />\n\n  <Button\n    class=\"size-24\"\n    @appearance=\"secondary\"\n    @icon=\"plus\"\n    @onClick={{fn this.onButtonsClick 1}}\n    @tooltip={{t \"components.counter.plus_1\"}}\n  />\n</div>","moduleName":"teamtailor/components/counter.hbs","parseOptions":{"srcName":"teamtailor/components/counter.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export type Args = {
  value: number;
  min: number;
  onValueChange: (value: number) => void;
};

export default class CounterComponent extends Component<Args> {
  @tracked value = this.args.value || 0;

  get min(): number {
    return this.args.min || 0;
  }

  @action
  onButtonsClick(amount: number) {
    const newValue = +this.value + amount;
    this.updateValue(newValue);
  }

  @action
  handleChange(event: Event) {
    const value = +(event.target as HTMLInputElement).value;
    this.updateValue(value);
  }

  updateValue(value: number): void {
    if (value >= this.min) {
      this.value = value;
      this.args.onValueChange(value);
    }
  }
}
