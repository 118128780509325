import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import SettingsOrganizationDivisionsEditController from 'teamtailor/controllers/settings/organization/divisions/edit';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';

export default class NewRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model() {
    return this.store.createRecord('division');
  }

  afterModel() {
    this.controllerName = 'settings.organization.divisions.edit';
  }

  resetController(
    controller: SettingsOrganizationDivisionsEditController,
    isExiting: boolean
  ) {
    if (isExiting && get(controller.model, 'isNew')) {
      controller.model.unloadRecord();
    }
  }
}
