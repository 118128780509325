import ActiveFilter from 'teamtailor/components/fancy-filters/active-filter';
import { QueryType } from '../base-query';
import { ReportFilterOption } from '../filters';
import { tracked } from '@glimmer/tracking';
import { camelize } from '@ember/string';

export default class SelectedFilter extends ActiveFilter {
  @tracked filter?: ReportFilterOption;

  getFieldName(queryType: QueryType) {
    switch (queryType) {
      case 'event':
        return this.filter?.eventFieldName;
      case 'pageview':
        return this.filter?.pageviewFieldName;
      case 'combined':
        return this.filter?.combinedFieldName;
      default:
        return 'error';
    }
  }

  getString(queryType: QueryType) {
    if (!this.selectedOperator) return '';

    const filterOptions = this.filter?.filterOptions
      ? Object.keys(this.filter.filterOptions).map(
          (key: string) =>
            this.filter?.filterOptions &&
            `${key}: "${this.filter.filterOptions[key]}"`
        )
      : [];
    const propertyName = this.getFieldName(queryType);
    return `${propertyName}: {
      ${camelize(this.selectedOperator.operatorName)}: ${
        this.selectedOperator.stringifiedValue
      }${filterOptions.length > 0 ? ', ' : ''}${filterOptions.join(`,
      `)}
    }`;
  }

  get modelName() {
    return this.filter?.modelName;
  }

  get modelKey() {
    return this.filter?.modelKey;
  }

  get stringifiedValue(): string {
    const value = this.serializedValue;
    if (Array.isArray(value)) {
      return `[${value.join(', ')}]`;
    }

    return value.toString();
  }
}
