import ApplicationInstance from '@ember/application/instance';
import { gql } from '@apollo/client/core';
import ReportAnalyticsRequest from './report-analytics-request';
import {
  AnalyticsReportBuilder,
  BuildReportArgs,
} from './analytics-report-builder';
import moment from 'moment-timezone';
import { get } from 'teamtailor/utils/get';
import fetchInBatches from 'teamtailor/utils/insights/fetch-in-batches';
import uniq from 'teamtailor/utils/uniq';

const QUERY = gql`
  query UserReferralsQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $companyIds: [ID!]
  ) {
    eventQuery(
      dateRange: $dateRange
      jobIds: $jobIds
      eventTypes: [REFERRAL]
      filters: [{ userId: { exists: true } }]
      orderBy: { field: TIMESTAMP, desc: true }
      companyIds: $companyIds
    ) {
      referrals: rows {
        userId
        candidateId
        jobId
        timestamp: keenTimestamp
      }
    }
  }
`;

interface Row {
  userId: number;
  userNameOrEmail?: string;
  companyName?: string;
  referrals: {
    candidateId: number;
    jobId: number | null;
    timestamp: number;
  }[];
}

export interface ResponseRow {
  timestamp: number;
  jobId: number | null;
  userId: number;
  candidateId: number;
}

interface Response {
  eventQuery: {
    referrals: ResponseRow[];
  } | null;
}

export interface Referral {
  userId: number;
  jobId?: number;
  jobIds: number[];
  candidateId: number;
  candidateIds: number[];
  numberOfReferrals: number;
  numberOfUsers?: number;
  timestamp: number;
}

interface EmployeeReferralsReportArgs {
  container: ApplicationInstance;
  rows: ResponseRow[];
  _data: Row[];
}

class EmployeeReferralsReport {
  container!: ApplicationInstance;
  rows: ResponseRow[] = [];
  _data: Row[] | null = null;

  constructor(args: EmployeeReferralsReportArgs) {
    Object.assign(this, args);
  }

  get referrals() {
    return this.data.referrals;
  }

  get numberOfReferrals() {
    return this.data.numberOfReferrals;
  }

  get numberOfUsers() {
    return this.data.numberOfUsers;
  }

  get data() {
    const rows: Row[] = this._data || [];

    const userIds = rows.map((row) => row.userId);
    const uniqueUserIds = Array.from(new Set(userIds));

    let referrals = rows.map(function (referralUser: Row) {
      const { referrals, userId, userNameOrEmail, companyName } = referralUser;
      const row = referrals[0];

      if (!row) return null;

      const { candidateId, jobId, timestamp } = row;
      const timestampInSeconds = moment(timestamp).unix();
      return {
        userId,
        userNameOrEmail,
        jobId,
        jobIds: referrals.map((row) => row.jobId),
        candidateId,
        candidateIds: referrals.map((row) => row.candidateId),
        numberOfReferrals: referrals.length,
        timestamp: timestampInSeconds,
        companyName,
      };
    });
    referrals = referrals.compact();

    return {
      numberOfReferrals: this.rows.length || 0,
      numberOfUsers: uniqueUserIds.length,

      referrals: (referrals as Referral[]).sort(
        (a, b) => b.timestamp - a.timestamp
      ),
    };
  }
}

type UserType = {
  id: string;
  nameOrEmail: string;
  avatarUrl?: string;
  company?: {
    id: string;
    name: string;
  };
};

type UsersResponseType = {
  users: UserType[];
};

export function buildReport(reportArgs: BuildReportArgs) {
  const { container } = reportArgs;
  return new AnalyticsReportBuilder<
    EmployeeReferralsReport,
    EmployeeReferralsReportArgs
  >(container, {
    query: async () => {
      return fetch(container);
    },

    createReport: (queryResult: EmployeeReferralsReportArgs) => {
      return new EmployeeReferralsReport(queryResult);
    },
  });
}

export async function fetch(container: ApplicationInstance) {
  const rows: ResponseRow[] = await new ReportAnalyticsRequest({
    container,
    query: QUERY,

    callback: (result: Response | null) => result?.eventQuery?.referrals || [],
  }).fetch();

  const _data = rows.reduce((acc: Row[], row: ResponseRow) => {
    const { userId, candidateId, jobId, timestamp } = row;
    const existingRow: Row | undefined = acc.find(
      (row) => row.userId === userId
    );

    if (existingRow) {
      existingRow.referrals.push({ candidateId, jobId, timestamp });
    } else {
      acc.push({
        userId,
        referrals: [{ candidateId, jobId, timestamp }],
      });
    }

    return acc;
  }, []);

  const analyticsService = container.lookup('service:analytics');
  const intlService = container.lookup('service:intl');
  const apolloService = container.lookup('service:apollo');
  const currentService = container.lookup('service:current');

  const userIds = uniq(_data.map((row) => row.userId.toString()));

  const users = await fetchInBatches<UserType, UsersResponseType>(
    userIds,
    (ids: string[]) =>
      apolloService.query({
        query: gql`
          query UsersQuery(
            $filter: UsersFilterInput
            $userId: ID!
            $companyIds: [ID!]
          ) {
            users(
              filter: $filter
              userScope: { userId: $userId }
              groupCompanyIds: $companyIds
            ) {
              id
              nameOrEmail
              company {
                id
                name
              }
            }
          }
        `,
        variables: {
          filter: { ids },
          userId: get(currentService.user, 'id'),
          companyIds: analyticsService.availableCompanyIds,
        },
      }),
    (acc, response) => {
      return acc.concat(response.users);
    }
  );

  const deletedUserString = intlService.t('common.deleted_user');
  const notAvailableString = intlService.t(
    'components.data_table.not_available'
  );

  const combinedData = _data.map((row) => {
    const user = users.find(
      (user: UserType) => user.id.toString() === row.userId.toString()
    );

    return {
      ...row,
      userNameOrEmail: user?.nameOrEmail || deletedUserString,
      companyName: user?.company?.name || notAvailableString,
    };
  });

  return {
    container,
    rows,
    _data: combinedData,
  };
}

export default EmployeeReferralsReport;
