import Modifier, { ArgsFor } from 'ember-modifier';
import { animate, KeyframeSimple } from 'teamtailor/ember-smooth';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import Owner from '@ember/owner';

type CallbackFn = (el: HTMLElement) => Promise<HTMLElement>;
type CbFnOrKeyframe = CallbackFn | KeyframeSimple;

interface Signature {
  Element: HTMLElement;
  Args: {
    Named: {
      scrollSyncSelector?: string;
      skipIn?: boolean;
      skipOut?: boolean;
      duration?: number;
      in?: CbFnOrKeyframe;
      out?: CbFnOrKeyframe;
      delay?: number;
    };
  };
}

export default class AnimateModifier<
  T extends Record<string, unknown>,
> extends Modifier<Signature> {
  constructor(owner: Owner, args: ArgsFor<Signature>) {
    super(owner, args);
    registerDestructor(this, this.destructor);
  }

  clone?: HTMLElement | null;
  parentElement?: HTMLElement | null;
  nextElementSibling?: Element | null;
  scrollTop?: number;
  isFirstRun = true;
  declare named: ArgsFor<Signature>['named'];
  declare positional: ArgsFor<Signature>['positional'];
  declare _el: HTMLElement;

  /**
   * @type {(HTMLElement|undefined)}
   * @private
   * @readonly
   */
  get el() {
    return this.clone || this._el;
  }

  set el(el: HTMLElement) {
    this._el = el;
  }

  modify(
    element: HTMLElement,
    positional: [],
    named: {
      skipIn?: boolean | undefined;
      skipOut?: boolean | undefined;
      duration?: number | undefined;
      in?: (CbFnOrKeyframe & T) | undefined;
      out?: (CbFnOrKeyframe & T) | undefined;
      delay?: number | undefined;
    }
  ): void {
    this.parentElement = element.parentElement;
    this.nextElementSibling = element.nextElementSibling;
    this.positional = positional;
    this.named = named;
    this.el = element;

    if (this.isFirstRun) {
      if (this.named.scrollSyncSelector) {
        this.el
          .querySelector(this.named.scrollSyncSelector)
          ?.addEventListener(
            'scroll',
            (e) =>
              (this.scrollTop = (
                e.currentTarget as HTMLElement | undefined
              )?.scrollTop)
          );
      }

      this.transitionIn();
      this.isFirstRun = false;
    }
  }

  @action destructor() {
    this.transitionOut();
  }

  /**
   * Adds a clone to the parentElement so it can be transitioned out
   *
   * @private
   * @method addClone
   */
  addClone() {
    const original = this._el;
    const parentElement = original.parentElement || this.parentElement;
    let nextElementSibling =
      original.nextElementSibling || this.nextElementSibling;

    if (
      nextElementSibling &&
      nextElementSibling.parentElement !== parentElement
    ) {
      nextElementSibling = null;
    }

    if (parentElement?.isConnected) {
      const clone = original.cloneNode(true) as HTMLElement;
      parentElement.insertBefore(clone, nextElementSibling!);

      if (this.scrollTop && this.named.scrollSyncSelector) {
        clone.querySelector(this.named.scrollSyncSelector)!.scrollTop =
          this.scrollTop;
      }

      this.clone = clone;
    }
  }

  /**
   * Removes the clone from the parentElement
   *
   * @private
   * @method removeClone
   */
  removeClone() {
    if (this.clone?.isConnected && this.clone.parentNode !== null) {
      this.clone.parentNode.removeChild(this.clone);
    }
  }

  async transitionIn() {
    const { skipIn, ...options } = this.named;
    if (!skipIn) {
      if (isCallbackFn(this.named.in)) {
        await this.named.in(this.el);
      } else if (this.named.in) {
        const keyframe = this.named.in;

        await animate.from(this.el, keyframe, options);
      }
    }
  }

  async transitionOut() {
    if (this.named.out && !this.named.skipOut) {
      // We can't stop ember from removing the element
      // so we clone the element to animate it out
      this.addClone();

      if (isCallbackFn(this.named.out)) {
        await this.named.out(this.el);
      } else {
        const { easing, ...keyframe } = this.named.out;
        const { duration } = this.named;
        await animate.to(this.el, keyframe, { duration, easing });
      }

      this.removeClone();

      this.clone = null;
    }
  }
}

const isCallbackFn = (a?: CbFnOrKeyframe): a is CallbackFn =>
  typeof a === 'function';
