import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @customForm}}\n  <Requisition::CustomFormDataValue\n    @customField={{this.customField}}\n    @customFields={{@customForm.form}}\n    @value={{@value}}\n    @requisition={{@requisition}}\n  />\n{{else}}\n  {{#if (and @value this.promiseField)}}\n    {{get this.readableValue this.promiseField}}\n  {{else}}\n    {{this.readableValue}}\n  {{/if}}\n{{/if}}", {"contents":"{{#if @customForm}}\n  <Requisition::CustomFormDataValue\n    @customField={{this.customField}}\n    @customFields={{@customForm.form}}\n    @value={{@value}}\n    @requisition={{@requisition}}\n  />\n{{else}}\n  {{#if (and @value this.promiseField)}}\n    {{get this.readableValue this.promiseField}}\n  {{else}}\n    {{this.readableValue}}\n  {{/if}}\n{{/if}}","moduleName":"teamtailor/components/requisition/detail-diff-value.hbs","parseOptions":{"srcName":"teamtailor/components/requisition/detail-diff-value.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class RequisitionDetailDiffValueComponent extends Component {
  @service store;

  get readableValue() {
    const { type, value } = this.args;

    if (!value) {
      return '-';
    }

    const modelToFetch =
      type === 'department_id'
        ? 'department'
        : type === 'location_id'
          ? 'location'
          : type === 'role_id'
            ? 'role'
            : type === 'recruiter_id'
              ? 'user'
              : null;

    return modelToFetch ? this.store.findRecord(modelToFetch, value) : value;
  }

  get customField() {
    const { type, customForm } = this.args;

    return customForm.form.find((field) => field.uuid === type);
  }

  get promiseField() {
    switch (this.args.type) {
      case 'department_id':
      case 'role_id':
        return 'name';

      case 'location_id':
        return 'nameOrCity';

      case 'recruiter_id':
        return 'nameOrEmail';

      default:
        return null;
    }
  }
}
