import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import SettingsOrganizationDepartmentsIndexController from 'teamtailor/controllers/settings/organization/departments';

export default class SettingsOrganizationDepartmentsRoute extends ScrollToTopRoute {
  queryParams = {
    search: { refreshModel: true },
    page: { refreshModel: true },
    sort_direction: { refreshModel: true },
  };

  resetController(
    controller: SettingsOrganizationDepartmentsIndexController,
    isExiting: boolean
  ) {
    if (isExiting) {
      controller.page = 1;
    }
  }
}
