import Controller from '@ember/controller';
import round from 'teamtailor/utils/round';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';
import IntlService from 'ember-intl/services/intl';
import AnalyticsService from 'teamtailor/services/analytics';
import { StatBarItem } from 'teamtailor/components/stat-bar';

export default class AnalyticsOverviewPipelineSpeedController extends Controller {
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;

  @tracked sortDirection = 'asc';
  @tracked _sortProperty = 'orderIndex';

  @tracked showingStageBreakdown = false;

  get sortProperty() {
    return this._sortProperty === 'orderIndex' && this.showingStageBreakdown
      ? 'stage'
      : this._sortProperty;
  }

  set sortProperty(value: string) {
    this._sortProperty = value;
  }

  get data() {
    return this.model.speed.value;
  }

  get rows() {
    if (!this.data) {
      return [];
    }

    return this.showingStageBreakdown
      ? this.data.rowsByName
      : this.data.rowsByStageType;
  }

  get allStats() {
    return [this.averageTimeToHiredStats, this.averageTimeToRejectStats];
  }

  get averageTimeToHiredStats(): StatBarItem {
    return {
      value: this.data.averageTimeToHired,
      title: this.intl.t('reports.days_until_hired'),
      icon: 'thumbs-up',
    };
  }

  get averageTimeToRejectStats(): StatBarItem {
    return {
      value: this.data.averageTimeToReject,
      title: this.intl.t('reports.days_to_reject'),
      icon: 'ban',
    };
  }

  get sortedRows() {
    let { sortProperty } = this;
    if (sortProperty === 'stage') {
      sortProperty = 'stage.name';
    }

    if (this.sortDirection === 'asc') {
      return this.rows.sortBy(sortProperty);
    } else {
      return this.rows.sortBy(sortProperty).reverse();
    }
  }

  @action
  handleExport() {
    jsonToCsvFile(
      'pipeline-speed',
      this.analytics.startDate,
      this.analytics.endDate,
      this.sortedRows,
      (row: any) => {
        const primaryField = this.showingStageBreakdown ? 'name' : 'stage_type';

        return {
          [primaryField]: this.showingStageBreakdown
            ? row.name
            : row.humanStageType,

          average: round(
            moment.duration(row.averageDuration, 'seconds').asDays()
          ),

          min: round(moment.duration(row.minDuration, 'seconds').asDays()),
          max: round(moment.duration(row.maxDuration, 'seconds').asDays()),
        };
      }
    );
  }

  @action
  handleSort(sortBy: string) {
    if (this.sortProperty === sortBy) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortDirection = 'desc';
      this.sortProperty = sortBy;
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.overview.pipeline-speed': AnalyticsOverviewPipelineSpeedController;
  }
}
