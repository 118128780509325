import { Transition } from 'teamtailor/utils/type-utils';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';

export default class SettingsOrganizationIndexRoute extends ScrollToTopRoute {
  @service declare router: RouterService;

  beforeModel(transition: Transition) {
    if (transition.to.name === 'settings.organization.index') {
      if (this.current.company.hasFeature('divisions')) {
        this.router.transitionTo('settings.organization.divisions.index');
      } else {
        this.router.transitionTo('settings.organization.departments.index');
      }
    }
  }
}
