import Model, { attr } from '@ember-data/model';
import Inflector from 'ember-inflector';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

export default class CookieDetailModel extends Model {
  @service declare intl: IntlService;

  @attr('string') declare name: string;
  @attr('string') declare vendor: string;
  @attr('string') declare description: string;
  @attr('string') declare externalDescriptionLink: string;
  @attr('string') declare type: string;
  @attr('number') declare amount: number;
  @attr('string') declare unit: string;
  @attr('boolean', { defaultValue: false }) declare sessionCookie: boolean;
  @attr('boolean', { defaultValue: true }) declare editable: boolean;
  @attr('boolean', { defaultValue: false }) declare togglable: boolean;
  @attr('boolean') declare enabled: boolean;

  get expires(): string {
    if (this.amount && this.unit) {
      if (this.amount === 1) {
        const singularizedUnit = Inflector.inflector.singularize(this.unit);
        return `${this.amount} ${singularizedUnit}`;
      }

      return `${this.amount} ${this.unit}`;
    } else {
      return this.intl.t('components.cookie_form.varying');
    }
  }

  get isAnalytics(): boolean {
    return this.type === 'analytics';
  }

  get isMarketing(): boolean {
    return this.type === 'marketing';
  }

  get isPreferences(): boolean {
    return this.type === 'preferences';
  }

  get isNecessary(): boolean {
    return this.type === 'necessary';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'cookie-detail': CookieDetailModel;
  }
}
