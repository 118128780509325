import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import jsonToCsvFile from 'teamtailor/utils/json-to-csv-file';
import AnalyticsService from 'teamtailor/services/analytics';
import {
  Referral,
  ResponseRow,
} from 'teamtailor/classes/analytics/report-employee-referrals';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';
import uniq from 'teamtailor/utils/uniq';
import { StatBarItem } from 'teamtailor/components/stat-bar';

export default class ReferralsOverviewController extends Controller {
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked sortDirection = 'desc';
  @tracked sortProperty = 'numberOfReferrals';

  get rows(): ResponseRow[] {
    return this.model.referrals.value?.rows ?? [];
  }

  get data(): Referral[] {
    return this.model.referrals.value?.data.referrals ?? [];
  }

  get referral(): Referral {
    return this.model.referrals.value;
  }

  get allStats() {
    return [this.numberOfReferralsStats, this.numberOfUsersStats];
  }

  get numberOfReferralsStats(): StatBarItem {
    return {
      value: this.referral.numberOfReferrals,
      title: this.intl.t('reports.referrals'),
    };
  }

  get numberOfUsersStats(): StatBarItem {
    return {
      value: this.referral.numberOfUsers || 0,
      title: this.intl.t('reports.users'),
    };
  }

  get sortedRows() {
    if (this.sortDirection === 'desc') {
      return this.data.sortBy(this.sortProperty).reverse();
    } else {
      return this.data.sortBy(this.sortProperty);
    }
  }

  @action
  async handleExport() {
    const userIds = this.data.map((referral) => referral.userId);
    let jobIds = this.rows.map((referral) => referral.jobId);
    jobIds = uniq(jobIds.filter((jobId) => jobId !== null));

    const users = await this.store.query('user', {
      ids: userIds,
      groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
    });
    const jobs = await this.store.query('job', {
      ids: jobIds,
      groupAnalytics: this.analytics.hasEnabledGroupAnalytics,
    });

    jsonToCsvFile(
      'referrals',
      this.analytics.startDate,
      this.analytics.endDate,
      this.rows,
      (row: Referral) => {
        const user = users.findBy('id', row.userId);
        const job = jobs.findBy('id', row.jobId);
        const nameOrEmail = user ? get(user, 'nameOrEmail') : null;
        const jobTitle = row.jobId
          ? job?.title || this.intl.t('insights.common.deleted_job')
          : null;

        return {
          name_or_email: nameOrEmail,
          user_id: row.userId,
          job_id: row.jobId,
          job_title: jobTitle,
          candidate_id: row.candidateId,
        };
      }
    );
  }

  @action
  handleSort(attribute: string) {
    if (attribute === 'userId') return;

    if (this.sortProperty === attribute) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortProperty = attribute;
      this.sortDirection = 'desc';
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.overview.referrals': ReferralsOverviewController;
  }
}
