// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { get as emberGet } from '@ember/object';
import { UnwrapComputedPropertyGetter } from '@ember/object/-private/types';
import ObjectProxy from '@ember/object/proxy';
import { AsyncBelongsTo, AsyncHasMany, SyncHasMany } from '@ember-data/model';
import Raw from 'teamtailor/transforms/raw';

type ResolveObj<InputObj> =
  InputObj extends Raw<infer RawArg>
    ? RawArg
    : InputObj extends AsyncBelongsTo<infer ModelArg>
      ? ModelArg
      : InputObj extends
            | AsyncHasMany<infer ModelArg>
            | SyncHasMany<infer ModelArg>
        ? ModelArg[]
        : InputObj extends ObjectProxy
          ? InputObj['content']
          : InputObj extends PromiseLike<infer PromiseLikeArg>
            ? PromiseLikeArg
            : InputObj;

export function get<
  InputObj,
  ResolvedObj extends ResolveObj<InputObj>,
  Key extends keyof ResolvedObj,
>(obj: InputObj, key: Key): UnwrapComputedPropertyGetter<ResolvedObj[Key]>;
export function get<
  InputObj,
  ResolvedObj extends ResolveObj<InputObj>,
  Key extends keyof ResolvedObj,
>(
  obj: InputObj | null | undefined,
  key: Key
): UnwrapComputedPropertyGetter<ResolvedObj[Key]> | undefined;
export function get<InputObj, Key extends keyof InputObj>(
  obj: InputObj | null | undefined,
  key: Key
): UnwrapComputedPropertyGetter<InputObj[Key]> | undefined;

export function get<
  InputObj,
  ResolvedObj extends ResolveObj<InputObj>,
  Key extends keyof ResolvedObj,
>(obj: InputObj, key: Key) {
  if (obj === null || obj === undefined) return undefined;
  return emberGet(obj as ResolvedObj, key);
}
