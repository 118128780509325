import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"mb-8 flex flex-row items-center\">\n  <div class=\"flex-1\">\n    <Core::Form::Field\n      @as=\"core/input\"\n      @value={{this.model.reason}}\n      @model={{this.model}}\n      @modelField=\"reason\"\n      @placeholder={{t \"settings.custom_fields.option_placeholder\"}}\n    />\n  </div>\n  <div>\n    <Button\n      @tooltip={{t \"common.delete_thing\" thingName=(t \"things.archive_reason\")}}\n      @appearance=\"tertiary\"\n      @icon=\"trash-can\"\n      @onClick={{this.deleteArchiveReason}}\n      class=\"ml-8\"\n    />\n  </div>\n</div>", {"contents":"<div class=\"mb-8 flex flex-row items-center\">\n  <div class=\"flex-1\">\n    <Core::Form::Field\n      @as=\"core/input\"\n      @value={{this.model.reason}}\n      @model={{this.model}}\n      @modelField=\"reason\"\n      @placeholder={{t \"settings.custom_fields.option_placeholder\"}}\n    />\n  </div>\n  <div>\n    <Button\n      @tooltip={{t \"common.delete_thing\" thingName=(t \"things.archive_reason\")}}\n      @appearance=\"tertiary\"\n      @icon=\"trash-can\"\n      @onClick={{this.deleteArchiveReason}}\n      class=\"ml-8\"\n    />\n  </div>\n</div>","moduleName":"teamtailor/components/settings/requisition-archive-reason.hbs","parseOptions":{"srcName":"teamtailor/components/settings/requisition-archive-reason.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import TtAlertService from 'teamtailor/services/tt-alert';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';
import { RequisitionArchiveReasonModel } from 'teamtailor/models';

interface RequisitionArchiveReasonComponentArgs {
  model: RequisitionArchiveReasonModel;
}

export default class RequisitionArchiveReasonComponent extends Component<RequisitionArchiveReasonComponentArgs> {
  @service declare ttAlert: TtAlertService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  get model() {
    return this.args.model;
  }

  @action
  deleteArchiveReason() {
    this.ttAlert.customConfirm({
      title: this.intl.t(
        'components.settings.requisition_archive_reason.delete_requisition_archive_reason'
      ),

      text: this.intl.t(
        'components.settings.requisition_archive_reason.are_you_sure',
        {
          reason: get(this.model, 'reason'),
        }
      ),

      confirmButtonText: this.intl.t('common.delete'),
      confirmButtonClass: 'btn-danger',
      cancelButtonText: this.intl.t('common.no'),
      confirmCallback: async () => {
        try {
          await this.model.destroyRecord();
          this.flashMessages.success(
            this.intl.t(
              'components.settings.requisition_archive_reason.deleted_requisition_archive_reason'
            )
          );
        } catch {
          this.flashMessages.error(
            this.intl.t(
              'components.settings.requisition_archive_reason.cant_delete_if_used'
            )
          );
        }
      },

      cancelCallback: () => {},
    });
  }
}
