import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{ember-smooth/animate in=this.in}}>\n  {{#if @heading}}\n    <h2 class=\"heading-5 mb-16\">\n      <div class=\"-mt-8 flex h-40 items-center justify-between\">\n        {{@heading}}\n        {{#if (and @onClearFilters @hasFiltersSelected)}}\n          <Button\n            @appearance=\"tertiary\"\n            @onClick={{@onClearFilters}}\n            @text={{t \"components.search_filters.clear_all_filters\"}}\n          />\n        {{/if}}\n      </div>\n    </h2>\n  {{/if}}\n  <div class=\"flex flex-col gap-16\" ...attributes>\n    {{yield}}\n  </div>\n</div>", {"contents":"<div {{ember-smooth/animate in=this.in}}>\n  {{#if @heading}}\n    <h2 class=\"heading-5 mb-16\">\n      <div class=\"-mt-8 flex h-40 items-center justify-between\">\n        {{@heading}}\n        {{#if (and @onClearFilters @hasFiltersSelected)}}\n          <Button\n            @appearance=\"tertiary\"\n            @onClick={{@onClearFilters}}\n            @text={{t \"components.search_filters.clear_all_filters\"}}\n          />\n        {{/if}}\n      </div>\n    </h2>\n  {{/if}}\n  <div class=\"flex flex-col gap-16\" ...attributes>\n    {{yield}}\n  </div>\n</div>","moduleName":"teamtailor/components/sidebar/inner-wrapper.hbs","parseOptions":{"srcName":"teamtailor/components/sidebar/inner-wrapper.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { animate } from 'teamtailor/ember-smooth';

export default class SidebarInnerWrapperComponent extends Component {
  @action
  async in(element) {
    if (this.args.doFadeInOut) {
      await animate.from(
        element,
        {
          transform: 'translateY(5px)',
          opacity: 0,
        },
        400
      );
    }
  }
}
