import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { get, set, action } from '@ember/object';
import { timeout, restartableTask } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class EditController extends Controller {
  @service ttAlert;
  @service intercom;
  @service intl;
  @service translations;
  @service current;
  @service router;
  @service flipper;
  @service store;
  @service flashMessages;
  @service theme;

  get user() {
    return this.current.user;
  }

  get company() {
    return this.current.company;
  }

  get employee() {
    return this.model;
  }

  get isEditingSelf() {
    return this.user.id === this.employee.id;
  }

  @tracked
  clickedDeleteButton = false;

  @tracked
  errors = {};

  @tracked
  fullErrorMessages = null;

  @tracked
  newFunFact = '';

  deleteUserPromise() {
    let promise = this.employee.destroyRecord();
    promise.then(() => {
      get(this, 'flashMessages').success(
        this.intl.t('employees.edit.deleting_employe_could_take_moment')
      );
      this.router.transitionTo('employees', {
        queryParams: { query: '' },
      });
      this.closeUserDeleteModal();
    });
    return promise;
  }

  @action
  openUserDeleteModal() {
    this.clickedDeleteButton = true;
  }

  @action
  closeUserDeleteModal() {
    this.clickedDeleteButton = false;
  }

  _setFormInputError(valuePath) {
    const changeset = get(this, 'changeset');
    const errors = get(changeset, `error.${valuePath}`);
    set(this, `errors.${valuePath}`, errors);
  }

  _checkValidity = restartableTask(async (valuePath) => {
    await timeout(500);
    this._setFormInputError(valuePath);
  });

  @action
  async saveUser() {
    const { changeset } = this;

    if (
      isEmpty(get(changeset, 'email')) &&
      !isEmpty(get(changeset, 'inviteEmail'))
    ) {
      set(changeset, 'email', get(changeset, 'inviteEmail'));
    }

    await changeset.validate();
    if (get(changeset, 'isInvalid')) {
      throw new Error('Validation error');
    }

    try {
      const newLocale = get(this, 'changeset').change.locale;
      await changeset.save();
      this.theme.setup();
      set(this.employee, 'resendInvitation', false);

      if (newLocale !== undefined && this.isEditingSelf) {
        await this.translations.setLocale(newLocale || this.company.localeAts);
      }
    } catch (e) {
      get(this.employee, 'errors').forEach((error) => {
        let { attribute, message } = error;

        if (['inviteEmail', 'login.email'].includes(attribute)) {
          set(
            this,
            `errors.${attribute === 'inviteEmail' ? 'inviteEmail' : 'email'}`,
            [{ message }]
          );
          return;
        }

        changeset.pushErrors(attribute, message);
        this._setFormInputError(attribute);
        set(this, 'fullErrorMessages', get(changeset, 'fullErrorMessages'));
      });
      this.employee.rollbackAttributes();
      throw e;
    }
  }

  @action
  deleteUser() {
    get(this, 'ttAlert').confirm(
      this.intl.t('employees.edit.remove_employee_confirmation'),
      () => {
        set(this, 'employee.picture', null);
        if (get(this, 'employee.replacementUserId')) {
          let promise = get(this, 'employee').save();
          promise.then(() => {
            return this.deleteUserPromise();
          });
        } else {
          return this.deleteUserPromise();
        }

        return undefined;
      },
      () => {},
      {
        title: this.intl.t('employees.edit.delete_employee'),
        confirmButtonText: this.intl.t('employees.edit.yes_delete_employee'),
      }
    );
  }

  @action
  toggleDesktopNotifications() {
    if (get(this, 'notificationsActivated')) {
      return this.disableDesktopNotifications();
    }

    return this.enableDesktopNotifications();
  }

  @action
  enableDesktopNotifications() {
    if (Notification && Notification.permission !== 'granted') {
      Notification.requestPermission();
      return undefined;
    } else {
      set(this, 'notificationsActivated', true);
      return localStorage.setItem('notificationsEnabled', true);
    }
  }

  @action
  disableDesktopNotifications() {
    set(this, 'notificationsActivated', false);
    return localStorage.removeItem('notificationsEnabled');
  }

  @action
  async deleteUserOrOpenModal() {
    let jobs = await get(this, 'employee.jobs');
    let nurtureCampaignSteps = await get(this, 'employee.nurtureCampaignSteps');
    let hasUpcomingCalendarEvents =
      await this.employee.checkForUpcomingEvents();

    let requisitionApprover = await this.employee.checkForRequisitionApprover();

    let jobOfferApprover = await this.employee.checkForJobOfferApprover();

    let requisitionRecruiter = await get(this, 'employee.requisitionRecruiter');

    let requisitionOwner = await get(this, 'employee.requisitionOwner');

    if (
      jobs.length > 0 ||
      nurtureCampaignSteps.length > 0 ||
      hasUpcomingCalendarEvents ||
      requisitionApprover ||
      requisitionOwner.length > 0 ||
      requisitionRecruiter.length > 0 ||
      jobOfferApprover
    ) {
      this.openUserDeleteModal();
    } else {
      this.deleteUser();
    }
  }

  @action
  validateProperty(valuePath) {
    this._setFormInputError(valuePath);
  }

  @action
  checkValidity(valuePath) {
    this._checkValidity.perform(valuePath);
  }

  @action
  async removeFunFact(funFact) {
    await funFact.destroyRecord();
  }

  @action
  async createFunFact() {
    const funFact = await this.store.createRecord('userFunFact', {
      fact: this.newFunFact,
    });

    await funFact.save();

    set(this, 'newFunFact', '');
  }
}
