import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import Store from '@ember-data/store';
import IntlService from 'ember-intl/services/intl';
import FlashMessageService from 'teamtailor/services/flash-message';
import RouterService from '@ember/routing/router-service';
import { TableColumn } from 'teamtailor/components/table/container';
import { SlackAppModel } from 'teamtailor/models';

const SLACK_APPS_COLUMNS: TableColumn[] = [
  {
    label: 'settings.integrations.slack.channel',
  },
  {
    label: 'job.title',
  },
  {
    label: 'common.department',
  },
  {
    label: 'common.role',
  },
  {
    label: 'common.status',
  },
];

export default class SlackIntegrationController extends Controller {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;

  columns = SLACK_APPS_COLUMNS;

  @action
  onOauthSuccess({ authorization: data }: { authorization: SlackAppModel }) {
    this.store
      .createRecord('slack-app', {
        slackChannelId: data.slackChannelId,
        slackChannelName: decodeURIComponent(data.slackChannelName),
        slackConfigurationUrl: decodeURIComponent(data.slackConfigurationUrl),

        slackIncomingWebhook: decodeURIComponent(data.slackIncomingWebhook),

        slackTeamId: data.slackTeamId,
        slackTeamName: data.slackTeamName,
        slackUserId: data.slackUserId,
        token: data.token,
      })
      .save()
      .then((slackApp) => {
        this.router.transitionTo(
          'settings.integrations.slack_apps.edit',
          slackApp
        );
      });
  }

  @action
  onOauthFailure() {
    this.flashMessages.error(this.intl.t('errors.something_went_wrong'));
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.integrations.slack-apps.index': SlackIntegrationController;
  }
}
