import Service, { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import CookieConsentService from 'teamtailor/services/cookie-consent';

const LOCAL_STORAGE_KEY = 'theme';
const DEFAULT_THEME = 'system';

export default class ThemeService extends Service {
  @service private declare current: Current;
  @service private declare cookieConsent: CookieConsentService;

  get appearance() {
    let theme = '';

    if (get(this.current.user, 'id')) {
      theme = this.current.user.appearance;

      if (
        localStorage.getItem(LOCAL_STORAGE_KEY) !== theme &&
        this.cookieConsent.functional
      ) {
        localStorage.setItem(LOCAL_STORAGE_KEY, theme);
      }
    } else {
      theme = localStorage.getItem(LOCAL_STORAGE_KEY) || DEFAULT_THEME;
    }

    return theme.replace(/"/g, '');
  }

  setup() {
    document.body.classList.remove('theme-light', 'theme-dark');

    if (this.appearance === 'system') {
      const darkMode = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      return document.body.classList.add(
        darkMode ? 'theme-dark' : 'theme-light'
      );
    } else {
      return document.body.classList.add(`theme-${this.appearance}`);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    theme: ThemeService;
  }
}
