import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import SettingsLanguageController from 'teamtailor/controllers/settings/language';
import Current from 'teamtailor/services/current';
import Store from '@ember-data/store';

export default class LanguageIndexRoute extends ScrollToTopRoute {
  @service declare current: Current;
  @service declare store: Store;

  async model() {
    return this.store.findAll('locale');
  }

  deactivate() {
    this.current.company.rollbackAttributes();
  }

  resetController(controller: SettingsLanguageController, isExiting: boolean) {
    if (isExiting) {
      if (controller.isChangingDefaultCareerSite) {
        controller.isChangingDefaultCareerSite = false;
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (controller.userChannel) {
        // @ts-expect-error need to migrate SettingsLanguageController to typescript
        controller.userChannel.unbind('default-language-change-complete');
      }
    }
  }
}
