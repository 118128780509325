import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { query } from 'ember-data-resources';

import { TableColumn } from 'teamtailor/components/table/container';

export default class IndexController extends Controller {
  @tracked search = '';
  @tracked page = 1;

  @tracked sort_column = 'name';
  @tracked sort_direction = 'asc';

  queryParams = ['search', 'page', 'sort_direction'];

  get divisions() {
    return this.divisionsQuery.records;
  }

  get tableColumns() {
    const columns: TableColumn[] = [
      {
        label: 'common.division',
        sortable: true,
        sortColumn: 'name',
        defaultSortOrder: 'asc',
        sortOrder: 1,
      },
      {
        label: 'user.manager',
        sortable: false,
        sortOrder: 2,
      },
    ];

    return columns;
  }

  handleSearchInput = restartableTask(async (value) => {
    await timeout(1000);
    this.search = value;
    this.page = 1;
  });

  divisionsQuery = query(this, 'division', () => {
    return {
      name: this.search,
      sort_column: this.sort_column,
      sort_direction: this.sort_direction,
      no_no_division: true,
    };
  });
}

declare module '@ember/controller' {
  interface Registry {
    'settings.organization.divisions.index': IndexController;
  }
}
