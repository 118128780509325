import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import QRCodeStyling from 'qr-code-styling';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import EmployeesProfileSecurityActivate2faRoute from 'teamtailor/routes/employees/profile/security/activate-2fa';
import { get } from 'teamtailor/utils/get';
import { modifier } from 'ember-modifier';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';

export default class EmployeesProfileSecurityActivate2fa extends Controller {
  declare model: ModelFrom<EmployeesProfileSecurityActivate2faRoute>;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked activationError = false;

  get twoFactorActivated() {
    return get(get(this.model, 'login'), 'twoFactorActivated');
  }

  async createQrCode(element: HTMLElement) {
    const login = await get(this.model, 'login');

    new QRCodeStyling({
      width: 192,
      height: 192,
      type: 'svg',
      data: login?.twoFactorProvisioningUri,
      margin: 0,
    }).append(element);
  }

  applyQrCodeToCanvas = modifier((element: HTMLElement) => {
    this.createQrCode(element);
  });

  @action
  async handleCodeChange(
    code: string,
    resetCallback: (refocus?: boolean) => void
  ): Promise<void> {
    this.activationError = false;

    if (code.length === 6) {
      try {
        const login = await this.model.login;
        if (login) {
          login
            .activateTwoFactorAuthentication({
              totp: code,
            })
            .then(() => {
              resetCallback();
              this.flashMessages.success(
                this.intl.t('employees.security.two_fa_setup.enable_success')
              );
            });
        }
      } catch (err) {
        this.activationError = true;
        resetCallback(true);
      }
    }
  }

  @action
  handleClose() {
    this.router.transitionTo('employees.profile.security');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'employees.profile.security.activate-2fa': EmployeesProfileSecurityActivate2fa;
  }
}
