import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Current from 'teamtailor/services/current';
import FlashMessageService from 'teamtailor/services/flash-message';
import IntlService from 'ember-intl/services/intl';

export default class EmployeesProfileSecurityDisable2fa extends Controller {
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;

  @tracked code = '';
  @tracked declare resetCallback: (refocus?: boolean) => void;

  get canDisable2fa() {
    return this.code.length === 6;
  }

  @action
  async disable2fa() {
    try {
      const login = await this.current.user.login;
      if (login) {
        login.disableTwoFactorAuthentication({
          totp: this.code,
        });
        this.router.transitionTo('employees.profile.security').then(() => {
          this.resetCallback(false);
          this.flashMessages.success(
            this.intl.t(
              'employees.profile.security.two_fa_disable.disable_success'
            )
          );
        });
      }
    } catch (err) {
      this.flashMessages.error(this.intl.t('common.invalid_code'));
      this.resetCallback(true);
    }
  }

  @action
  handleOnEnter() {
    if (this.canDisable2fa) {
      this.disable2fa();
    }
  }

  @action
  handleCodeChange(code: string, resetCallback: (refocus?: boolean) => void) {
    this.code = code;
    this.resetCallback = resetCallback;
  }

  @action
  handleClose() {
    this.router.transitionTo('employees.profile.security');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'employees.profile.security.disable-2fa': EmployeesProfileSecurityDisable2fa;
  }
}
