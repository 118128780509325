import {
  iconBrands,
  iconDuoTone,
  iconLight,
  iconRegular,
  iconSolid,
} from 'teamtailor/constants/unicorn/icon';
import { Transition } from 'teamtailor/utils/type-utils';
import Route from '@ember/routing/route';
import UnicornController from 'teamtailor/controllers/unicorn';
import { next } from '@ember/runloop';

const users = (numberOfUsers: number) => {
  const users = [];
  for (let i = 0; i < numberOfUsers; i++) {
    users.push({ nameOrEmail: `User Name ${i}`, id: i + 1 }); // would be nice to have a faker lib here
  }

  return users;
};

const jobs = (job: number) => {
  const jobs = [];
  for (let i = 0; i < job; i++) {
    jobs.push({ title: `Job title ${i}`, location: `City ${i}`, id: i }); // would be nice to have a faker lib here
  }

  return jobs;
};

export default class UnicornRoute extends Route {
  model() {
    const usersArr = users(30);
    return {
      users: usersArr, // replace with this.current.company.users for testing "real" data
      user: usersArr[0],
      jobs: jobs(10),
      iconBrands,
      iconDuoTone,
      iconLight,
      iconRegular,
      iconSolid,
      errors: {
        users: [{ attribute: 'name', message: "User name can't be blank" }],
      },

      toggleSwitch: 'off',
    };
  }

  setupController(
    controller: UnicornController,
    model: unknown,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);

    const anchor = window.location.href.split('#')[1];
    const mainContainer = document.getElementById('main-container');
    if (anchor) {
      next(() => document.getElementById(anchor)?.scrollIntoView());
    } else if (mainContainer) {
      next(() => mainContainer.scrollTo(0, 0));
    }
  }
}

type RouteThisType = {
  route: (
    routeName: string,
    callbackOrObject?: Record<string, unknown> | ((this: RouteThisType) => void)
  ) => void;
};

export function unicornRoutes(this: RouteThisType) {
  this.route('index', { path: '/' });

  this.route('contributing');

  this.route('guidelines', function () {
    this.route('css');
    this.route('ember', function () {
      this.route('tests');
      this.route('relations');
      this.route('glimmer');
      this.route('routing');
      this.route('render-modifiers');
      this.route('controller-injections');
      this.route('migrate', function () {
        this.route('array-utils');
      });
    });
    this.route('rails');
    this.route('figma');
    this.route('delete');
    this.route('content', function () {
      this.route('voice');
      this.route('actionable-language');
      this.route('inclusive-language');
      this.route('web-elements');
    });
  });

  this.route('design-tokens', function () {
    this.route('colors', function () {
      this.route('decorative');
      this.route('functional');
      this.route('spectrum');
    });
    this.route('radius');
    this.route('effects');
    this.route('spacing');
    this.route('typography');
  });

  this.route('components', function () {
    this.route('alert-message');
    this.route('badge');
    this.route('buttons', function () {
      this.route('button');
      this.route('status-button');
      this.route('split-button');
      this.route('back-button');
    });
    this.route('checkbox');
    this.route('choice-card');
    this.route('color-dot');
    this.route('dropdown');
    this.route('empty-state');
    this.route('figure-picker');
    this.route('forms');
    this.route('icon');
    this.route('input');
    this.route('modal');
    this.route('radio-group');
    this.route('select');
    this.route('selectable-item');
    this.route('stat-bar');
    this.route('status-dot');
    this.route('table');
    this.route('tabs');
    this.route('tag');
    this.route('textarea');
    this.route('toggle-switch');
    this.route('tooltip');
    this.route('user-image-group');
    this.route('user-image');
    this.route('selected-count');
  });
}
