import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FigurePicker\n  @selectedIcon={{this.selectedIcon}}\n  @onSelect={{this.onSelectIcon}}\n  @style={{@style}}\n  @showColors={{@showColors}}\n  @onSelectColor={{(noop)}}\n>\n  <:trigger as |fp|>\n    <Button\n      class=\"mt-8\"\n      @appearance=\"secondary\"\n      @text={{@text}}\n      @onClick={{fp.trigger.onClick}}\n      {{did-insert fp.trigger.onInsert}}\n    />\n  </:trigger>\n</FigurePicker>", {"contents":"<FigurePicker\n  @selectedIcon={{this.selectedIcon}}\n  @onSelect={{this.onSelectIcon}}\n  @style={{@style}}\n  @showColors={{@showColors}}\n  @onSelectColor={{(noop)}}\n>\n  <:trigger as |fp|>\n    <Button\n      class=\"mt-8\"\n      @appearance=\"secondary\"\n      @text={{@text}}\n      @onClick={{fp.trigger.onClick}}\n      {{did-insert fp.trigger.onInsert}}\n    />\n  </:trigger>\n</FigurePicker>","moduleName":"teamtailor/components/unicorn/components/figure-picker.hbs","parseOptions":{"srcName":"teamtailor/components/unicorn/components/figure-picker.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Icon {
  iconName: string;
  prefix: string;
}

export default class UnicornFigurePicker extends Component {
  @tracked declare selectedIcon: string;

  @action
  onSelectIcon(icon: Icon) {
    this.selectedIcon = icon.iconName;
  }
}
