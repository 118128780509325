import { hasMany, attr, SyncHasMany } from '@ember-data/model';
import TriggerModel from 'teamtailor/models/trigger';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { wait } from 'teamtailor/ember-smooth';
import Current from 'teamtailor/services/current';
import PickedQuestionModel from 'teamtailor/models/picked-question';
import UploadModel from 'teamtailor/models/upload';
import { get } from 'teamtailor/utils/get';

export default class TriggerMessageModel extends TriggerModel<'message'> {
  @service declare current: Current;

  @hasMany('picked-question', { async: false })
  declare pickedQuestions: SyncHasMany<PickedQuestionModel>;

  @hasMany('upload', { async: false })
  declare uploads: SyncHasMany<UploadModel>;

  @attr('string') declare body: string;
  @attr('string') declare subject: string;
  @attr('boolean', { defaultValue: false }) declare fallbackSmsToEmail: boolean;
  @attr('boolean', { defaultValue: false }) declare isSms: boolean;

  get triggerContent() {
    return get(this, 'subject');
  }

  get questions() {
    return get(this, 'pickedQuestions').mapBy('question');
  }

  get sortedQuestions() {
    return get(this, 'pickedQuestions').sortBy('position');
  }

  get validEmail() {
    const subject = get(this, 'subject') || '';
    let body = get(this, 'body') || '<p></p>';
    body = body.replace(String.fromCharCode(8203), ''); // ZERO WIDTH SPACE from Redactor
    return subject.length > 0 && body !== '<p></p>';
  }

  get validSms() {
    if (
      !this.current.company.belongsTo('phoneNumber').id() ||
      isEmpty(get(this, 'body'))
    ) {
      return false;
    } else if (
      get(this, 'fallbackSmsToEmail') &&
      isEmpty(get(this, 'subject'))
    ) {
      return false;
    } else {
      return true;
    }
  }

  get valid() {
    return get(this, 'isSms') ? get(this, 'validSms') : get(this, 'validEmail');
  }

  get description() {
    if (get(this, 'isSms')) {
      return this.intl.t('models.trigger.message.description_sms', {
        body: get(this, 'body'),
      });
    } else {
      return this.intl.t('models.trigger.message.description_subject', {
        subject: get(this, 'subject'),
      });
    }
  }

  @action
  loadAsyncRelationships(timeout = 10000) {
    return Promise.race([
      this.current.company.hasMany('cannedResponses').load(),
      wait(timeout),
    ]);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'trigger/message': TriggerMessageModel;
  }
}
