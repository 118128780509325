import { themeColors } from '@teamtailor/design-tokens';

export const DECORATIVE_COLORS = Object.keys(themeColors.background.decorative);

export const ALLOWED_COLORS = [...DECORATIVE_COLORS, 'random'] as const;

function randomItem<T>(array: Array<T>): T {
  return array[Math.floor(Math.random() * array.length)] as T;
}

export function randomizeColor(options: string[], currentColor: string) {
  if (currentColor) {
    options = options.filter((color) => color !== currentColor);
  }

  return randomItem(options);
}
