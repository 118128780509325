import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';

export default class SettingsExternalRecruitersDeleteController extends Controller {
  @service declare router: RouterService;

  @tracked isOpen = true;

  get recruiterName() {
    return this.model.nameOrEmail;
  }

  initController() {
    this.isOpen = true;
  }

  @action
  handleDelete() {
    this.model.destroyRecord().then(() => {
      this.isOpen = false;
      this.router.transitionTo('settings.external-recruitment.recruiters');
    });
  }

  @action
  handleClose() {
    this.router.transitionTo('settings.external-recruitment.recruiters');
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.external-recruitment.recruiters.delete': SettingsExternalRecruitersDeleteController;
  }
}
