import { dasherize } from '@ember/string';
/* eslint-disable ember/use-ember-get-and-set */

import { runInDebug } from '@ember/debug';
import { scheduleOnce } from '@ember/runloop';
import { isNone } from '@ember/utils';
import {
  EmptyObj,
  Entries,
  OmitNullish,
  Prettify,
  RemoveIndexSignature,
  keys,
} from 'teamtailor/utils/type-utils';
import { headShake } from './animate.css/headShake';
import { bounce, bounceNoScale } from './animate.css/bounce';
import { tada } from './animate.css/tada';
import { shakeY } from './animate.css/shakeY';
import deepmerge from 'deepmerge';

const keyframePresets = {
  headShake,
  bounce,
  bounceNoScale,
  shakeY,
  tada,
} as const;

type KeyframePresets = keyof typeof keyframePresets;

const keyframePresetNames = keys(keyframePresets);

type Directions = 'top' | 'right' | 'bottom' | 'left' | 'block' | 'inline';

type Directional<Prefix extends string> = `${Prefix}${Capitalize<Directions>}`;

type IntKeys = Prettify<
  | 'opacity'
  | 'lineHeight'
  | 'zIndex'
  | 'offset'
  | 'flexBasis'
  | Directions
  | Directional<'margin' | 'padding'>
  | (typeof rectKeys)[number]
>;

type CSSStyleDeclarationSimple = RemoveIndexSignature<
  Omit<
    CSSStyleDeclaration,
    | 'getPropertyPriority'
    | 'getPropertyValue'
    | 'item'
    | 'removeProperty'
    | 'setProperty'
    | 'length'
    | 'parentRule'
    | 'prototype'
    | typeof Symbol.iterator
    | IntKeys
  >
>;

type StyleSimpleWithIndex = Partial<
  CSSStyleDeclarationSimple & { [key in IntKeys]?: string | number }
>;

export type StyleSimple = Prettify<
  RemoveIndexSignature<StyleSimpleWithIndex> & {
    [key in `--${string}`]: string;
  }
>;

const overflowKeys = ['overflowY', 'overflowX'] as const;

// setproperty requires hyphen-case and el.style[key] requires camelCase
function setPropertyOrRegular(
  el: HTMLElement,
  key: keyof CSSStyleDeclarationSimple,
  val: string
) {
  if (key.includes('-')) {
    el.style.setProperty(key, val);
  } else {
    el.style[key] = val;
  }
}

export function setStyles(elementsArg: ElementsArg, style: StyleSimple) {
  let elements: HTMLElement[] | HTMLStyleElement;
  if (
    typeof elementsArg === 'string' &&
    elementsArg.startsWith('::view-transition')
  ) {
    const styleEl = document.createElement('style');

    const styleString: string = Object.entries(pxifyIfNeeded(style)).reduce(
      (acc, [key, val]) => `${acc} ${dasherize(key)}: ${val};`,
      ''
    );

    styleEl.appendChild(
      document.createTextNode(`${elementsArg} {${styleString}}`)
    );
    document.body.appendChild(styleEl);

    elements = styleEl;
  } else {
    elements = normalizeElementsArray(elementsArg);

    elements.forEach((el) => {
      for (const [key, val] of Object.entries(
        pxifyIfNeeded(style)
      ) as Entries<CSSStyleDeclarationSimple>) {
        setPropertyOrRegular(el, key, val);
      }
    });
  }

  type UnsetStylesArg = {
    await?: Promise<unknown>[] | Promise<unknown>;
  };

  return async ({ await: awaitArray }: UnsetStylesArg = {}) => {
    if (awaitArray) {
      awaitArray = Array.isArray(awaitArray) ? awaitArray : [awaitArray];
      await Promise.all(awaitArray);
    }

    if (elements instanceof HTMLStyleElement) {
      elements.remove();
    } else {
      elements.forEach((el) => {
        (
          Object.entries(style) as unknown as [
            key: keyof CSSStyleDeclarationSimple,
          ][]
        ).forEach(([key]) => {
          setPropertyOrRegular(el, key, '');
        });
      });
    }
  };
}

export const easings = {
  // from https://www.npmjs.com/package/css-ease
  // Also, some of these are used in animate.css. For example ease-out-cubic here: https://github.com/rauchg/thereallybigone/blob/master/source-sass/_animate.scss#L69

  snap: 'cubic-bezier(0,1,.5,1)',
  linear: 'cubic-bezier(0.250, 0.250, 0.750, 0.750)',
  'ease-in-quad': 'cubic-bezier(0.550, 0.085, 0.680, 0.530)',
  'ease-in-cubic': 'cubic-bezier(0.550, 0.055, 0.675, 0.190)',
  'ease-in-quart': 'cubic-bezier(0.895, 0.030, 0.685, 0.220)',
  'ease-in-quint': 'cubic-bezier(0.755, 0.050, 0.855, 0.060)',
  'ease-in-sine': 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
  'ease-in-expo': 'cubic-bezier(0.950, 0.050, 0.795, 0.035)',
  'ease-in-circ': 'cubic-bezier(0.600, 0.040, 0.980, 0.335)',
  'ease-in-back': 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',
  'ease-out-quad': 'cubic-bezier(0.250, 0.460, 0.450, 0.940)',
  'ease-out-cubic': 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
  'ease-out-quart': 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
  'ease-out-quint': 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
  'ease-out-sine': 'cubic-bezier(0.390, 0.575, 0.565, 1.000)',
  'ease-out-expo': 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  'ease-out-circ': 'cubic-bezier(0.075, 0.820, 0.165, 1.000)',
  'ease-out-back': 'cubic-bezier(0.175, 0.885, 0.320, 1.275)',
  'ease-in-out-quad': 'cubic-bezier(0.455, 0.030, 0.515, 0.955)',
  'ease-in-out-cubic': 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
  'ease-in-out-quart': 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',
  'ease-in-out-quint': 'cubic-bezier(0.860, 0.000, 0.070, 1.000)',
  'ease-in-out-sine': 'cubic-bezier(0.445, 0.050, 0.550, 0.950)',
  'ease-in-out-expo': 'cubic-bezier(1.000, 0.000, 0.000, 1.000)',
  'ease-in-out-circ': 'cubic-bezier(0.785, 0.135, 0.150, 0.860)',
  'ease-in-out-back': 'cubic-bezier(0.680, -0.550, 0.265, 1.550)',
};

const dontPxIfy = ['opacity', 'lineHeight', 'zIndex', 'offset'];

const pxifyIfNeededKeyVal = (key: string, value?: string | number | null) =>
  typeof value === 'number' && !dontPxIfy.includes(key)
    ? `${value}px`
    : !isNone(value)
      ? `${value}`
      : '';

function pxifyIfNeeded(style: StyleSimple): StyleSimple;
function pxifyIfNeeded(key: string, value?: string | number | null): string;
function pxifyIfNeeded<KeyOrStyle extends StyleSimple | string>(
  keyOrstyle: KeyOrStyle,
  value?: string | number | null
): StyleSimple | string {
  if (typeof keyOrstyle === 'object') {
    const out: StyleSimple = {};
    for (const [key, value] of Object.entries(
      keyOrstyle
    ) as Entries<StyleSimple>) {
      out[key] = pxifyIfNeededKeyVal(key, value);
    }

    return out;
  } else {
    return pxifyIfNeededKeyVal(keyOrstyle, value);
  }
}

type ProcessKFArgs = {
  element: HTMLElement;
  keyframesArg: KeyframesArg;
  options: Options;
};

export const processKeyframes = ({
  element,
  keyframesArg,
  options,
}: ProcessKFArgs) => {
  const willChange = new Set<string>();

  let setsHeight: boolean | undefined, setsWidth: boolean | undefined;

  let keyframes: KeyframesArgOrPreset = [keyframesArg[0]];
  keyframes = keyframesArg.map((keyframeArg) => {
    const keyframe: KeyframeSimple =
      keyframeArg instanceof DOMRect
        ? keyframeArg.toJSON()
        : { ...keyframeArg };

    const parsedKeyframeWidth =
      'width' in keyframe ? parseInt(keyframe.width as string) : undefined;

    setsWidth =
      parsedKeyframeWidth !== undefined && !Number.isNaN(parsedKeyframeWidth);
    const setsWidth0 = setsWidth && parsedKeyframeWidth === 0;

    if (setsWidth) {
      const currentInitial: StyleSimple = element.dataset.animateInitial
        ? (JSON.parse(element.dataset.animateInitial) as StyleSimple)
        : {};

      if (
        options.auto.overflowXClip === 'setsWidth' &&
        !('overflowX' in currentInitial) &&
        element !== document.documentElement
      ) {
        currentInitial.overflowX = element.style.overflowX;
        element.dataset.animateInitial = JSON.stringify(currentInitial);
        element.style.overflowX = 'clip';
      }
    }

    if (setsWidth0) {
      if (
        options.auto.marginInline === 'setsWidth0' &&
        !('marginInline' in keyframe)
      ) {
        const marginInlineKFVal =
          parseInt(keyframe.marginInline as string) || 0;
        keyframe.marginInline = 0 + marginInlineKFVal;
      }
    }

    const parsedKeyframeHeight =
      'height' in keyframe ? parseInt(keyframe.height as string) : undefined;

    setsHeight =
      parsedKeyframeHeight !== undefined && !Number.isNaN(parsedKeyframeHeight);
    const setsHeight0 = setsHeight && parsedKeyframeHeight === 0;

    if (setsHeight) {
      const currentInitial: StyleSimple = element.dataset.animateInitial
        ? (JSON.parse(element.dataset.animateInitial) as StyleSimple)
        : {};

      if (
        options.auto.overflowYClip === 'setsHeight' &&
        !('overflowY' in currentInitial) &&
        element !== document.documentElement
      ) {
        currentInitial.overflowY = element.style.overflowY;
        element.dataset.animateInitial = JSON.stringify(currentInitial);
        element.style.overflowY = 'clip';
      }

      if (
        options.auto.paddingBlock === 'setsHeight' &&
        !('paddingBlock' in keyframe)
      ) {
        keyframe.paddingBlock = 0;
      }
    }

    if (setsHeight0) {
      if (
        options.auto.marginBlock === 'setsHeight0' &&
        !('marginBlock' in keyframe)
      ) {
        const marginBlockKFVal = parseInt(keyframe.marginBlock as string) || 0;
        keyframe.marginBlock = 0 + marginBlockKFVal;
      }

      const gapAutoProp = 'marginTop';
      const reverseGapAutoProp = 'marginBottom';
      if (options.auto.parentGap === 'setsHeight0') {
        let gapMatch = element.parentElement
          ?.getAttribute('class')
          ?.match(/( |^)gap-\[?-?(\d+)(px)?\]?( |$)/)?.[2];

        if (!gapMatch) {
          gapMatch = getComputedStyle(element.parentElement!).gap;
        }

        if (gapMatch) {
          const gap = parseInt(gapMatch);
          const gapKFVal = parseInt(keyframe[gapAutoProp] as string) || 0;
          const reverseGapKFVal =
            parseInt(keyframe[reverseGapAutoProp] as string) || 0;

          const newVal = (gapKFVal || 0) - gap;

          keyframe[gapAutoProp] = newVal;

          keyframe[reverseGapAutoProp] = 0 + reverseGapKFVal;
        }
      }
    }

    return (Object.entries(keyframe) as Entries<KeyframeSimple>).reduce(
      (acc: KeyframeSimple, [key, value]) => {
        willChange.add(key);

        acc[key] = pxifyIfNeeded(key, value);
        return acc;
      },
      {}
    );
  }) as KeyframesArg;

  return {
    keyframes,
    willChange,
  };
};

const rectKeys = ['height', 'width'] as const;

export type KeyframeSimple = (Keyframe | ComputedKeyframe) & StyleSimple;

type ElemData = {
  animation: Animation;
  keyframes: KeyframeSimple[];
  options: Options;
  firstKeysNotInLast: (string | number)[];
  willChange: Set<keyof StyleSimple>;
};

const elemsDatas = new WeakMap<HTMLElement, ElemData[]>();

type ElementsArg =
  | undefined
  | null
  | (HTMLElement | undefined | null)[]
  | HTMLElement
  | string
  | HTMLCollectionOf<HTMLElement>;

const isHTMLElementCollection = (
  input: unknown
): input is HTMLCollectionOf<HTMLElement> =>
  input instanceof HTMLCollection &&
  Array.from(input).every((el) => el instanceof HTMLElement);

export const normalizeElementsArray = (
  elements: ElementsArg
): HTMLElement[] => {
  return Array.isArray(elements)
    ? elements.compact()
    : isHTMLElementCollection(elements)
      ? Array.from(elements)
      : typeof elements === 'string'
        ? Array.from(document.querySelectorAll(elements))
        : [elements].compact();
};

const isInteger = (a: unknown): a is number => Number.isInteger(a);

type AllAutoOptions = {
  rectKeys: boolean;
  parentGap: 'setsHeight0' | false;
  paddingBlock: 'setsHeight' | false;
  marginBlock: 'setsHeight0' | false;
  marginInline: 'setsWidth0' | false;
  overflowYClip: 'setsHeight' | false;
  overflowXClip: 'setsWidth' | false;
  willChange: '!documentElement' | (string & EmptyObj) | false;
};

type Options = KeyframeAnimationOptions & {
  setStylesAfter: boolean;
  style?: StyleSimple;
  auto: Partial<AllAutoOptions>;
  debug?: true | 'console';
};

type OptionsArg = Partial<Options> & {
  auto?: Options['auto'] | false;
};

export type KeyframesArg = [KeyframeSimple, ...KeyframeSimple[]];
export type KeyframesArgOrPreset = KeyframesArg | KeyframePresets;

type BeforeAfterOptions = {
  awaitRaf?: boolean;
};

const propMap = {
  height: 'offsetHeight',
  width: 'offsetWidth',
} as const;

export const beforeAfter = async (
  el: HTMLElement,
  propArg: 'height' | 'width',
  updateDomCb: () => unknown,
  { awaitRaf = true }: BeforeAfterOptions = {}
) => {
  const prop = propMap[propArg];
  const before = el[prop];

  await updateDomCb();

  if (awaitRaf) {
    await raf();
  }

  const after = el[prop];

  return { before, after };
};

export const animate = async (
  elements: ElementsArg,
  keyframesArg: KeyframesArgOrPreset,
  optionsArg?: number | OptionsArg
) => {
  const elementsArray = normalizeElementsArray(elements);

  if (!elementsArray[0]) return Promise.resolve();

  const autoDefaults: AllAutoOptions = {
    rectKeys: true,
    parentGap: 'setsHeight0',
    paddingBlock: 'setsHeight',
    marginBlock: 'setsHeight0',
    marginInline: 'setsWidth0',
    overflowYClip: 'setsHeight',
    overflowXClip: 'setsWidth',
    willChange: '!documentElement',
  };

  let autoOptions = autoDefaults;

  if (typeof optionsArg === 'object') {
    if (optionsArg.auto === false) {
      autoOptions = Object.fromEntries(
        keys(autoDefaults).map((k) => [k, false])
      ) as AllAutoOptions;
    } else if (typeof optionsArg.auto === 'object') {
      autoOptions = Object.assign(autoDefaults, optionsArg.auto);
    }
  }

  const out = await Promise.all(
    elementsArray.map(async (element) => {
      const optionsArgObjNormalized: OptionsArg = removeUndefinedVals(
        isInteger(optionsArg)
          ? { duration: optionsArg }
          : !isNone(optionsArg)
            ? optionsArg
            : {}
      );

      const defaultOptions = {
        setStylesAfter: !optionsArgObjNormalized.pseudoElement,
        easing: 'ease-out-cubic',
        duration: 250,
        auto: autoOptions,
      } as Options & {
        easing: keyof typeof easings;
      };

      if (
        typeof keyframesArg === 'string' &&
        keyframePresetNames.includes(keyframesArg)
      ) {
        const preset = keyframePresets[keyframesArg];

        keyframesArg = preset.keyframes as KeyframesArg;

        Object.assign(defaultOptions, preset.options);
      }

      const options: Options = deepmerge(
        defaultOptions,
        optionsArgObjNormalized
      );

      if (options.easing && options.easing in easings) {
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/43284
        options.easing = easings[options.easing];
      }

      const { keyframes, willChange } = processKeyframes({
        element,
        keyframesArg: keyframesArg as KeyframesArg,
        options,
      });

      const firstKeys = keys(keyframes[0]);
      const lastKeyframe = keyframes.length > 1 ? keyframes.at(-1) : null;
      const lastKeys = lastKeyframe ? keys(lastKeyframe) : null;

      let firstKeysNotInLast = firstKeys;

      if (lastKeys) {
        firstKeysNotInLast = firstKeys.filter(
          (firstKey) => !lastKeys.includes(firstKey)
        );
      }

      options.fill =
        options.fill ||
        (lastKeys && firstKeysNotInLast.length === lastKeys.length
          ? 'none'
          : 'both');

      const setWillChange =
        'union' in Set.prototype &&
        !(
          !options.auto.willChange ||
          (options.auto.willChange === '!documentElement' &&
            element === document.documentElement)
        );

      const elemDatas = elemsDatas.get(element) || [];
      const elemdata = { willChange } as ElemData;

      const origWillChange = element.style.willChange;
      const willChangeVarKey = '--aniMateWillChange';
      const willChangeVarVal = `var(${willChangeVarKey})`;
      if (setWillChange && willChange.size > 0) {
        const currentWillChange = elemDatas.reduce((acc, curr) => {
          // @ts-expect-error remove this when set methods are typed in tracked-built-ins package
          return acc.union(curr.willChange);
        }, new Set<keyof StyleSimple>());

        element.style.setProperty(
          willChangeVarKey,
          // @ts-expect-error remove this when set methods are typed in tracked-built-ins package
          Array.from(willChange.union(currentWillChange)).join(', ')
        );
        element.style.willChange = willChangeVarVal;
      }

      if (firstKeysNotInLast.length > 0 && lastKeyframe) {
        if (options.auto.rectKeys) {
          rectKeys.forEach((key) => {
            if (!(key in lastKeyframe) && key in keyframes[0]) {
              const rect = element.getBoundingClientRect();
              lastKeyframe[key] = pxifyIfNeeded(key, rect[key]);
            }
          });
        }

        if (!('opacity' in lastKeyframe) && 'opacity' in keyframes[0]) {
          lastKeyframe.opacity = parseFloat(keyframes[0].opacity as string)
            ? 0
            : 1;
        }
      }

      if (options.auto.rectKeys) {
        rectKeys.forEach((key) => {
          if (!(key in keyframes[0]) && lastKeyframe?.[key]) {
            const rect = element.getBoundingClientRect();
            keyframes[0][key] = pxifyIfNeeded(key, rect[key]);
          }
        });
      }

      runInDebug(() => {
        if (options.debug === 'console') {
          // eslint-disable-next-line no-console
          console.error({ element, keyframes, options });
        } else if (options.debug) {
          // eslint-disable-next-line no-debugger
          debugger;
        }
      });

      let unsetStyles: ReturnType<typeof setStyles> | undefined;
      if (options.style) {
        unsetStyles = setStyles(element, options.style);
      }

      const animation = element.animate(keyframes, options);

      Object.assign(elemdata, {
        animation,
        keyframes,
        firstKeysNotInLast,
        options,
      });

      elemDatas.push(elemdata);
      elemsDatas.set(element, elemDatas);

      await animation.finished;

      unsetStyles?.();

      // dont do post animation stuff (reset styles etc.) before all animations are finished
      const allElemAnimationsFinished = !elemDatas.find(
        ({ animation }) => animation.playState !== 'finished'
      );

      if (
        allElemAnimationsFinished &&
        (options.auto.overflowYClip || options.auto.overflowXClip)
      ) {
        const currentInitial: StyleSimple = element.dataset.animateInitial
          ? (JSON.parse(element.dataset.animateInitial) as StyleSimple)
          : {};

        overflowKeys.forEach((key) => {
          if (typeof currentInitial[key] === 'string') {
            element.style[key] = currentInitial[key]!;
          }
        });

        if (typeof currentInitial.overflowClipMargin === 'string') {
          element.style.overflowClipMargin = currentInitial.overflowClipMargin;
        }

        delete element.dataset.animateInitial;
      }

      if (
        ['both', 'forwards'].includes(options.fill) &&
        allElemAnimationsFinished /* &&
        element.isConnected */
      ) {
        setStylesAfter(element, elemDatas);
      }

      if (setWillChange && willChange.size > 0) {
        element.style.willChange = origWillChange.replace(willChangeVarVal, '');
        element.style.removeProperty(willChangeVarKey);
      }

      elemsDatas.delete(element);

      return animation;
    })
  );

  return out;
};

animate.from = (
  elements: ElementsArg,
  keyframesArg: KeyframeSimple,
  optionsArg?: number | OptionsArg
) => {
  return animate(elements, [keyframesArg, {}], optionsArg);
};

animate.to = (
  elements: ElementsArg,
  keyframesArg: KeyframeSimple,
  optionsArg?: number | OptionsArg
) => {
  return animate(elements, [{}, keyframesArg], optionsArg);
};

const setStylesAfter = (element: HTMLElement, elemDatas: ElemData[]) => {
  // this block is because we cant use `commitStyles`.. see: https://github.com/w3c/csswg-drafts/issues/5394
  // With this it wont set styles after animation unless it overrides styles it had before animation
  elemDatas.forEach(({ keyframes, animation, options, firstKeysNotInLast }) => {
    const lastKeyframe = keyframes.at(-1);
    if (lastKeyframe && options.setStylesAfter && !options.pseudoElement) {
      (
        Object.entries(lastKeyframe) as Entries<CSSStyleDeclarationSimple>
      ).forEach(([key, value]) => {
        if (!firstKeysNotInLast.includes(key)) {
          setPropertyOrRegular(element, key, value);
        }
      });
    }

    if (!options.pseudoElement) {
      animation.cancel();
    }
  });
};

const afterRenderCb = (timesLeftArg: number, resolve: () => void) => {
  const timesLeft = timesLeftArg - 1;

  if (timesLeft === 0) {
    resolve();
  } else {
    scheduleOnce('afterRender', null, afterRenderCb, timesLeft, resolve);
  }
};

export function afterRender(times = 1) {
  return new Promise((resolve) => {
    scheduleOnce('afterRender', null, afterRenderCb, times, resolve);
  });
}

const rafCb = (timesLeftArg: number, resolve: () => void) => {
  const timesLeft = timesLeftArg - 1;

  if (timesLeft === 0) {
    resolve();
  } else {
    requestAnimationFrame(() => rafCb(timesLeft, resolve));
  }
};

export function raf(times = 1) {
  return new Promise<void>((resolve) => {
    requestAnimationFrame(() => rafCb(times, resolve));
  });
}

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export { AnimateArray } from './animate-array';

const removeUndefinedVals = <T extends Record<string, unknown>>(object: T) => {
  return Object.fromEntries(
    Object.entries(object).filter(([, val]) => typeof val !== 'undefined')
  ) as OmitNullish<T>;
};
