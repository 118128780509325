import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#ember-smooth/if this.hasUnhandledProblem}}\n  {{yield}}\n{{/ember-smooth/if}}", {"contents":"{{#ember-smooth/if this.hasUnhandledProblem}}\n  {{yield}}\n{{/ember-smooth/if}}","moduleName":"teamtailor/components/meeting/if-unhandled-problem.hbs","parseOptions":{"srcName":"teamtailor/components/meeting/if-unhandled-problem.hbs"}});
import Component from '@glimmer/component';

import {
  IGraphQLErrorProblem,
  getStringErrorPath,
} from 'teamtailor/utils/meetings/error-handling';

interface IMeetingArgs {
  allProblems: IGraphQLErrorProblem[];
  errorMessages: Error[];
}

const HANDLED_PATHS = [
  'prepared_message',
  'meeting_events[].summary',
  'meeting_events[].users',
  'meeting_events[].booked_slot',
  'meeting_events[].booked_slot_starts_at',
  'meeting_events[].booked_slot_ends_at',
  'meeting_events[].starts_at',
  'meeting_events[].ends_at',
  'meeting_events[].self_schedule',
];

export default class MeetingIfUnhandledProblem extends Component<IMeetingArgs> {
  get hasUnhandledProblem(): boolean {
    if (!this.args.errorMessages.length) {
      return false;
    }

    if (this.args.errorMessages.length && !this.args.allProblems.length) {
      return true;
    }

    const foundProblem = this.args.allProblems.some((problem) =>
      HANDLED_PATHS.includes(getStringErrorPath(problem.path, true))
    );

    return !foundProblem;
  }
}
