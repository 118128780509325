import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"mb-8 flex flex-row items-center\">\n  <div class=\"flex-1\">\n    <Core::Form::Field\n      @as=\"core/input\"\n      @model={{this.translatableData}}\n      @modelField=\"name\"\n      @size=\"large\"\n      @onInput={{this.handleRoleNameChange}}\n      placeholder={{this.role.name}}\n    />\n  </div>\n  <div>\n    <Button\n      @appearance=\"secondary\"\n      @icon=\"trash-can\"\n      @ariaLabel={{t \"settings.roles.delete_role\"}}\n      @onClick={{@onDeleteRole}}\n      @isDisabled={{@tabLanguage}}\n      class=\"ml-8\"\n      @size=\"large\"\n    />\n  </div>\n</div>", {"contents":"<div class=\"mb-8 flex flex-row items-center\">\n  <div class=\"flex-1\">\n    <Core::Form::Field\n      @as=\"core/input\"\n      @model={{this.translatableData}}\n      @modelField=\"name\"\n      @size=\"large\"\n      @onInput={{this.handleRoleNameChange}}\n      placeholder={{this.role.name}}\n    />\n  </div>\n  <div>\n    <Button\n      @appearance=\"secondary\"\n      @icon=\"trash-can\"\n      @ariaLabel={{t \"settings.roles.delete_role\"}}\n      @onClick={{@onDeleteRole}}\n      @isDisabled={{@tabLanguage}}\n      class=\"ml-8\"\n      @size=\"large\"\n    />\n  </div>\n</div>","moduleName":"teamtailor/components/departments/role-row.hbs","parseOptions":{"srcName":"teamtailor/components/departments/role-row.hbs"}});
import { RoleModel } from 'teamtailor/models';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import TranslatableFormBaseComponent from '../translatable-form/base';
import { action } from '@ember/object';

const REQUIRED_PROPS = ['name'];

export default class RoleRow extends TranslatableFormBaseComponent<RoleModel> {
  @service declare store: Store;
  translationsProps = REQUIRED_PROPS;

  get role() {
    return this.args.translatableModel;
  }

  @action
  handleRoleNameChange(event: Event) {
    if (this.translatableData) return;

    const target = event.target as HTMLInputElement;

    if (this.args.tabLanguage) {
      this.handleTranslation(this.args.tabLanguage, { name: target.value });
    }
  }
}
