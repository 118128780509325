import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Tabs::Button\n  @isActive={{eq @index @selectedTabIndex}}\n  @isGrowable={{@isGrowable}}\n  @onClick={{fn @handleTab @index @tab.languageCode}}\n  @text={{t (concat \"language_codes.\" @tab.languageCode)}}\n>\n  {{#if this.isMissingLanguage}}\n    <StatusDot @status=\"draft\" @size=\"medium\" />\n  {{/if}}\n</Tabs::Button>", {"contents":"<Tabs::Button\n  @isActive={{eq @index @selectedTabIndex}}\n  @isGrowable={{@isGrowable}}\n  @onClick={{fn @handleTab @index @tab.languageCode}}\n  @text={{t (concat \"language_codes.\" @tab.languageCode)}}\n>\n  {{#if this.isMissingLanguage}}\n    <StatusDot @status=\"draft\" @size=\"medium\" />\n  {{/if}}\n</Tabs::Button>","moduleName":"teamtailor/components/translation/tab-button.hbs","parseOptions":{"srcName":"teamtailor/components/translation/tab-button.hbs"}});
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { get } from 'teamtailor/utils/get';

export default class TranslationTabButtonComponent extends Component {
  get isMissingLanguage() {
    const { requiredProps, isTranslationTab, isCareerSiteTab, tab } = this.args;

    if (requiredProps) {
      if (isCareerSiteTab) {
        const isMissingRequiredProps = requiredProps.some((requiredProp) =>
          isEmpty(tab[requiredProp])
        );
        return isMissingRequiredProps;
      }

      if (
        isTranslationTab &&
        get(this.args.translations, 'isFulfilled') &&
        !get(this.args.translations, 'isDestroying')
      ) {
        const translation = this.args.translations.find(
          (t) => get(t, 'languageCode') === tab.languageCode
        );

        if (translation) {
          const isMissingRequiredProps = requiredProps.some((requiredProp) =>
            isEmpty(translation[requiredProp])
          );
          return isMissingRequiredProps;
        }

        return true;
      }
    }

    return false;
  }
}
