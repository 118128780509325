import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import RecruitingFirmsController from 'teamtailor/controllers/settings/external-recruitment/recruiting-firms';
import { Transition } from 'teamtailor/utils/type-utils';

export default class SettingsExternalRecruitmentRecruitingFirmsDeleteRoute extends Route {
  @service declare store: Store;

  model(params: { recruiting_firm_id: string }) {
    return this.store.findRecord('recruiting-firm', params.recruiting_firm_id);
  }

  setupController(
    controller: RecruitingFirmsController,
    model: unknown,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    controller.initController();
  }
}
