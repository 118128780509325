import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class SettingsIntegrationsPartnerActivationActivateController extends Controller {
  @service ttAlert;
  @service intl;
  @service router;
  @service flashMessages;
  @service timeFormat;

  @tracked validForm;
  @tracked validationErrors;
  @tracked isLoading;

  get partnerActivation() {
    return this.model.partnerActivation;
  }

  get fields() {
    return this.model.fields;
  }

  get oauthHasForm() {
    return this.model.oauthHasForm;
  }

  get partnerApiKeyScope() {
    return this.model.partnerActivation.partner?.apiKeyScope;
  }

  providerKeyData = {};
  get isApiKeyRequired() {
    return !(this.partnerApiKeyScope === 'no_key') || !this.partnerApiKeyScope;
  }

  get showApiKeyField() {
    const empty = this.fields.length === 0;
    const errorConfig = this.fields.isEvery('type', 'error') && !empty;

    return this.isApiKeyRequired && !errorConfig;
  }

  get disabledSubmit() {
    if (
      this.model.hasValidationEndpoint &&
      !this.model.partnerActivation.credentialsValidatedAt
    )
      return true;
    return !this.validForm;
  }

  get buttonText() {
    const text = this.partnerActivation.installed ? 'update' : 'install';
    return this.intl.t(`settings.integrations.partner_activations.${text}`);
  }

  @action
  onValueChanged(key, value) {
    this.providerKeyData[key] = value;
    this.validForm = this.fields.isEvery('isValid');
  }

  @action
  async save() {
    this.partnerActivation.providerKey = this.providerKeyData;
    try {
      await this.partnerActivation.save();
      this.flashMessages.success(
        this.intl.t('settings.integrations.partner_activations.key_updated')
      );
      this.router.transitionTo('settings.integrations.marketplace-activations');
    } catch ({ errors }) {
      errors.forEach((error) => {
        this.flashMessages.error(error?.detail);
      });
    }
  }

  @action
  async validateEndpoint() {
    this.isLoading = true;
    this.partnerActivation.providerKey = this.providerKeyData;
    await this.partnerActivation.save();

    try {
      await this.partnerActivation.validateProviderKey(
        this.model.validateEndpoint
      );
      await this.partnerActivation.reload();
      this.validationErrors = null;
      return (this.isLoading = false);
    } catch (error) {
      const { errors } = error;
      await this.partnerActivation.reload();
      this.isLoading = false;

      const messages =
        Array.isArray(errors) &&
        errors.filter((error) => error && typeof error === 'string');
      return (this.validationErrors = messages.length
        ? messages
        : ['Unknown error']);
    }
  }

  @action
  onSelectApiKey(key) {
    this.partnerActivation.apiKey = key;
  }

  @action
  clearProviderKeyData() {
    this.providerKey = {};
  }

  @action
  delete() {
    this.ttAlert.customConfirm({
      title: this.intl.t('common.are_you_sure'),
      text: this.intl.t(
        'settings.integrations.partner_activations.deactivate_alert.text'
      ),

      confirmButtonText: this.intl.t(
        'settings.integrations.partner_activations.deactivate_alert.confirm'
      ),

      cancelButtonText: this.intl.t('common.cancel'),
      confirmCallback: async () => {
        await this.partnerActivation.destroyRecord();
        this.flashMessages.success(
          this.intl.t(
            'settings.integrations.partner_activations.deactivate_alert.partner_deleted'
          )
        );
        this.router.transitionTo(
          'settings.integrations.marketplace-activations'
        );
      },

      cancelCallback: () => {},
    });
  }

  @action
  closeModal() {
    this.router.transitionTo('settings.integrations.marketplace-activations');
  }
}
