import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import customMessage from 'teamtailor/utils/custom-message';
import { get } from 'teamtailor/utils/get';

export default class JobDetailModel extends Model {
  @service current;

  @belongsTo('job', { async: false }) job;
  @belongsTo('team', { async: false }) team;
  @belongsTo('survey') form;
  @belongsTo('job') fromTemplate;
  @belongsTo('message-widget', { async: false }) messageWidget;
  @belongsTo('image-with-setting', { async: false }) imageWithSetting;
  @belongsTo('canned-response') rejectMessage;
  @belongsTo('canned-response') replyMessage;
  @belongsTo('canned-response') externalRecruiterInvite;
  @belongsTo('user') templateContact;
  @belongsTo('suggest-existing-candidates-job-run', { async: false })
  suggestExistingCandidatesJobRun;

  @hasMany('user') teamMembers;
  @hasMany('trigger') triggers;
  @hasMany('scorecard-pick', { async: false }) scorecardPicks;
  @hasMany('stage', { async: false }) stages;
  @hasMany('user') colleagues;
  @hasMany('recruiting-firm') recruitingFirms;
  @hasMany('picked-interview-kit', { async: false }) pickedInterviewKits;
  @hasMany('picked-question', { async: false }) pickedQuestions;
  @hasMany('partner') partners;
  @hasMany('picked-custom-field', {
    async: false,
  })
  pickedCustomFields;

  @hasMany('candidate-suggestion', { async: true }) candidateSuggestions;

  @attr('string', { defaultValue: 'phone_optional' }) phoneRequirement;
  @attr('string', { defaultValue: 'resume_optional' }) resumeRequirement;
  @attr('string', { defaultValue: 'additional_files_optional' })
  additionalFilesRequirement;

  @attr('string', { defaultValue: 'name_optional' }) nameRequirement;
  @attr('boolean', { defaultValue: false }) tagged;
  @attr('string') replyTime;
  @attr('raw') responseTimeOptions;
  @attr('string') applyButtonText;
  @attr('boolean') approved;
  @attr('string') body;
  @attr('boolean') competenceBased;
  @attr('string') externalApplicationUrl;
  @attr('string') languageCode;
  @attr('date') lastSavedAt;
  @attr('string') pitch;
  @attr('string') rejectBody;
  @attr('string') rejectSubject;
  @attr('string') replyBody;
  @attr('string') replySubject;
  @attr('string') externalRecruiterInviteBody;
  @attr('string') externalRecruiterInviteSubject;
  @attr('string') templateInstructions;
  @attr('string') templateName;
  @attr('number') unreadApplications;
  @attr('number') activeSourcedJobApplicationsCount;
  @attr('date') jobStartedAt;
  @attr('string', {
    defaultValue: 'cover_letter_optional',
  })
  coverLetterRequirement;

  @attr('number') daysToFill;
  @attr('number') daysPublished;

  get pickedImage() {
    return this.imageWithSetting.pickedImage;
  }

  get questions() {
    return this.pickedQuestions.mapBy('question');
  }

  get nonQualifyingPickedQuestions() {
    return this.pickedQuestions.filterBy('isQualifying', false);
  }

  get qualifyingPickedQuestions() {
    return this.pickedQuestions.filterBy('isQualifying', true);
  }

  get visibleCandidateSuggestions() {
    return this.candidateSuggestions.filterBy('visible', true);
  }

  @customMessage('reply') customReplyMessage;
  @customMessage('reject') customRejectMessage;
  @customMessage('externalRecruiterInvite')
  customExternalRecruiterInviteMessage;

  get replyMessageWithFallback() {
    if (this.belongsTo('replyMessage').id()) {
      return get(this, 'replyMessage');
    } else {
      return get(this, 'current.company.defaultReplyMessage');
    }
  }

  get rejectMessageWithFallback() {
    if (this.belongsTo('rejectMessage').id()) {
      return get(this, 'rejectMessage');
    } else {
      return get(this, 'current.company.defaultRejectMessage');
    }
  }

  get _scorecardScores() {
    return this.scorecardPicks.mapBy('scorecardScores');
  }

  get scorecardScores() {
    let scores = [];
    get(this, '_scorecardScores').forEach((arraysOfScores) => {
      arraysOfScores.forEach((score) => {
        scores.addObject(score);
      });
    });

    return scores;
  }

  get skillsPicks() {
    return this.scorecardPicks.filterBy('topic', 'skills');
  }

  get personalityPicks() {
    return this.scorecardPicks.filterBy('topic', 'personality');
  }

  get sortedPickedInterviewKits() {
    return this.pickedInterviewKits.rejectBy('isDeleted').sortBy('position');
  }

  get interviewKits() {
    return this.sortedPickedInterviewKits.mapBy('interviewKit');
  }
}
