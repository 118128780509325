import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { JobApplicationModel } from 'teamtailor/models';

export default class StageDrag extends Service {
  @tracked aboardModalOpen = false;
  @tracked jobApplication: JobApplicationModel | null = null;
}

declare module '@ember/service' {
  interface Registry {
    'stage-drag': StageDrag;
  }
}
