import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  attrs: {
    paths: { serialize: false },
    intercomId: { serialize: false },
    jobsCreated: { serialize: false },
    accessLevels: { serialize: false },
  },
});
