import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import CareerSiteLanguageBaseRoute from 'teamtailor/routes/career-site-language-base';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';
import SettingsOrganizationDepartmentsEditController from 'teamtailor/controllers/settings/organization/departments/edit';

export default class NewRoute extends CareerSiteLanguageBaseRoute {
  @service declare store: Store;
  @service declare current: Current;

  model() {
    return this.store.createRecord('department', {
      startPageHeadline: get(this.current.company, 'departmentHeadlineDefault'),
      languageCode: this.defaultLanguageCode,
    });
  }

  afterModel() {
    this.controllerName = 'settings.organization.departments.edit';
  }

  resetController(
    controller: SettingsOrganizationDepartmentsEditController,
    isExiting: boolean
  ) {
    if (isExiting && get(controller.model, 'isNew')) {
      get(controller.model, 'pickedQuestions').forEach((question) =>
        question.rollbackAttributes()
      );
      get(controller.model, 'roles').forEach((role) =>
        role.rollbackAttributes()
      );

      if (this.current.company.hasFeature('career_site_languages')) {
        get(controller.model, 'translations').forEach((translation) =>
          translation.rollbackAttributes()
        );

        get(controller.model, 'roles').forEach((role) =>
          get(role, 'translations').forEach((translation) =>
            translation.rollbackAttributes()
          )
        );
      }

      controller.model.unloadRecord();
    }
  }
}
