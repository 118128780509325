import { set } from '@ember/object';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import EmployeesProfileSecurityDisable2faController from 'teamtailor/controllers/employees/profile/security/disable-2fa';
import UserModel from 'teamtailor/models/user';
import { get } from 'teamtailor/utils/get';

export default class EmployeesProfileSecurityDisable2fa extends Route {
  @service declare router: RouterService;

  model(): UserModel {
    return this.modelFor('employees.profile');
  }

  async afterModel(model: UserModel) {
    const login = await get(model, 'login');
    if (!login?.twoFactorActivated) {
      this.router.transitionTo('employees.profile.security.activate-2fa');
    }
  }

  resetController(
    controller: EmployeesProfileSecurityDisable2faController,
    isExiting: boolean
  ) {
    if (isExiting) {
      set(controller, 'code', '');
    }
  }
}
