import Owner from '@ember/owner';
import Service, { inject as service } from '@ember/service';
import { IntlService } from 'ember-intl';
import { EkEvent, keyResponder, onKey } from 'ember-keyboard';
import KeyboardService from 'ember-keyboard/services/keyboard';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

interface ConfirmOptions {
  title?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonClass?: string;
}

interface CustomConfirmArgs {
  title: string;
  html?: string;
  text: string;
  confirmButtonText: string;
  cancelButtonText: string;
  confirmCallback(): void;
  cancelCallback?(): void;
  confirmButtonClass?: string;
  showCancelButton?: boolean;
  showCloseButton?: boolean;
}

@keyResponder
export default class TtAlertService extends Service {
  @service declare intl: IntlService;
  @service declare keyboard: KeyboardService;

  constructor(owner: Owner) {
    super(owner);

    this.Swal = Swal.mixin({
      onOpen: () => {
        const highestPriorityResponder = this.keyboard.sortedResponders[0];

        if (highestPriorityResponder !== this) {
          const highestActivePriority =
            this.keyboard.sortedResponders[0]?.keyboardPriority ?? 0;

          this.keyboardPriority = highestActivePriority + 1;
        }
      },
    });
  }

  declare keyboardPriority: number;

  Swal: typeof Swal;

  get isOpen() {
    return this.Swal.isVisible();
  }

  @onKey('Escape')
  onEscapeKey(_event: KeyboardEvent, ekEvent: EkEvent) {
    if (this.isOpen) {
      this.Swal.close();
      ekEvent.stopPropagation();
    }
  }

  get target() {
    return document.getElementById('ember-teamtailor-modal-on-modal') || 'body';
  }

  error(text: string) {
    this.Swal.fire(this.intl.t('tt_alert.title.error'), text, 'error');
  }

  success(text: string) {
    this.Swal.fire(this.intl.t('tt_alert.title.success'), text, 'success');
  }

  warning(text: string) {
    this.Swal.fire(this.intl.t('tt_alert.title.warning'), text, 'warning');
  }

  info(text: string) {
    this.Swal.fire(this.intl.t('tt_alert.title.info'), text, 'info');
  }

  question(text: string) {
    this.Swal.fire(this.intl.t('tt_alert.title.question'), text, 'question');
  }

  confirm(
    text: string,
    confirmCallback: () => void,
    cancelCallback?: () => void,
    options?: ConfirmOptions
  ) {
    options = options ? options : {};
    const { title, confirmButtonText, cancelButtonText, confirmButtonClass } =
      options;
    this.Swal.fire({
      title: title ? title : this.intl.t('common.are_you_sure'),
      text,
      type: 'warning',
      confirmButtonClass: confirmButtonClass
        ? confirmButtonClass
        : 'btn-danger',

      confirmButtonText: confirmButtonText
        ? confirmButtonText
        : this.intl.t('common.yes'),

      cancelButtonText: cancelButtonText
        ? cancelButtonText
        : this.intl.t('common.cancel'),

      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
      target: this.target,
      customClass: {
        container: 'pointer-events-auto',
      },
    }).then((result) => {
      if (result.value) {
        confirmCallback();
      } else {
        if (cancelCallback) {
          cancelCallback();
        }
      }
    });
  }

  customConfirm({
    title,
    html,
    text,
    confirmButtonText,
    cancelButtonText,
    confirmCallback,
    cancelCallback,
    confirmButtonClass,
    showCancelButton = true,
    showCloseButton = true,
  }: CustomConfirmArgs) {
    this.Swal.fire({
      title,
      html,
      text,
      confirmButtonText,
      cancelButtonText,
      confirmButtonClass,
      type: 'warning',
      showCancelButton,
      showCloseButton,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmCallback();
      } else {
        cancelCallback?.();
      }
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'tt-alert': TtAlertService;
  }
}
