import Controller from '@ember/controller';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';
import { trackedFunction } from 'ember-resources/util/function';

const CAREER_SITES_COLUMNS = [
  {
    label: '',
  },
  {
    label: 'common.language',
  },
  {
    label: 'common.status',
  },
  {
    label: '',
  },
];

export default class SettingsLanguageController extends Controller {
  @service current;
  @service ttAlert;
  @service intl;
  @service store;
  @service flashMessages;
  @service pusher;
  @service flipper;

  @tracked modalOpen = false;
  @tracked careerSite = null;
  @tracked isChangingDefaultCareerSite = false;

  userChannel = null;
  columns = CAREER_SITES_COLUMNS;

  get locales() {
    return this.model;
  }

  get company() {
    return this.current.company;
  }

  get user() {
    return this.current.user;
  }

  get defaultCareerSite() {
    return get(this.company, 'defaultCareerSite');
  }

  get defaultLanguageCode() {
    return get(this, 'defaultCareerSite.languageCode');
  }

  get selectedLanguageCodes() {
    return get(this, 'company.availableCareerSiteLanguages');
  }

  get availableLocales() {
    return this.locales.map((locale) => {
      locale.isDisabled = this.isCodeTaken(locale.code);
      return locale;
    });
  }

  resolveSortedCareerSitesResponses = trackedFunction(this, async () => {
    const careerSites = await this.current.company.careerSites;
    return careerSites
      .slice()
      .toSorted((a, b) =>
        a.translatedLanguage.localeCompare(b.translatedLanguage)
      );
  });

  get sortedCareerSites() {
    return this.resolveSortedCareerSitesResponses.value ?? [];
  }

  get filteredCareerSites() {
    const careerSites = [
      this.defaultCareerSite,
      ...this.sortedCareerSites.filter((a) => !a.isDefault),
    ];

    return careerSites.filter((site) => site != null);
  }

  get missingCareerSiteLanguages() {
    const existingCareerSiteLanguages = this.sortedCareerSites.map(
      (site) => site.languageCode
    );

    const languagesWithoutCareerSite = this.selectedLanguageCodes.filter(
      (languageCode) => {
        return (
          !existingCareerSiteLanguages.includes(languageCode) &&
          !isEmpty(languageCode)
        );
      }
    );

    return languagesWithoutCareerSite;
  }

  isCodeTaken(code) {
    return (
      code === this.defaultLanguageCode ||
      this.selectedLanguageCodes.includes(code)
    );
  }

  toggleCareerSiteStatusTask = dropTask(async (careerSite) => {
    await careerSite.changeStatus();
  });

  @action
  save() {
    const promises = [];

    if (this.defaultCareerSite.hasDirtyAttributes) {
      promises.push(this.defaultCareerSite.save());
    }

    if (get(this.company, 'hasDirtyAttributes')) {
      promises.push(this.company.save());
    }

    return Promise.all(promises);
  }

  deleteCareerSiteTask = dropTask(async (careerSite) => {
    await careerSite.destroyRecord();

    const { languageCode } = careerSite;
    const newLanguages = get(
      this,
      'company.availableCareerSiteLanguages'
    ).filter((code) => code !== languageCode);

    set(this, 'company.availableCareerSiteLanguages', newLanguages);
    await this.company.save();
  });

  async confirmDeleteCareerSite(careerSite) {
    const careerSiteJobs = await this.store.query('job', {
      language_code: careerSite.languageCode,
      status: 'published',
      per_page: 1,
    });

    if (careerSiteJobs?.length === 0) {
      this.ttAlert.confirm(
        this.intl.t('settings.language.delete_confirm'),
        () => {
          this.deleteCareerSiteTask.perform(careerSite);
        }
      );
    } else {
      this.ttAlert.error(
        this.intl.t('career_sites.delete.cant_delete_explanation')
      );
    }
  }

  @action
  handleDefaultLanguageCodeChange({ code }) {
    set(this, 'defaultCareerSite.languageCode', code);
  }

  @action
  toggleCareerSiteStatus(careerSite) {
    return this.toggleCareerSiteStatusTask.perform(careerSite);
  }

  @action
  handleDefaultCareerSiteChange(careerSite) {
    this.ttAlert.customConfirm({
      title: this.intl.t(
        'settings.language.confirm_default_language_change_title',
        {
          language: careerSite.translatedLanguage,
        }
      ),

      html: `<p>${this.intl.t(
        'settings.language.confirm_default_language_change',
        {
          careerSite: `${careerSite.emoji} ${careerSite.name}`,
        }
      )}</p><p>${this.intl.t('settings.language.approximate_timeframe')}</p>`,

      cancelButtonText: this.intl.t('common.cancel'),
      confirmButtonText: this.intl.t('settings.language.confirm_button'),
      cancelCallback: () => {},
      confirmCallback: () => {
        this.isChangingDefaultCareerSite = true;

        this.pusher
          .subscribe(this.user.notificationsChannel)
          .then((channel) => {
            this.userChannel = channel;
            channel.bind('default-language-change-complete', () => {
              this.isChangingDefaultCareerSite = false;
            });
          });

        careerSite
          .makeDefault()
          .then(() => {
            set(this.company, 'careerSites', this.store.peekAll('career-site'));
          })
          .catch(({ errors }) => {
            if (errors.length > 0) {
              const { status } = errors[0].status;

              if (status === '503') {
                this.flashMessages.error(
                  this.intl.t(
                    'settings.language.default_language_change_unavailable'
                  )
                );
              }

              if (status === '409') {
                this.flashMessages.error(
                  this.intl.t(
                    'settings.language.default_language_change_already_running'
                  )
                );
              }
            }
          });
      },
    });
  }

  @action
  updateCareerSite({ prop, value }) {
    this.careerSite[prop] = value;
  }

  @action
  handlePickEmoji(careerSite, emoji) {
    careerSite.emoji = emoji;
    careerSite.save();
  }

  @action
  handleDelete(careerSite) {
    this.confirmDeleteCareerSite(careerSite);
  }

  @action
  handleModalClose() {
    this.modalOpen = false;
    get(this, 'company').rollbackAttributes();

    if (this.careerSite.isNew) {
      this.careerSite.unloadRecord();
    }
  }

  @action
  handleModalOpen(languageCode) {
    this.careerSite = this.store.createRecord('careerSite', {
      languageCode,
      emoji: '🌎',
    });

    if (languageCode) {
      this.careerSite.name = this.intl.t(`language_codes.${languageCode}`);
    }

    this.modalOpen = true;
  }

  @action
  async handleSaveNewCareerSite() {
    await this.careerSite.save();
    if (
      !get(this, 'company.availableCareerSiteLanguages').includes(
        this.careerSite.languageCode
      )
    ) {
      get(this, 'company.availableCareerSiteLanguages').push(
        this.careerSite.languageCode
      );
    }

    await this.company.save();

    this.modalOpen = false;
  }
}
