import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { CompanyModel } from 'teamtailor/models';

export default class DivisionCountModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;

  @attr('number') declare count: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'division-count': DivisionCountModel;
  }
}
