import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Insights::Molecules::Widget\n  ...attributes\n  @title={{t \"insights.common.sources\"}}\n  @showMoreLink={{@showMoreLink}}\n  @onShowExportModal={{@onShowExportModal}}\n  @empty={{if @data.length false true}}\n  @emptyEmoji=\"👀\"\n  @emptyText={{t \"reports.no_sources_yet\"}}\n  @loading={{@isLoading}}\n>\n  <:content>\n    <div\n      class=\"mb-0 flex flex-1 flex-wrap items-center justify-between justify-items-stretch\"\n    >\n      <div class=\"flex min-w-[210px] flex-1 items-center justify-center\">\n        <div class=\"min-w-[210px]\">\n          <Insights::Charts::DonutChart @data={{@data}} @variant=\"sources\" />\n        </div>\n      </div>\n\n      <div>\n        <Insights::Molecules::AdvancedLegend\n          @data={{@data}}\n          @total={{this.total}}\n          @limit=\"5\"\n        />\n      </div>\n    </div>\n  </:content>\n</Insights::Molecules::Widget>", {"contents":"<Insights::Molecules::Widget\n  ...attributes\n  @title={{t \"insights.common.sources\"}}\n  @showMoreLink={{@showMoreLink}}\n  @onShowExportModal={{@onShowExportModal}}\n  @empty={{if @data.length false true}}\n  @emptyEmoji=\"👀\"\n  @emptyText={{t \"reports.no_sources_yet\"}}\n  @loading={{@isLoading}}\n>\n  <:content>\n    <div\n      class=\"mb-0 flex flex-1 flex-wrap items-center justify-between justify-items-stretch\"\n    >\n      <div class=\"flex min-w-[210px] flex-1 items-center justify-center\">\n        <div class=\"min-w-[210px]\">\n          <Insights::Charts::DonutChart @data={{@data}} @variant=\"sources\" />\n        </div>\n      </div>\n\n      <div>\n        <Insights::Molecules::AdvancedLegend\n          @data={{@data}}\n          @total={{this.total}}\n          @limit=\"5\"\n        />\n      </div>\n    </div>\n  </:content>\n</Insights::Molecules::Widget>","moduleName":"teamtailor/components/insights/widgets/sources.hbs","parseOptions":{"srcName":"teamtailor/components/insights/widgets/sources.hbs"}});
import Component from '@glimmer/component';
import flatten from 'teamtailor/utils/flatten';

export default class InsightsWidgetsSources extends Component {
  get total() {
    return flatten(this.args.data.mapBy('value')).reduce(
      (acc, i) => acc + i,
      0
    );
  }
}
