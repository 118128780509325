import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex flex-col gap-16\">\n  <ChoiceCard\n    @outerMargin={{false}}\n    @type=\"switch\"\n    @label={{t \"components.share_link.password_restricted\"}}\n    @subTitle={{t \"components.share_link.set_password\"}}\n    @value=\"switch\"\n    @checked={{@restricted}}\n    @onChange={{this.toggleOption}}\n  />\n  {{#ember-smooth/if @restricted}}\n    <Core::Form::Field\n      @as=\"core/input\"\n      @size=\"large\"\n      @required={{true}}\n      @value={{@password}}\n      @label={{t \"components.share_link.password\"}}\n      @errors={{@errors}}\n      @onInput={{@onPasswordChange}}\n    />\n  {{/ember-smooth/if}}\n</div>", {"contents":"<div class=\"flex flex-col gap-16\">\n  <ChoiceCard\n    @outerMargin={{false}}\n    @type=\"switch\"\n    @label={{t \"components.share_link.password_restricted\"}}\n    @subTitle={{t \"components.share_link.set_password\"}}\n    @value=\"switch\"\n    @checked={{@restricted}}\n    @onChange={{this.toggleOption}}\n  />\n  {{#ember-smooth/if @restricted}}\n    <Core::Form::Field\n      @as=\"core/input\"\n      @size=\"large\"\n      @required={{true}}\n      @value={{@password}}\n      @label={{t \"components.share_link.password\"}}\n      @errors={{@errors}}\n      @onInput={{@onPasswordChange}}\n    />\n  {{/ember-smooth/if}}\n</div>","moduleName":"teamtailor/components/share-link/password-restricted-option.hbs","parseOptions":{"srcName":"teamtailor/components/share-link/password-restricted-option.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { v4 as uuid } from 'ember-uuid';

type Args = {
  onRestrictedChange: () => void;
  onPasswordChange: (password: string) => void;
  setPassword: (password: string) => void;
  restricted: boolean;
  password: string;
  errors?: { message: string };
};

export default class PasswordRestrictedOption extends Component<Args> {
  get restricted() {
    return this.args.restricted;
  }

  @action
  toggleOption() {
    this.args.onRestrictedChange();

    if (this.restricted && !this.args.password) {
      const password = uuid().replace('-', '').substring(0, 12);
      this.args.setPassword(password);
    }
  }
}
