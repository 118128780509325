import CurrentService from 'teamtailor/services/current';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import RSVP from 'rsvp';
import { tracked } from '@glimmer/tracking';
import { get } from 'teamtailor/utils/get';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';
import FlashMessageService from 'teamtailor/services/flash-message';
import Store from '@ember-data/store';
import ArrayProxy from '@ember/array/proxy';
import {
  RequisitionFlowModel,
  RequisitionFlowStepModel,
  RequisitionArchiveReasonModel,
} from 'teamtailor/models';
import { FormBuilderField } from 'teamtailor/utils/form-builder';
import RequisitionUpdateService from 'teamtailor/services/requisition-update-service';

interface CustomFormAnswer {
  [key: string]: unknown;
}

interface CustomFormError {
  [key: string]: { message: string }[];
}

export default class IndexController extends Controller {
  @service declare current: CurrentService;
  @service declare flashMessages: FlashMessageService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare requisitionUpdateService: RequisitionUpdateService;

  @tracked customFieldAnswers: CustomFormAnswer = {};
  @tracked customFieldErrors: CustomFormError = {};
  @tracked isModalOpen = false;
  @tracked selectedArchiveReason: RequisitionArchiveReasonModel | null = null;

  get requisitionSetting() {
    return this.current.company.requisitionSetting;
  }

  get requisition() {
    return this.model;
  }

  get requisitionArchiveReasons() {
    const reasons = this.current.company.sortedRequisitionArchiveReasons;
    return reasons.map((reason: RequisitionArchiveReasonModel) => {
      return {
        name: reason.reason,
        id: reason.id,
        companyId: this.current.company.id,
        reason,
      };
    });
  }

  @action
  handleSetArchiveReason(selectedReason: RequisitionArchiveReasonModel) {
    this.selectedArchiveReason = selectedReason;
    set(this.requisition, 'requisitionArchiveReason', selectedReason.reason);
  }

  @action
  async saveRequisitionArchiveReason() {
    this.isModalOpen = false;
    if (this.selectedArchiveReason !== null) {
      await this.requisition.archive(this.selectedArchiveReason.id);
    }
  }

  get customFields(): FormBuilderField[] {
    return this.requisition.customForm.form;
  }

  get editRequisitionButtonText() {
    return get(this.requisition, 'isDraft')
      ? this.intl.t('requisitions.index.edit_draft')
      : this.intl.t('requisitions.index.edit_requisition');
  }

  get isCreator() {
    return get(this.current.user, 'id') === get(this.requisition, 'user.id');
  }

  get isRejectedOrDraft() {
    return (
      get(this.requisition, 'isRejected') || get(this.requisition, 'isDraft')
    );
  }

  get canEditDetails() {
    return get(this, 'isCreator');
  }

  get canArchive() {
    return get(this, 'isCreator') || get(this.current.user, 'admin');
  }

  get editModels() {
    const divisions = this.store.findAll('division');
    const departments = this.store.findAll('department');
    const roles = this.store.findAll('role');
    const locations = this.store.findAll('location');
    const defaultFlow = this.store
      .findAll('requisition-flow')
      .then((flow: ArrayProxy<RequisitionFlowModel>) => flow[0]);

    return RSVP.hash({
      divisions,
      departments,
      roles,
      locations,
      defaultFlow,
    });
  }

  validateCustomForm(isDraft: boolean) {
    this.customFieldErrors = {};

    if (isDraft) {
      return;
    }

    const errorObj = [
      {
        message: this.intl.t('errors.cant_be_blank'),
      },
    ];

    const visibleFields = this.customFields.filter((f) => {
      if (f.show_if) {
        return (
          this.customFieldAnswers[f.show_if.ref_uuid as string] ===
          f.show_if.value
        );
      }

      return true;
    });

    const missingFieldUuids = visibleFields
      .filter((f) => !!f.required)
      .filter((f) => !this.customFieldAnswers[f.uuid])
      .map((f) => f.uuid);

    const customFieldErrors = missingFieldUuids.reduce<CustomFormError>(
      (acc, fieldUuid) => {
        return {
          ...acc,
          [fieldUuid]: errorObj,
        };
      },
      {}
    );

    this.customFieldErrors = customFieldErrors;
  }

  @action
  async editRequisition() {
    this.requisitionUpdateService.isEditing = true;

    if (!get(this.requisition, 'requisitionFlow.id')) {
      set(
        this.requisition,
        'requisitionFlow',
        get(this.editModels, 'defaultFlow')
      );
    }
  }

  @action
  async archive() {
    if (!this.requisition.archivedAt) {
      this.isModalOpen = true;
    } else {
      return get(this, 'requisition').archive(null);
    }
  }

  @action
  onCustomFieldChange(key: string, value: unknown) {
    set(this.customFieldAnswers, key, value);
  }

  @action
  async saveUpdatedRequisition(isDraft = false) {
    const updatedRequisition = get(this, 'requisition');

    this.validateCustomForm(isDraft);

    set(updatedRequisition, 'isDraft', isDraft);

    const form = get(this, 'customFields');
    const answers = get(this, 'customFieldAnswers');

    set(updatedRequisition, 'customForm', {
      form,
      answers,
    });

    updatedRequisition.steps.forEach(async (step: RequisitionFlowStepModel) => {
      await step;

      step.unloadRecord();
    });

    try {
      await updatedRequisition.save();

      if (Object.keys(this.customFieldErrors).length > 0 && !isDraft) {
        throw new Error(this.intl.t('requisitions.new.missing_custom_fields'));
      }

      get(this, 'flashMessages').success(
        this.intl.t('requisitions.index.requisition_updated')
      );

      this.requisitionUpdateService.isEditing = false;
    } catch (e) {
      set(updatedRequisition, 'isDraft', true);
      get(this, 'flashMessages').error(
        this.intl.t('requisitions.index.requisition_update_failed')
      );
    }
  }
}
declare module '@ember/controller' {
  interface Registry {
    'jobs.requisition.index': IndexController;
  }
}
