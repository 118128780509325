import Model, {
  AsyncBelongsTo,
  AsyncHasMany,
  SyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { compare, isEmpty, isPresent } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import computeFullErrorMessages from 'teamtailor/utils/full-error-messages';
import AnonymalsService from 'teamtailor/services/anonymals';
import {
  ActivityModel,
  AnswerModel,
  CompanyModel,
  DepartmentModel,
  FormInviteModel,
  HireQualityResponseModel,
  InterviewModel,
  JobApplicationModel,
  JobApplicationTriggerModel,
  JobModel,
  JobOfferModel,
  MeetingEventAttendeeModel,
  MeetingEventModel,
  MeetingEventStatusModel,
  MessageModel,
  NoteModel,
  NurtureCampaignRecipientModel,
  PartnerResultModel,
  PickedCustomFieldModel,
  PickedLocationModel,
  RecruitingFirmModel,
  ReferenceModel,
  ReferralModel,
  ReviewModel,
  RoleModel,
  ScorecardCommentModel,
  ScorecardScoreModel,
  ShareLinkModel,
  SourceModel,
  TagModel,
  TodoModel,
  UploadModel,
  UserModel,
  DataRequestModel,
} from 'teamtailor/models';
import StageTypesCounts from 'teamtailor/types/stage-types-counts';
import { UnwrapComputedPropertySetters } from '@ember/object/-private/types';
import Server from 'teamtailor/services/server';
import Current from 'teamtailor/services/current';

export type CandidateAttributes = Partial<
  Pick<UnwrapComputedPropertySetters<CandidateModel>, keyof CandidateModel>
>;

export type CandidateStatus =
  | 'lead'
  | 'externally_sourced'
  | 'sourced'
  | 'referred'
  | 'connected'
  | 'will_be_deleted'
  | 'application_will_be_deleted'
  | 'internal'
  | 'data_requested'
  | 'removal_requested'
  | 'consent_expired'
  | 'consent_pending'
  | 'consent_missing';

export default class CandidateModel extends Model {
  @service declare anonymals: AnonymalsService;
  @service declare intl: IntlService;
  @service declare server: Server;
  @service declare current: Current;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('department') declare department: AsyncBelongsTo<DepartmentModel>;
  @belongsTo('user') declare externalRecruiter: AsyncBelongsTo<UserModel>;
  @belongsTo('recruiting-firm')
  declare recruitingFirm: AsyncBelongsTo<RecruitingFirmModel>;

  @belongsTo('user') declare resumeSummaryUpdatedBy: AsyncBelongsTo<UserModel>;
  @belongsTo('role') declare role: AsyncBelongsTo<RoleModel>;
  @belongsTo('source') declare source: AsyncBelongsTo<SourceModel>;

  @hasMany('data-request')
  declare dataRequests: AsyncHasMany<DataRequestModel>;

  @hasMany('activity') declare activities: AsyncHasMany<ActivityModel>;
  @hasMany('answer') declare answers: AsyncHasMany<AnswerModel>;
  @hasMany('candidate', { inverse: null })
  declare duplicateCandidates: AsyncHasMany<CandidateModel>;

  @hasMany('interview') declare interviews: AsyncHasMany<InterviewModel>;
  @hasMany('job-application')
  declare jobApplications: AsyncHasMany<JobApplicationModel>;

  @hasMany('job-application-trigger')
  declare jobApplicationTriggers: AsyncHasMany<JobApplicationTriggerModel>;

  @hasMany('job-offer') declare jobOffers: AsyncHasMany<JobOfferModel>;
  @hasMany('meeting-event-attendee')
  declare meetingEventAttendees: AsyncHasMany<MeetingEventAttendeeModel>;

  @hasMany('meeting-event')
  declare meetingEvents: AsyncHasMany<MeetingEventModel>;

  @hasMany('meeting-event-status', { async: false })
  declare meetingEventStatuses: SyncHasMany<MeetingEventStatusModel>;

  @hasMany('message') declare messages: AsyncHasMany<MessageModel>;
  @hasMany('note') declare notes: AsyncHasMany<NoteModel>;
  @hasMany('nurture-campaign-recipient')
  declare nurtureCampaignRecipients: AsyncHasMany<NurtureCampaignRecipientModel>;

  @hasMany('partner-result')
  declare partnerResults: AsyncHasMany<PartnerResultModel>;

  @hasMany('picked-custom-field', { polymorphic: true })
  declare pickedCustomFields: AsyncHasMany<PickedCustomFieldModel>;

  @hasMany('picked-location')
  declare pickedLocations: AsyncHasMany<PickedLocationModel>;

  @hasMany('reference') declare references: AsyncHasMany<ReferenceModel>;
  @hasMany('referral') declare referrals: AsyncHasMany<ReferralModel>;
  @hasMany('review') declare reviews: AsyncHasMany<ReviewModel>;
  @hasMany('scorecard-score')
  declare scorecardScores: AsyncHasMany<ScorecardScoreModel>;

  @hasMany('scorecard-comment')
  declare scorecardComments: AsyncHasMany<ScorecardCommentModel>;

  @hasMany('share-link') declare shareLinks: AsyncHasMany<ShareLinkModel>;
  @hasMany('form-invite') declare formInvites: AsyncHasMany<FormInviteModel>;
  @hasMany('tag') declare tags: AsyncHasMany<TagModel>;
  @hasMany('todo') declare todos: AsyncHasMany<TodoModel>;
  @hasMany('upload') declare uploads: AsyncHasMany<UploadModel>;
  @hasMany('user') declare users: AsyncHasMany<UserModel>;
  @hasMany('hire-quality-response')
  declare hireQualityResponses: AsyncHasMany<HireQualityResponseModel>;

  @attr('string') declare about: string;
  @attr('boolean') declare anonymousResumeFailed: boolean;
  @attr('number') declare averageRating: number;
  @attr('raw') declare candidateStatus: string[];
  @attr('string') declare color: string;
  @attr('date') declare connectedAt: Date;
  @attr('date') declare consentGivenAt?: Date;
  @attr('date') declare consentExpiresAt?: Date;
  @attr('date') declare consentFutureJobsAt: Date;
  @attr('date') declare consentMissingAt?: Date;
  @attr('date') declare consentRequestedAt: Date;
  @attr('date') declare createdAt: Date;
  @attr('date') declare dataRequestedAt: Date;
  @attr('string') declare deleteReason: string;
  @attr('boolean', { defaultValue: false }) declare deleteResume: boolean; // Setting this attribute and persisting model will remove resume
  @attr('string') declare downloadOriginalResumeUrl: string;
  @attr('string') declare downloadResumeUrl: string;
  @attr('string') declare email: string | null;
  @attr('date') declare emailFailedAt: Date;
  @attr('number') declare externalRecruiterId: number;
  @attr('number') declare externalRecruitmentId: number;
  @attr('string') declare facebookProfile: string;
  @attr('string') declare firstName: string | null;
  @attr('boolean') declare fromEmail: boolean;
  @attr('boolean') declare hiddenFromSearch: boolean;
  @attr('number') declare hiredCount: number;
  @attr('number') declare inboxCount: number;
  @attr('string') declare initials: string;
  @attr('number') declare inProcessCount: number;
  @attr('boolean') declare internal: boolean;
  @attr('boolean') declare isFileInfected: boolean;
  @attr('boolean') declare isFromBulkAdd: boolean;
  @attr('boolean') declare isNumberInShortcodeCountry: boolean;
  @attr('boolean') declare isTwilioNumberValid: boolean;
  @attr('date') declare jobApplicationWillBeDeletedAt: Date;
  @attr('string') declare jobId: string;
  @attr('string') declare languageCode: string;
  @attr('date') declare lastActivityAt: Date;
  @attr('date') declare lastApplicationAt: Date;
  @attr('number') declare lastManualMessageId: number;
  @attr('string') declare lastName: string | null;
  @attr('string') declare linkedinId: string;
  @attr('string') declare linkedinProfile: string;
  @attr('string') declare linkedinUrl: string;
  @attr('string') declare metaText: string;
  @attr('string') declare note: string;
  @attr('string') originalResumeFileName?: string;
  @attr('string') pdfOrOriginalResumeFileName?: string;
  @attr('string') declare phone: string;
  @attr('carrierwave') declare picture: string;
  @attr('carrierwave-cache') declare pictureCache: string;
  @attr('string') declare pitch: string;
  @attr('raw') declare pusherChannels: string[];
  @attr('string') declare pusherChatPresence: string;
  @attr('boolean') declare receivedBulkSms: boolean;
  @attr('number') declare recruitingFirmId: number;
  @attr('number') declare referralsCount: number;
  @attr('string') declare referringSite: string;
  @attr('string') declare referringUrl: string;
  @attr('number') declare rejectedCount: number;
  @attr('string') declare remotePictureUrl: string;
  @attr('date') declare removalRequestedAt: Date;
  @attr('boolean') declare removePicture: boolean;
  @attr('boolean') declare restricted: boolean;
  @attr('date') declare restrictedAt: Date;
  @attr('string') declare resume: string;
  @attr('string') declare resumeSummary: string;
  @attr('string', { defaultValue: 'na' }) declare resumeSummaryStatus: string;
  @attr('date') declare resumeSummaryUpdatedAt: Date;
  @attr('boolean') declare resumeTextPresent: boolean;
  @attr('number') declare reviewCount: number;
  @attr('number') declare reviewRating: number;
  @attr('date') declare sentExtendGdprReminderAt: Date;
  @attr('string') declare socialImageUrl: string;
  @attr('boolean') declare socketUpdated: boolean;
  @attr('date') sourcedAt?: Date;
  @attr('raw') declare stageTypesCounts?: StageTypesCounts;
  @attr('string') declare status: string;
  @attr('date') declare unsubscribedAt: Date;
  @attr('date') declare unsubscribedFromNurtureCampaignsAt: Date;
  @attr('boolean') declare unsubscribedFromSms: boolean;
  @attr('string') declare uuid: string;
  @attr('date') declare willBeDeletedAt: Date;

  get fullErrorMessages() {
    return computeFullErrorMessages(this, this.intl);
  }

  get jobs() {
    return this.jobApplications.map((ja) => ja.job);
  }

  get nameOrEmail() {
    return this.name || this.email;
  }

  get isSourced() {
    return !!this.sourcedAt;
  }

  get isExternallySourced() {
    return !!this.externalRecruitmentId;
  }

  get isLead() {
    return this.status === 'lead';
  }

  get anonymal() {
    return this.anonymals.fromSeed(this.id);
  }

  get anonymousName() {
    return this.anonymal.name;
  }

  get anonymousColor() {
    return this.anonymal.colorHex;
  }

  get anonymalIconUrl() {
    return this.anonymal.iconUrl;
  }

  get pendingDataRequest() {
    return this.dataRequests.find((dr) => dr.isPending);
  }

  get partnerResultsWithScores() {
    return get(this, 'partnerResults').filterBy('hasScore', true);
  }

  get scoreObjects() {
    return [...get(this, 'partnerResultsWithScores').toArray()];
  }

  get hasReferences() {
    return !!get(this, 'references').length;
  }

  get hasJobApplications() {
    return !!get(this, 'jobApplications').length;
  }

  get consentIsExpired() {
    return !!(this.consentExpiresAt && this.consentExpiresAt < new Date());
  }

  get consentIsPending() {
    return !!(this.consentMissingAt && this.consentRequestedAt);
  }

  get companyOptInFutureJobs() {
    const termsOfServiceSetting = get(this.company, 'termsOfServiceSetting');
    return (
      get(termsOfServiceSetting, 'optInFutureJobs') ||
      get(termsOfServiceSetting, 'optInFutureJobsConnect')
    );
  }

  scopedPusherChannels(jobId: number | null | undefined) {
    if (jobId) {
      return this.pusherChannels.reject(
        (channel) =>
          channel.includes('job') &&
          !channel.includes(`job-${jobId.toString()}`)
      );
    }

    return this.pusherChannels;
  }

  get consentFutureJobsMissing() {
    return (
      this.companyOptInFutureJobs &&
      (isPresent(this.consentMissingAt) || isPresent(this.consentExpiresAt)) &&
      isEmpty(this.consentFutureJobsAt)
    );
  }

  get jobApplicationsWillBeDeleted() {
    return this.jobApplications.filterBy('willBeDeletedAt');
  }

  get sortedTags() {
    return get(this.tags, 'isFulfilled')
      ? this.tags.toArray().sort((a, b) => compare(a.name, b.name))
      : [];
  }

  get locations() {
    return get(this, 'pickedLocations').uniqBy('location.id').mapBy('location');
  }

  get canReceiveSms() {
    return this.isTwilioNumberValid && !this.unsubscribedFromSms;
  }

  get isOriginalResumePdf() {
    return this.originalResumeFileName?.slice(-3).toLowerCase() === 'pdf';
  }

  get isPdfResume() {
    return this.pdfOrOriginalResumeFileName?.slice(-3).toLowerCase() === 'pdf';
  }

  get lastActivity() {
    return this.lastActivityAt;
  }

  get name() {
    const { firstName, lastName } = this;
    if (isEmpty(firstName) && isEmpty(lastName)) {
      return null;
    } else {
      return [firstName, lastName].compact().join(' ');
    }
  }

  get scheduledJobApplicationTriggers() {
    return this.jobApplicationTriggers.filter((jobApplicationTrigger) => {
      return (
        get(jobApplicationTrigger, 'status') === 'pending' &&
        isPresent(get(jobApplicationTrigger, 'scheduledAt'))
      );
    });
  }

  get sortedScheduledJobApplicationTriggers() {
    return this.scheduledJobApplicationTriggers.sort((a, b) =>
      compare(a.scheduledAt, b.scheduledAt)
    );
  }

  get scorecardScoresWithoutSurveys() {
    return get(this, 'scorecardScores').filterBy('hireQualityResponseId', null);
  }

  async askForFeedback(data: { reference_id: string; template_id: string }) {
    return this.server.memberAction(this, {
      action: 'ask_for_feedback',
      options: { data },
    });
  }

  get privacyNotification() {
    return (
      isPresent(this.removalRequestedAt) ||
      isPresent(this.consentMissingAt) ||
      isPresent(this.jobApplicationWillBeDeletedAt) ||
      isPresent(this.willBeDeletedAt) ||
      isPresent(this.dataRequestedAt) ||
      this.consentIsExpired
    );
  }

  async requestConsent() {
    return this.server.memberAction(this, {
      action: 'request_consent',
    });
  }

  async dismissDataRequest() {
    return this.server.memberAction(this, { action: 'dismiss_data_request' });
  }

  async handleDataRequest(data: { dismissed_or_handled: string }) {
    return this.server.memberAction(this, {
      action: 'handle_data_request',
      options: { data },
    });
  }

  async extendConsent() {
    return this.server.memberAction(this, { action: 'extend_consent' });
  }

  async saveTags() {
    await this.server.memberAction(this, {
      action: 'tag',
      options: {
        data: {
          tags: this.tags.mapBy('rawName').compact(),
        },
      },
    });
  }

  async fetchLastJobHiringTeam() {
    const response = await this.server.memberAction<{ users: UserModel[] }>(
      this,
      {
        action: 'last_job_hiring_team',
        method: 'GET',
      }
    );
    this.store.pushPayload('user', response);
    const users: (UserModel | null)[] = [];
    response.users.forEach((userJson: { id: string }) => {
      users.push(this.store.peekRecord('user', userJson.id));
    });
    return users;
  }

  async getUsersWithAccessThroughJob(job: JobModel | null = null) {
    const query = job ? `?job_id=${get(job, 'id')}` : '';
    const response = await this.server.memberAction<{ users: UserModel[] }>(
      this,
      {
        action: `users${query}`,
        method: 'GET',
        urlType: 'findHasMany',
      }
    );

    // We remove the current user here so we don't replace it (https://github.com/Teamtailor/teamtailor/issues/30766)
    this.store.pushPayload('user', {
      ...response,
      users: response.users.filter(
        (u) => u.id.toString() !== this.current.user.id
      ),
    });

    const users: (UserModel | null)[] = [];
    response.users.forEach((userJson: { id: string }) => {
      users.push(this.store.peekRecord('user', userJson.id));
    });

    return users;
  }

  async fetchLastReviewsState() {
    const response = await this.server.memberAction<{
      candidate: CandidateModel;
    }>(this, {
      action: 'last_reviews_state',
      method: 'GET',
    });
    this.store.pushPayload('candidate', response);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    candidate: CandidateModel;
  }
}
