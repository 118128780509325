import Modifier, { NamedArgs } from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import { tracked } from '@glimmer/tracking';
import Tribute from 'tributejs';
import Current from 'teamtailor/services/current';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import TeamtailorApolloService from 'teamtailor/services/apollo';
import { JobModel, UserModel } from 'teamtailor/models';
import TeamModel from 'teamtailor/models/team';
import { get } from 'teamtailor/utils/get';
import mentionItem from 'teamtailor/utils/mention-item';
import FlipperService from 'teamtailor/services/flipper';

interface MentionsSignature {
  Element: HTMLElement;
  Args: {
    Named: {
      candidateId?: string;
      jobId?: string;
      container?: HTMLElement;
      attachCallback?: (args: unknown) => unknown;
      includeCurrentUser?: boolean;
    };
  };
}

export default class MentionsModifier extends Modifier<MentionsSignature> {
  @service declare current: Current;
  @service declare store: Store;
  @service declare apollo: TeamtailorApolloService;
  @service declare flipper: FlipperService;

  @tracked declare el: HTMLElement;
  @tracked candidateId?: string;
  @tracked jobId?: string;
  @tracked container?: HTMLElement;
  @tracked attachCallback?: (args: unknown) => unknown;
  @tracked includeCurrentUser?: boolean;

  attached = false;
  tribute?: Tribute<UserModel | TeamModel>;

  async modify(
    element: HTMLElement,
    _: [],
    {
      candidateId,
      jobId,
      container,
      attachCallback,
      includeCurrentUser,
    }: NamedArgs<MentionsSignature>
  ) {
    this.el = element;
    this.candidateId = candidateId;
    this.jobId = jobId;
    this.container = container;
    this.attachCallback = attachCallback;
    this.includeCurrentUser = includeCurrentUser;

    if (!this.attached) {
      this.attached = true;
      await this.initializeTribute();
      registerDestructor(this, this.cleanup.bind(this));
    }
  }

  async initializeTribute() {
    this.tribute = new Tribute(this.generateOptions());
    this.tribute.attach(this.el);

    this.attachCallback?.(
      this.tribute.showMenuForCollection.bind(this.tribute)
    );
  }

  generateOptions() {
    const includeOnlineBeacon = get(this.flipper, 'user_online_status');
    return {
      values: this.mentionableUsers.bind(this),
      menuContainer: this.container || document.body,
      menuItemLimit: 10,
      fillAttr: 'username',
      selectClass: 'bg-neutral-weak',
      containerClass:
        'tribute-container shadow-high rounded-6 bg-canvas p-8 pointer-events-auto',

      itemClass:
        'w-full items-center rounded-6 px-8 py-4 text-left text-14 h-32 flex cursor-pointer',

      menuItemTemplate: (item: { original: UserModel | TeamModel }) =>
        mentionItem(item.original, includeOnlineBeacon),

      noMatchTemplate: () => {
        return '<span class="w-full items-center rounded-6 px-8 py-4 text-left text-14 h-32 flex">No user or team found</span>';
      },

      lookup: (option: TeamModel | UserModel) => {
        if (option instanceof UserModel) {
          return get(option, 'username') + get(option, 'nameOrEmail');
        } else {
          return get(option, 'username') + get(option, 'name');
        }
      },
    };
  }

  async mentionableUsers(
    _: string,
    cb: (users: (UserModel | TeamModel)[]) => void
  ) {
    const [teams, users] = await Promise.all([
      this.fetchMentionableTeams(),
      this.fetchMentionableUsers(),
    ]);
    cb([...teams, ...users].filter(Boolean));
  }

  async fetchMentionableUsers() {
    const candidate = this.candidateId
      ? this.store.peekRecord('candidate', this.candidateId)
      : undefined;

    let job: JobModel | null = null;
    if (this.jobId) {
      job = this.store.peekRecord('job', this.jobId);
      await get(job, 'jobDetail');
    }

    const users = candidate
      ? await candidate.getUsersWithAccessThroughJob(job)
      : job
        ? await Promise.all(get(job, 'usersWhoCanBeMentioned'))
        : [];

    await Promise.all(users.map((user) => get(user, 'activeStatus')));

    if (this.includeCurrentUser) {
      return users;
    } else {
      return users.filter((u) => u !== this.current.user && get(u, 'username'));
    }
  }

  async fetchMentionableTeams() {
    if (this.jobId) {
      const teams = [
        {
          id: 'team',
          username: 'team',
          name: 'Hiring team',
          emoji: '👥',
        } as TeamModel,
      ];
      const job = await this.store.peekRecord('job', this.jobId);

      const team = await get(job, 'team');
      if (team) {
        teams.push({
          id: get(team, 'id'),
          username: `team/${get(team, 'username')}`,
          name: get(team, 'name'),
          emoji: get(team, 'emoji'),
        } as TeamModel);
      }

      return teams;
    } else if (this.candidateId) {
      const teams = await this.store.query('team', {
        candidate_id: this.candidateId,
      });
      return teams.map((team) => {
        return {
          id: get(team, 'id'),
          username: `team/${get(team, 'username')}`,
          name: get(team, 'name'),
          emoji: get(team, 'emoji'),
        } as TeamModel;
      });
    }

    return [];
  }

  cleanup() {
    this.attached = false;
    this.tribute?.detach(this.el);
    this.tribute = undefined;
  }
}
