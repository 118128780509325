import Model, {
  type AsyncBelongsTo,
  type AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';
import Server from 'teamtailor/services/server';
import CareerSiteHeaderModel from './career-site-header';
import {
  CompanyModel,
  PageModel,
  PagePublicationModel,
  PushPayloadArg,
} from './index';
import PageCountModel from './page-count';

const STATUS_PUBLISHED = 'published';
const STATUS_DRAFT = 'draft';
const STATUS_BUILDING = 'building';

// TODO: Remove this when ember-data has proper return types for `serialize`
type SerializedCareerSite = {
  about: string;
  facebook_url: string;
  footer_layout: string;
  founded_in: string;
  instagram_url: string;
  linkedin_url: string;
  nr_employees: string;
  turnover: string;
  twitter_url: string;
  website: string;
};

export default class CareerSiteModel extends Model {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare server: Server;

  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('page') declare homePage: AsyncBelongsTo<PageModel>;
  @belongsTo('page') declare connectPage: AsyncBelongsTo<PageModel>;
  @belongsTo('page-count') declare pageCount: AsyncBelongsTo<PageCountModel>;

  @belongsTo('career-site-header')
  declare careerSiteHeader: AsyncBelongsTo<CareerSiteHeaderModel>;

  @hasMany('page-publication')
  declare pagePublications: AsyncHasMany<PagePublicationModel>;

  @attr('string') declare about: string;
  @attr('string') declare connectWelcomeMessage: string;
  @attr('string') declare departmentNamePlural: string;
  @attr('string') declare departmentNameSingular: string;
  @attr('string') declare emoji: string;
  @attr('string') declare facebookUrl: string;
  @attr('string', { defaultValue: 'default' }) declare footerLayout: string;
  @attr('string') declare foundedIn: string;
  @attr('string') declare instagramUrl: string;
  @attr('string') declare languageCode: string;
  @attr('string') declare linkedinUrl: string;
  @attr('string') declare name: string;
  @attr('string') declare nrEmployees: string;
  @attr('string') declare status: string;
  @attr('string') declare turnover: string;
  @attr('string') declare twitterUrl: string;
  @attr('string') declare url: string;
  @attr('string') declare website: string;

  @attr('number') declare cloneFromId: number;
  @attr('number') declare companyId: number;

  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;

  @attr('boolean') declare hasOtherCareerSites: boolean;
  @attr('boolean') declare isDefault: boolean;

  get isPublished() {
    return this.status === STATUS_PUBLISHED;
  }

  get isDraft() {
    return this.status === STATUS_DRAFT;
  }

  get isBuilding() {
    return this.status === STATUS_BUILDING;
  }

  get translatedLanguage() {
    return this.intl.t(`language_codes.${this.languageCode}`);
  }

  async promoteAllChanges() {
    return this.server.memberAction(this, { action: 'promote_all_changes' });
  }

  async changeStatus() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'change_status',
    });
    this.store.pushPayload(response);
  }

  async updateCompanyInfo() {
    const {
      about,
      facebook_url,
      footer_layout,
      founded_in,
      instagram_url,
      linkedin_url,
      nr_employees,
      turnover,
      twitter_url,
      website,
    } = this.serialize() as SerializedCareerSite;

    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'update_company_info',
      method: 'PUT',
      options: {
        data: JSON.stringify({
          career_site: {
            about,
            facebook_url,
            footer_layout,
            founded_in,
            instagram_url,
            linkedin_url,
            nr_employees,
            turnover,
            twitter_url,
            website,
          },
        }),
      },
    });

    return this.store.pushPayload(response);
  }

  async makeDefault() {
    const response = await this.server.memberAction<PushPayloadArg>(this, {
      action: 'make_default',
    });
    return this.store.pushPayload(response);
  }

  async availablePagePublications() {
    return this.server.memberAction(this, {
      action: 'available_page_publications',
      method: 'GET',
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'career-site': CareerSiteModel;
  }
}
