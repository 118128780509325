import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import IntlService from 'ember-intl/services/intl';
import RouterService from '@ember/routing/router-service';
import { get } from 'teamtailor/utils/get';
import { allSettled, task } from 'ember-concurrency';
import FlashMessageService from 'teamtailor/services/flash-message';
import Current from 'teamtailor/services/current';
import TranslationRoleModel from 'teamtailor/models/translation/role';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import SettingsOrganizationRolesEditRoute from 'teamtailor/routes/settings/organization/roles/edit';
import TtAlertService from 'teamtailor/services/tt-alert';
import { PickedQuestionModel, QuestionModel } from 'teamtailor/models';
import applyDefaultPickedQuestionValues from 'teamtailor/utils/apply-default-picked-question-values';

export default class SettingsOrganizationRolesEditController extends Controller {
  declare model: ModelFrom<SettingsOrganizationRolesEditRoute>;

  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessageService;
  @service declare current: Current;
  @service declare ttAlert: TtAlertService;

  get defaultLanguageCode() {
    const careerSite = get(this.current.company, 'defaultCareerSite');
    return get(careerSite, 'languageCode');
  }

  saveRole = task(async () => {
    try {
      const model = await this.model.save();
      get(model, 'pickedQuestions')
        .filter((pickedQuestion) => pickedQuestion.isNew)
        .forEach((pickedQuestion) => pickedQuestion.unloadRecord);
      return model;
    } catch {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject();
    }
  });

  saveTranslation = task(async (model: TranslationRoleModel) => {
    return model.save();
  });

  @action
  removeQuestion(question: PickedQuestionModel) {
    question.deleteRecord();
  }

  @action
  pickQuestion(question: QuestionModel) {
    const pickedQuestions = get(this.model, 'pickedQuestions');

    pickedQuestions.forEach((question, index) =>
      set(question, 'rowOrderPosition', index)
    );

    get(this.model, 'pickedQuestions').createRecord(
      applyDefaultPickedQuestionValues({
        question,
        rowOrderPosition: pickedQuestions.length,
      })
    );
  }

  @action
  async handleSave() {
    await this.saveRole.perform();

    // Note: We must exclude default language from translations or else the previously updated page publication will be overwrittena
    const translationTasks = get(this.model, 'translations')
      .filter((t) => t.languageCode !== this.defaultLanguageCode)
      .map((t) => {
        return this.saveTranslation.perform(t);
      });

    const result = await allSettled(translationTasks);

    if (result.some((task) => task.state === 'rejected')) {
      this.flashMessages.error(this.intl.t('common.something_went_wrong'));
      return Promise.reject();
    } else {
      this.flashMessages.success(this.intl.t('settings.roles.role_saved'));
      this.router.transitionTo('settings.organization.roles.index');
      return Promise.resolve();
    }
  }

  @action
  handleDestroy() {
    this.ttAlert.confirm(
      this.intl.t('settings.roles.confirm_deleting_role'),
      () => {
        this.model
          .destroyRecord()
          .then(() => {
            get(this, 'flashMessages').success(
              this.intl.t('settings.roles.role_deleted')
            );
            this.router.transitionTo('settings.organization.roles.index');
          })
          .catch(() => {
            this.flashMessages.error(
              this.intl.t('common.something_went_wrong')
            );
          });
      }
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'settings.organization.roles.edit': SettingsOrganizationRolesEditController;
  }
}
