import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { get } from 'teamtailor/utils/get';
import { tracked } from '@glimmer/tracking';

export default class JobOfferBaseController extends Controller {
  @service current;
  @service intl;
  @service modal;
  @service ttAlert;
  @service store;

  @tracked fields = [];

  @tracked originalJobOfferApprovalFlowSteps =
    get(this.model, 'jobOfferApprovalFlow.steps') ?? [];

  get company() {
    return this.current.company;
  }

  get jobOffer() {
    return this.model;
  }

  get jobOfferApprovalsEnabled() {
    return get(this.company, 'jobOfferApprovalsEnabled');
  }

  get buttonsDisabled() {
    return !(
      this.haveAnswers(this.requiredFields) &&
      this.haveValues() &&
      get(this.jobOffer, 'jobOfferForm.introText') &&
      this.jobOffer.jobApplication.isTruthy &&
      this.haveFormOptionsValue &&
      this.haveQuestionLabels
    );
  }

  get requiredFields() {
    return this.fields.filter((field) => field.required);
  }

  get haveFormOptionsValue() {
    return (
      get(this.jobOffer, 'jobOfferForm.options.currency') &&
      get(this.jobOffer, 'jobOfferForm.options.salaryTimeUnit')
    );
  }

  get haveQuestionLabels() {
    return this.fields.every((field) => field.label.trim() !== '');
  }

  getValue(answer) {
    return (
      answer.textValue ||
      answer.numericValue ||
      answer.dateValue ||
      answer.arrayValue?.length > 0
    );
  }

  haveAnswers(fields) {
    return fields.map((field) => field.answers.length > 0).every(Boolean);
  }

  haveValues() {
    return this.requiredFields
      .map((field) => this.getValue(field.answers[0]))
      .every(Boolean);
  }

  approvalFlowCriteriaParams(jobApplication, jobOffer) {
    const salary = get(jobOffer, 'jobOfferForm.options.salary');
    return jobApplication
      ? {
          country: get(jobApplication, 'job.locations').map((l) => l.country),
          location_id: get(jobApplication, 'job.locations').map((l) => l.id),
          department_id: get(jobApplication, 'job.department.id'),
          role_id: get(jobApplication, 'job.role.id'),
          ...(salary
            ? {
                max_salary: salary,
                currency: get(jobOffer, 'jobOfferForm.options.currency'),
                salary_time_unit: get(
                  jobOffer,
                  'jobOfferForm.options.salaryTimeUnit'
                ),
              }
            : {}),
        }
      : {};
  }

  async setApprovalFlow(jobApplication, jobOffer) {
    jobApplication = jobApplication || this.jobOffer.jobApplication;
    jobOffer = jobOffer || this.jobOffer;

    let query = { is_default: true };
    const criteriaParams = this.approvalFlowCriteriaParams(
      jobApplication,
      jobOffer
    );

    if (
      isPresent(get(jobApplication, 'id')) &&
      isPresent(Object.values(criteriaParams).filter((v) => v && v.length > 0))
    ) {
      query = criteriaParams;
    }

    const flows = await this.store.query('job-offer-approval-flow', query);
    set(jobOffer, 'jobOfferApprovalFlow', flows.firstObject);
  }

  @action
  onSendOffer() {
    this.ttAlert.confirm(
      this.intl.t('components.candidate_modal.job_offers.item.confirm_send', {
        candidate: get(this.jobOffer, 'candidate.nameOrEmail'),
      }),
      () => {
        this.sendOffer();
      },
      () => {},
      {
        confirmButtonClass: 'btn-primary',
      }
    );
  }

  @action
  onClose() {
    if (this.limitedView) {
      this.router.transitionTo('dashboard');
    }

    this.modal.closeCandidateModal(get(this.jobOffer, 'candidate.id'));
  }
}
