import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Button\n  @tooltip={{if this.inDarkMode \"Switch to light mode\" \"Switch to dark mode\"}}\n  @appearance=\"secondary\"\n  @icon={{if this.inDarkMode \"moon\" \"sun-bright\"}}\n  {{on \"click\" this.onToggleTheme}}\n  class=\"fixed right-24 top-24\"\n/>", {"contents":"<Button\n  @tooltip={{if this.inDarkMode \"Switch to light mode\" \"Switch to dark mode\"}}\n  @appearance=\"secondary\"\n  @icon={{if this.inDarkMode \"moon\" \"sun-bright\"}}\n  {{on \"click\" this.onToggleTheme}}\n  class=\"fixed right-24 top-24\"\n/>","moduleName":"teamtailor/components/unicorn/theme.hbs","parseOptions":{"srcName":"teamtailor/components/unicorn/theme.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

export default class UnicornTheme extends Component {
  @tracked inDarkMode = false;

  colorSchemeMediaQueryList?: MediaQueryList;

  constructor(owner: unknown, args: never) {
    super(owner, args);

    if (
      window.matchMedia('(prefers-color-scheme: dark)').matches &&
      !this.inDarkMode
    ) {
      next(this.toggleTheme.bind(this));
    }
  }

  toggleTheme(): void {
    const body = document.querySelector('body');
    if (this.inDarkMode) {
      body?.classList.remove('theme-dark', 'dark');
      body?.classList.add('theme-light');
    } else {
      body?.classList.remove('theme-light');
      body?.classList.add('theme-dark', 'dark');
    }

    this.inDarkMode = !this.inDarkMode;
  }

  @action
  onToggleTheme() {
    this.toggleTheme();
  }
}
