import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { ModelFrom } from 'teamtailor/utils/type-utils';
import FromRoute from 'teamtailor/routes/employees/profile/security';
import Current from 'teamtailor/services/current';
import { action } from '@ember/object';
import TtAlertService from 'teamtailor/services/tt-alert';
import IntlService from 'ember-intl/services/intl';
import { get } from 'teamtailor/utils/get';
import FlipperService from 'teamtailor/services/flipper';

export default class EmployeesProfileSecurity extends Controller {
  declare model: ModelFrom<FromRoute>;

  @service declare current: Current;
  @service declare ttAlert: TtAlertService;
  @service declare intl: IntlService;
  @service declare flipper: FlipperService;

  get login() {
    return get(this.model, 'login');
  }

  get showTwoFactorAuthenticationEnforcedWarning(): boolean {
    const { twoFactorAuthenticationEnforced } = this.current.company;
    const twoFactorActivated = get(this.login, 'twoFactorActivated');

    return twoFactorAuthenticationEnforced && !twoFactorActivated;
  }

  get isCurrentUser(): boolean {
    return this.current.user.id === this.model.id;
  }

  get isCompanyAdmin(): boolean {
    return this.current.user.admin;
  }

  get supportUrl(): string {
    return 'https://support.teamtailor.com/en/articles/6730339-login-security-and-password-requirements';
  }

  @action
  disable2FA(): void {
    this.ttAlert.confirm(
      this.intl.t('employees.security.two_fa_disable.chrome.action_disable'),
      async () => {
        const login = await this.model.login;
        if (login) {
          await login.disableTwoFactorAuthentication();
        }
      }
    );
  }
}

declare module '@ember/controller' {
  interface Registry {
    'employees.profile.security': EmployeesProfileSecurity;
  }
}
