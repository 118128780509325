import { get } from 'teamtailor/utils/get';
import ApplicationInstance from '@ember/application/instance';
import moment, { Moment } from 'moment-timezone';
import round from 'teamtailor/utils/round';
import { inject as service } from '@ember/service';
import ReportAnalyticsRequest, {
  FetchOptions,
  getClickhousePageviewsTransitionDate,
} from './report-analytics-request';
import { gql } from '@apollo/client/core';
import { percentageOf } from 'teamtailor/utils/analytics';
import IntlService from 'ember-intl/services/intl';
import {
  AnalyticsReportBuilder,
  ComparableReport,
  BuildReportArgs,
} from './analytics-report-builder';
import {
  EventTypeResponse,
  GoogleAnalyticsQueryResponse,
  GoogleAnalyticsVisitTypeResponse,
  PageviewTypeResponse,
} from 'teamtailor/utils/insights/graphql-response-types';
import DateRange from 'teamtailor/utils/date-range';

const INSIGHTS_GA_QUERY = gql`
  query GoogleAnalyticsVisitorsQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $onlyJobAds: Boolean
    $companyIds: [ID!]
  ) {
    googleAnalyticsQuery {
      visits(
        dateRange: $dateRange
        jobIds: $jobIds
        onlyJobAds: $onlyJobAds
        companyIds: $companyIds
      ) {
        date
        year
        month
        day
        isoWeek
        isoYearIsoWeek
        sessions
        pageviews
        applications: numberOfApplications
        connections: numberOfConnections
        jobApplicationCandidateIds: appliedCandidateIds
      }
    }
  }
`;

const INSIGHTS_APPLICATIONS_AND_CONNECTIONS_PARTIAL_QUERY = `
eventQuery(
  dateRange: $dateRange
  jobIds: $jobIds
  eventTypes: [APPLIED, CONNECTED]
  companyIds: $companyIds
) {
  aggregated(groupBy: [DATE]) {
    date
    applications: countOccurrences(
      filters: { eventType: { equals: APPLIED } }
    )
    connections: countOccurrences(
      filters: { eventType: { equals: CONNECTED } }
    )
    jobApplicationCandidateIds: collect(
      field: CANDIDATE_ID
      filters: { eventType: { equals: APPLIED } }
    )
  }
}
`;

const insightsPageviewsQueryGenerator = (
  onlyVisits: boolean | undefined
) => gql`
  query VisitorsQuery(
    $dateRange: DateRangeAttributes!
    $jobIds: [ID!]
    $filters: [PageviewFilterAttributes!]
    $companyIds: [ID!]
  ) {
    pageviewQuery(dateRange: $dateRange, jobIds: $jobIds, filters: $filters, companyIds: $companyIds) {
      aggregated(groupBy: [DATE]) {
        date
        year
        month
        day
        isoWeek
        isoYearIsoWeek
        count
        distinctCount(field: SESSION_ID)
      }
    }
    ${onlyVisits ? '' : INSIGHTS_APPLICATIONS_AND_CONNECTIONS_PARTIAL_QUERY}
  }
`;

interface EventDataResponse extends EventTypeResponse {
  applications: number;
  connections: number;
  jobApplicationCandidateIds: string[];
}

interface Row extends GoogleAnalyticsVisitTypeResponse {
  conversionRate: number;
}

const formatInsightsData = (
  pageviews: PageviewTypeResponse[] = [],
  eventData: EventDataResponse[] = []
): GoogleAnalyticsVisitTypeResponse[] => {
  if (!pageviews.length) return [];

  const eventsByDate = eventData.reduce(
    (
      result: {
        [key: string]: {
          applications: number;
          connections: number;
          jobApplicationCandidateIds: string[];
        };
      },
      { date, applications, connections, jobApplicationCandidateIds }
    ) => {
      result[date] = {
        applications,
        connections,
        jobApplicationCandidateIds,
      };
      return result;
    },
    {}
  );

  const rows = pageviews.map((row) => {
    const {
      date,
      year,
      month,
      day,
      isoWeek,
      isoYearIsoWeek,
      count,
      distinctCount,
    } = row;
    const eventDate = eventsByDate[date] || {
      applications: 0,
      connections: 0,
      jobApplicationCandidateIds: [],
    };

    return {
      date,
      year,
      month,
      day,
      isoWeek,
      isoYearIsoWeek,
      pageviews: count,
      sessions: distinctCount,
      applications: eventDate.applications,
      jobApplicationCandidateIds: eventDate.jobApplicationCandidateIds,
      connections: eventDate.connections,
    };
  });

  return rows;
};

function groupByIsoYearIsoWeek(array: GoogleAnalyticsVisitTypeResponse[]) {
  const groupedResult: {
    [key: number]: GoogleAnalyticsVisitTypeResponse[];
  } = {};

  for (const item of array) {
    const p = item.isoYearIsoWeek;

    if (groupedResult[p] && Array.isArray(groupedResult[p])) {
      groupedResult[p]!.push(item);
    } else {
      groupedResult[p] = [item];
    }
  }

  return groupedResult;
}

function reduceRows(rows: [GoogleAnalyticsVisitTypeResponse]) {
  const [firstRow] = rows;

  return rows
    .slice(1)
    .reduce((result: GoogleAnalyticsVisitTypeResponse, row) => {
      return {
        ...row,
        jobApplicationCandidateIds: result.jobApplicationCandidateIds.concat(
          row.jobApplicationCandidateIds
        ),

        applications: result.applications + row.applications,
        connections: result.connections + row.connections,
        pageviews: result.pageviews + row.pageviews,
        sessions: result.sessions + row.sessions,
      };
    }, firstRow);
}

interface AudienceVisitorsReportArgs {
  container: ApplicationInstance;
  _rows?: GoogleAnalyticsVisitTypeResponse[];
  compareModel?: AudienceVisitorsReport;
  dateRange?: DateRange;
}

export class AudienceVisitorsReport implements ComparableReport {
  @service declare intl: IntlService;

  container!: ApplicationInstance;
  _rows: GoogleAnalyticsVisitTypeResponse[] = [];
  compareModel?: AudienceVisitorsReport;
  dateRange!: DateRange;

  constructor(args: AudienceVisitorsReportArgs) {
    Object.assign(this, args);
  }

  get rows(): Row[] {
    const rows = this._rows.map((visit) => {
      const { sessions, pageviews, applications, connections } = visit;
      const date = moment([visit.year, visit.month - 1, visit.day!]);

      return {
        ...visit,
        date: date.format('YYYY-MM-DD'),
        sessions,
        pageviews,
        applications,
        connections,
        jobApplicationCandidateIds: visit.jobApplicationCandidateIds,
        conversionRate: applications / sessions,
      };
    });

    if (rows.length === 0) {
      return rows;
    }

    return this.allDates.reduce((result, date) => {
      if (!result.find((row) => row.date === date)) {
        const dateMoment = moment(date);
        result.push({
          date,
          year: dateMoment.year(),
          month: dateMoment.month() + 1,
          day: dateMoment.date(),
          isoWeek: dateMoment.isoWeek(),
          isoYearIsoWeek: parseInt(
            `${dateMoment.year()}${dateMoment.isoWeek()}`
          ),

          sessions: 0,
          pageviews: 0,
          applications: 0,
          connections: 0,
          jobApplicationCandidateIds: [],
          conversionRate: 0,
        });
      }

      return result;
    }, rows);
  }

  get graphRows() {
    if (this.totalVisits === 0) {
      return [];
    }

    let rows = [...this.rows];
    const numberOfRows = rows.length;

    // Transform it to weeks if there's more than 3 months since Insights
    // always returns daily data
    if (numberOfRows > 91) {
      const rowsGroupedByWeek = groupByIsoYearIsoWeek(rows);

      rows = Object.entries(rowsGroupedByWeek)
        .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
        .map(([, value]) => value)
        .map((rowsByWeek) => ({
          ...reduceRows(rowsByWeek as [GoogleAnalyticsVisitTypeResponse]),
          day: undefined,
          conversionRate: 0,
        }));
    }

    return rows
      .map((visit) => {
        const { sessions } = visit;

        const { applications, connections } = visit;

        let date: Moment | undefined, year: number | undefined;

        if (visit.day) {
          date = moment([visit.year, visit.month - 1, visit.day]);
        } else {
          year =
            visit.isoWeek > 51 && visit.month === 1
              ? visit.year - 1
              : visit.year;

          date = moment().year(year).isoWeek(visit.isoWeek).isoWeekday(1);
        }

        return {
          date: date.format('YYYY-MM-DD'),
          timestamp: date.valueOf(),
          sessions,
          pageviews: visit.pageviews,
          applications,
          connections,
          conversionRate: round((applications / sessions) * 100, true),
        };
      })
      .sort(function (a, b) {
        return a.timestamp - b.timestamp;
      });
  }

  get chartOptions(): { [key: string]: any } {
    return {
      chart: {
        type: 'areaspline',
      },

      yAxis: {
        title: {
          text: null,
        },
      },

      xAxis: {
        categories: get(this, 'dates'),
      },

      legend: {
        enabled: true,
        align: 'center',
        x: 0,
      },
    };
  }

  get dates() {
    return this.graphRows.map((row) => row.date);
  }

  get graphRowApplications() {
    return this.graphRows.map((row) => row.applications);
  }

  get applications() {
    return this.rows.map((row) => row.applications);
  }

  get totalApplications() {
    return this.applications.reduce(
      (sum, applications) => sum + applications,
      0
    );
  }

  get previousTotalApplications() {
    return this.compareModel?.totalApplications || 0;
  }

  get _connections() {
    return this.rows.map((row) => row.connections);
  }

  get connections() {
    return this._connections.reduce((sum, connections) => sum + connections, 0);
  }

  get allDates() {
    return this.dateRange.dateSpan.map((date) => date.format('YYYY-MM-DD'));
  }

  get graphRowVisits() {
    return this.graphRows.map((row) => row.sessions);
  }

  get visits() {
    return this.rows.map((row) => row.sessions);
  }

  get totalVisits() {
    return this.visits.reduce((sum, visits) => sum + visits, 0);
  }

  get previousTotalVisits() {
    return this.compareModel?.totalVisits || 0;
  }

  get totalPageviews() {
    return this.rows.reduce((sum: number, row) => sum + row.pageviews, 0);
  }

  get conversionRate() {
    return this.totalVisits > 0
      ? percentageOf(this.totalApplications, this.totalVisits)
      : undefined;
  }

  get previousConversionRate() {
    return this.compareModel?.conversionRate;
  }
}

interface VisitorsFetchOptions extends FetchOptions {
  ignoreJobsFilter?: boolean;
  onlyJobAds?: boolean;
  onlyVisits?: boolean;
  dateRange?: DateRange;
}

interface ExtraCreateReportProps {
  dateRange?: DateRange;
}

export function buildReport(reportArgs: BuildReportArgs) {
  const { container, options, compareOptions } = reportArgs;
  return new AnalyticsReportBuilder<
    AudienceVisitorsReport,
    GoogleAnalyticsVisitTypeResponse[]
  >(container, {
    query: async (options: VisitorsFetchOptions = {}) => {
      const flipper = container.lookup('service:flipper');
      const analytics = container.lookup('service:analytics');

      const transitionDate = getClickhousePageviewsTransitionDate(flipper);

      let { onlyJobAds, onlyVisits, ignoreJobsFilter, dateRange } = options;

      if (!dateRange) {
        dateRange = new DateRange(
          analytics.startDateParam,
          analytics.endDateParam
        );
      }

      const googleAnalyticsRows = await new ReportAnalyticsRequest({
        container,
        query: INSIGHTS_GA_QUERY,
        extraVariables: {
          onlyJobAds: !!onlyJobAds,
        },

        callback: (result?: {
          googleAnalyticsQuery: GoogleAnalyticsQueryResponse;
        }) => result?.googleAnalyticsQuery.visits || [],
      }).fetch({
        before: transitionDate,
        dateRange,
        ignoreJobsFilter,
      });

      const insightsData = await new ReportAnalyticsRequest({
        container,
        query: insightsPageviewsQueryGenerator(onlyVisits),
        extraVariables: {
          filters: onlyJobAds
            ? { path: { match: '(/jobs/|/jobs/internal/)([a-z0-9-]*)$' } }
            : null,
        },

        callback: (result) => result,
      }).fetch({ after: transitionDate, dateRange, ignoreJobsFilter });

      const clickhouseRows = formatInsightsData(
        insightsData?.pageviewQuery?.aggregated,
        insightsData?.eventQuery?.aggregated
      );

      return [...googleAnalyticsRows, ...clickhouseRows];
    },

    queryOptions: options,
    compareOptions,
    createReport: (queryResult, extraProps?: ExtraCreateReportProps) => {
      return new AudienceVisitorsReport({
        container,
        _rows: queryResult,
        dateRange: extraProps?.dateRange,
      });
    },
  });
}
