import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <h5 class=\"ml-16\">\n    <button\n      type=\"button\"\n      class=\"flex w-full -outline-offset-2 focus-visible:rounded focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus\"\n      aria-expanded={{this.open}}\n      {{on \"click\" this.handleSubMenuClick}}\n    >\n      <span class=\"flex w-full text-start\">\n        {{@text}}\n      </span>\n      {{#unless @hideButton}}\n        <Icon\n          @icon={{if this.open \"angle-up\" \"angle-down\"}}\n          class=\"text-icon-neutral-weak\"\n        />\n      {{/unless}}\n    </button>\n  </h5>\n  {{#if (or this.open @hideButton)}}\n    <ul class=\"mt-12 flex flex-col gap-4\">\n      {{yield}}\n    </ul>\n  {{/if}}\n</div>", {"contents":"<div>\n  <h5 class=\"ml-16\">\n    <button\n      type=\"button\"\n      class=\"flex w-full -outline-offset-2 focus-visible:rounded focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus\"\n      aria-expanded={{this.open}}\n      {{on \"click\" this.handleSubMenuClick}}\n    >\n      <span class=\"flex w-full text-start\">\n        {{@text}}\n      </span>\n      {{#unless @hideButton}}\n        <Icon\n          @icon={{if this.open \"angle-up\" \"angle-down\"}}\n          class=\"text-icon-neutral-weak\"\n        />\n      {{/unless}}\n    </button>\n  </h5>\n  {{#if (or this.open @hideButton)}}\n    <ul class=\"mt-12 flex flex-col gap-4\">\n      {{yield}}\n    </ul>\n  {{/if}}\n</div>","moduleName":"teamtailor/components/settings/collapsible-section.hbs","parseOptions":{"srcName":"teamtailor/components/settings/collapsible-section.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface CollapsibleSectionArgs {
  text: string;
  open: boolean | undefined;
}

export default class CollapsibleSectionComponent extends Component<CollapsibleSectionArgs> {
  @tracked open = false;

  constructor(owner: unknown, args: CollapsibleSectionArgs) {
    super(owner, args);
    this.open = this.args.open ?? false;
  }

  @action
  handleSubMenuClick() {
    this.open = !this.open;
  }
}
