import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import SettingsOrganizationRoleEditController from 'teamtailor/controllers/settings/organization/roles/edit';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { get } from 'teamtailor/utils/get';

interface Params {
  role_id: string;
}

export default class SettingsOrganizationRoleEditRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model(params: Params) {
    return this.store.findRecord('role', params.role_id);
  }

  resetController(
    controller: SettingsOrganizationRoleEditController,
    isExiting: boolean
  ) {
    if (isExiting && !get(controller.model, 'isDeleted')) {
      get(controller.model, 'pickedQuestions')
        .toArray()
        .invoke('rollbackAttributes');

      if (this.current.company.hasFeature('career_site_languages')) {
        get(controller.model, 'translations')
          .toArray()
          .invoke('rollbackAttributes');
      }

      controller.model.rollbackAttributes();
    }
  }
}
