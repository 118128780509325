import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import { getClickhousePageviewsTransitionDate } from 'teamtailor/classes/analytics/report-analytics-request';
import { isEmpty } from '@ember/utils';
import CsvFileExporter from 'teamtailor/utils/csv-file-exporter';
import colors, {
  primaryColor,
} from 'teamtailor/components/insights/charts/colors';

const COLUMN_CONFIGURATION_FOR_EXPORT = [
  {
    type: 'text',
    propertyName: 'date',
    headerKey: 'common.date',
  },
  {
    type: 'text',
    propertyName: 'visits',
    headerKey: 'reports.visits',
  },
  {
    type: 'text',
    propertyName: 'pageviews',
    headerKey: 'reports.pageviews',
  },
  {
    type: 'text',
    propertyName: 'connections',
    headerKey: 'reports.connections',
  },
  {
    type: 'text',
    propertyName: 'applications',
    headerKey: 'reports.applications',
  },
  {
    type: 'text',
    propertyName: 'conversionRate',
    headerKey: 'reports.conversion_rate',
  },
  {
    type: 'text',
    propertyName: 'jobApplicationCandidateIds',
    headerKey: 'reports.applicants',
  },
];

export default class VisitorsController extends Controller {
  @service reports;
  @service analytics;
  @service flipper;
  @service intl;

  @tracked sortDirection = 'desc';
  @tracked sortProperty = 'date';
  @tracked chartWidth;
  @tracked chartHeight;

  graphColor = primaryColor;

  get transitionDate() {
    return getClickhousePageviewsTransitionDate(this.flipper);
  }

  get data() {
    return this.model.visitors.value?.graphRowVisits;
  }

  get dates() {
    return this.model.visitors.value?.dates;
  }

  get visits() {
    return this.model.visitors.value?.rows;
  }

  get startDate() {
    return this.dates?.at(0);
  }

  get endDate() {
    return this.dates?.at(-1);
  }

  get allStats() {
    return [
      this.totalVisitsStats,
      this.totalPageviewsStats,
      this.totalApplicationsStats,
      this.conversionRateStats,
    ];
  }

  get totalVisitsStats() {
    return {
      value: this.model.visitors.value.totalVisits,
      title: this.intl.t('reports.visits'),
      infoTitle: this.intl.t('reports.visits'),
      infoText: this.intl.t('reports.visits_help_text'),
    };
  }

  get totalPageviewsStats() {
    return {
      value: this.model.visitors.value.totalPageviews,
      title: this.intl.t('reports.pageviews'),
      infoTitle: this.intl.t('reports.pageviews'),
      infoText: this.intl.t('reports.pageviews_help_text'),
    };
  }

  get totalApplicationsStats() {
    return {
      value: this.model.visitors.value.totalApplications,
      title: this.intl.t('reports.applicants'),
    };
  }

  get conversionRateStats() {
    return {
      value: this.model.visitors.value.conversionRate,
      title: this.intl.t('reports.conversion_rate'),
      isPercentage: true,
    };
  }

  get formattedTransitionDate() {
    return moment(this.transitionDate).format('D MMM YYYY');
  }

  get showTransitionNote() {
    const { startDate, endDate } = this.analytics;
    return (
      moment(this.transitionDate).isBetween(startDate, endDate) ||
      moment(this.transitionDate).isSame(endDate)
    );
  }

  get chartOptions() {
    const { startDate, endDate } = this.analytics;
    const startX = moment(startDate).valueOf();
    const endX = moment(endDate).valueOf();

    let _this = this;
    const updateSize = function () {
      if (
        _this.chartWidth !== this.clipBox.width ||
        _this.chartHeight !== this.clipBox.height
      ) {
        _this.chartWidth = this.clipBox.width;
        _this.chartHeight = this.clipBox.height;
        this.redraw();
      }
    };

    let options = {
      chart: {
        type: 'areaspline',
        events: {
          redraw() {
            updateSize.call(this);
          },

          load() {
            updateSize.call(this);
          },
        },
      },

      yAxis: {
        title: {
          text: null,
        },
      },

      xAxis: {
        type: 'datetime',
        min: startX,
        max: endX,
      },

      legend: {
        enabled: true,
        align: 'center',
        x: 0,
      },
    };

    if (this.showTransitionNote) {
      const calculateAnnotationX = () => {
        const transitionX = moment(this.transitionDate).valueOf();
        const width = endX - startX;
        return (this.chartWidth * (transitionX - startX)) / width;
      };

      options = {
        ...options,
        tooltip: {
          style: {
            width: 300,
          },

          formatter() {
            let htmlString = `<tspan style="font-size: 10px">${this.point.name}</tspan><br />`;

            // Don't show series name and value for the extra serie, which is only for displaying the footer
            if (this.series.name !== '') {
              htmlString += `<tspan style="fill:${this.color}">●</tspan> ${this.series.name}: <strong>${this.point.y}</strong><br />`;
            }

            if (!isEmpty(this.point.options.footer)) {
              htmlString += `<br /><span>${this.point.options.footer}</span>`;
            }

            return htmlString;
          },
        },

        annotations: [
          {
            draggable: '',

            shapes: [
              {
                dashStyle: 'ShortDash',
                type: 'path',
                fill: 'none',
                strokeWidth: 1.5,
                stroke: '#78868E',
                points: [
                  function () {
                    return {
                      x: calculateAnnotationX(),
                      y: 0,
                    };
                  },
                  function () {
                    return {
                      x: calculateAnnotationX(),
                      y: _this.chartHeight,
                    };
                  },
                ],
              },
            ],
          },
        ],
      };
    }

    return options;
  }

  get chartData() {
    const graphRows = this.model.visitors.value?.graphRows;
    if (!graphRows) {
      return;
    }

    let extraData = [];
    if (this.showTransitionNote) {
      const footer = this.intl.t('reports.old_pageview_info_tooltip', {
        date: this.formattedTransitionDate,
      });
      const hasDataForDate = !!this.visitsChartData.find(
        (row) => row.name === this.transitionDate && row.y > 0
      );

      if (!hasDataForDate) {
        const maxValue = graphRows.reduce((max, row) => {
          const val = Math.max(row.sessions, row.applications);
          return val > max ? val : max;
        }, 0);

        extraData.push({
          name: '',
          color: 'rgba(0,0,0,0)',
          fillColor: 'rgba(0,0,0,0)',
          data: [
            {
              name: this.transitionDate,
              x: moment(this.transitionDate).valueOf(),
              y: maxValue / 2,
              footer,
            },
          ],
        });
      }
    }

    return [
      {
        name: this.intl.t('reports.visits'),
        color: colors[0],
        fillColor: colors[0],
        data: this.insertMissingFooter(this.visitsChartData),
      },
      {
        name: this.intl.t('reports.applications'),
        color: colors[1],
        fillColor: colors[1],
        data: this.insertMissingFooter(
          graphRows.map((row) => ({
            name: row.date,
            x: moment(row.date).valueOf(),
            y: row.applications,
          }))
        ),
      },
      ...extraData,
    ];
  }

  get visitsChartData() {
    return this.model.visitors.value?.graphRows.map((row) => ({
      name: row.date,
      x: row.timestamp,
      y: row.sessions,
    }));
  }

  get sortedVisits() {
    const { sortDirection, sortProperty, visits } = this;
    const sortedVisits = visits.sortBy(sortProperty);
    return sortDirection === 'desc' ? sortedVisits.reverse() : sortedVisits;
  }

  insertMissingFooter(data) {
    if (this.showTransitionNote) {
      let transitionDate = data.findBy('name', this.transitionDate);
      if (transitionDate) {
        transitionDate.footer = this.intl.t(
          'reports.old_pageview_info_tooltip',
          {
            date: this.formattedTransitionDate,
          }
        );
      }
    }

    return data;
  }

  rowsToExport() {
    return this.sortedVisits.map((row) => ({
      date: moment(new Date(row.year, row.month - 1, row.day)).format(
        'YYYY-MM-DD'
      ),

      applications: row.applications,
      connections: row.connections,
      jobApplicationCandidateIds: row.jobApplicationCandidateIds,
      visits: this.analytics.jobId ? row.uniquePageviews : row.sessions,
      pageviews: row.pageviews,
      conversionRate: (row.conversionRate * 100).toFixed(2),
    }));
  }

  @action
  handleExport() {
    new CsvFileExporter(
      this.rowsToExport(),
      COLUMN_CONFIGURATION_FOR_EXPORT,
      this.intl
    ).downloadFile({
      baseFileName: 'visitors',
      startDate: this.analytics.startDate,
      endDate: this.analytics.endDate,
    });
  }

  @action
  handleSort(sortProperty) {
    if (this.sortProperty === sortProperty) {
      const nextDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      this.sortDirection = nextDirection;
    } else {
      this.sortDirection = 'desc';
      this.sortProperty = sortProperty;
    }
  }
}
