import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import AnalyticsService from 'teamtailor/services/analytics';

export default class AnalyticsOverviewRejectReasonsController extends Controller {
  @service declare analytics: AnalyticsService;
}

declare module '@ember/controller' {
  interface Registry {
    'analytics.overview.reject-reasons': AnalyticsOverviewRejectReasonsController;
  }
}
