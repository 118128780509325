import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<EmberSmooth::If::Glimmer\n  @predicate={{@predicate}}\n  @mode={{@mode}}\n  @devJumpCheck={{@devJumpCheck}}\n  @awaitIn={{@awaitIn}}\n  @debug={{@debug}}\n  @onRegister={{@onRegister}}\n  @hasElseBlock={{has-block \"else\"}}\n  @delayWhenClipping={{@delayWhenClipping}}\n  class={{@class}}\n  ...attributes\n  as |smoothIf|\n>\n  {{#if smoothIf.predicate}}\n    {{yield smoothIf}}\n  {{else}}\n    {{yield smoothIf to=\"else\"}}\n  {{/if}}\n</EmberSmooth::If::Glimmer>", {"contents":"<EmberSmooth::If::Glimmer\n  @predicate={{@predicate}}\n  @mode={{@mode}}\n  @devJumpCheck={{@devJumpCheck}}\n  @awaitIn={{@awaitIn}}\n  @debug={{@debug}}\n  @onRegister={{@onRegister}}\n  @hasElseBlock={{has-block \"else\"}}\n  @delayWhenClipping={{@delayWhenClipping}}\n  class={{@class}}\n  ...attributes\n  as |smoothIf|\n>\n  {{#if smoothIf.predicate}}\n    {{yield smoothIf}}\n  {{else}}\n    {{yield smoothIf to=\"else\"}}\n  {{/if}}\n</EmberSmooth::If::Glimmer>","moduleName":"teamtailor/components/ember-smooth/if.hbs","parseOptions":{"srcName":"teamtailor/components/ember-smooth/if.hbs"}});
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
class SmoothIf extends Component {
  tagName = '';
  static positionalParams = ['predicate'];
}

export default SmoothIf;
