import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

export default class SettingsExternalRecruitmentRecruitingFirmsRoute extends ScrollToTopRoute {
  @service declare current: Current;

  model() {
    return get(this.current.company, 'recruitingFirms');
  }
}
