export interface ColorOption {
  color: string;
  variant?: string;
  icon?: string;
  isDecorative?: boolean;
  isStandard?: boolean;
}

export function gradientBackgroundStyle(colors: ColorOption[]) {
  const sliceCount = colors.length;
  const sliceAngle = 360 / sliceCount;

  let gradientString = colors
    .map((option, index) => {
      let colorVar = option.color;

      if (!option.isStandard) {
        const prefixText = option.isDecorative ? '-decorative' : '';
        const variantText = option.variant ? `-${option.variant}` : '';

        colorVar = `var(--color-background${prefixText}-${option.color}${variantText})`;
      }

      const rotation = (multiplier: number) => {
        return multiplier * sliceAngle;
      };

      return `${colorVar} ${rotation(index)}deg ${rotation(index + 1)}deg`;
    })
    .join(', ');

  return `background: conic-gradient(${gradientString});`;
}
