import IntlService from 'ember-intl/services/intl';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import TimeFormatService from 'teamtailor/services/time-format';
import UserModel from 'teamtailor/models/user';

export default class UserStatusModel extends Model {
  @service declare intl: IntlService;
  @service declare timeFormat: TimeFormatService;

  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;
  @attr('string', { defaultValue: '🏖️' }) declare emoji: string;
  @attr('string') declare status: string;
  @attr('date', { allowNull: true }) declare endDate: Date | null;
  @attr('boolean') declare notify: boolean;
  @attr('boolean') declare isLastActive: boolean;
  @attr('boolean') declare pauseNotifications: boolean;

  get formattedEndDate(): string | undefined {
    if (this.endDate) {
      const isToday = this.endDate.toDateString() === new Date().toDateString();
      const isTomorrow = moment().add(1, 'day').isSame(this.endDate, 'day');

      let dateFormat: string = this.timeFormat.format;
      let prefix = '';

      if (isTomorrow) {
        prefix = `${this.intl.t('models.user_status.tomorrow')} `;
      } else if (!isToday) {
        dateFormat = `Do MMM ${dateFormat}`;
      }

      const formattedDate = moment(this.endDate).format(dateFormat);
      return prefix + formattedDate;
    }
  }

  get statusText() {
    let statusText = this.status;

    if (this.endDate) {
      statusText += ` (${this.intl.t('components.user_status.until')} ${
        this.formattedEndDate
      })`;
    }

    return statusText;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-status': UserStatusModel;
  }
}
