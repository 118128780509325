import { helper } from '@ember/component/helper';

export default helper(function formatPrice([locale, price, currency]: [
  string,
  number,
  string,
]) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    // @ts-expect-error this is a TS issue, will be solved after update
    trailingZeroDisplay: 'stripIfInteger',
  }).format(price);
});
