import IntlService from 'ember-intl/services/intl';
import UserService from 'teamtailor/services/user';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import AnalyticsService from 'teamtailor/services/analytics';
import { DocumentNode, gql } from '@apollo/client/core';
import moment from 'moment-timezone';
import { MeetingEventTypeResponse } from 'teamtailor/utils/insights/graphql-response-types';
import ApplicationInstance from '@ember/application/instance';

const MEETINGS_QUERY = gql`
  query MeetingEventsQuery(
    $userId: ID!
    $jobIds: [ID!]
    $startDate: String
    $endDate: String
    $page: Int
    $perPage: Int
    $companyIds: [ID!]
  ) {
    meetingEvents(
      userScope: { userId: $userId }
      jobIds: $jobIds
      from: $startDate
      to: $endDate
      page: $page
      perPage: $perPage
      groupCompanyIds: $companyIds
    ) {
      id
      summary
      bookedSlot {
        startsAt
        endsAt
      }
      meetingEventAttendees {
        ... on MeetingEventAttendeeUser {
          user {
            id
          }
        }
      }
      meetingEventLocation {
        ... on MeetingEventLocationLocation {
          location {
            nameOrCity
          }
        }
        ... on MeetingEventLocationVideoMeeting {
          videoMeeting {
            providerName
            appName
          }
        }
      }
      meeting {
        job {
          title
        }
        candidates {
          id
          nameOrEmail
          initials
          color
          avatarImage {
            candidatePictureUrl
          }
          company {
            id
            name
          }
        }
      }
    }
  }
`;

export interface MeetingEvent extends MeetingEventTypeResponse {
  candidateName?: string;
  jobTitle?: string;
  durationInMinutes: number;
  numberOfUserParticipants: number;
}

async function fetchPagified(
  container: ApplicationInstance,
  query: DocumentNode,
  variables = {},
  page = 1,
  perPage = 100
): Promise<MeetingEventTypeResponse[]> {
  const { meetingEvents } = await container.lookup('service:apollo').query({
    query,
    variables: {
      ...variables,
      page,
      perPage,
    },
  });

  if (meetingEvents.length < perPage) {
    return meetingEvents;
  }

  const nextPage = await fetchPagified(
    container,
    query,
    variables,
    page + 1,
    perPage
  );
  return meetingEvents.concat(nextPage);
}

export default class ReportMeetings {
  container: ApplicationInstance;

  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;
  @service declare user: UserService;

  constructor({ container }: { container: ApplicationInstance }) {
    this.container = container;
  }

  fetch = task(async (): Promise<unknown> => {
    const calendarEvents = await fetchPagified(this.container, MEETINGS_QUERY, {
      jobIds: this.analytics.selectedJobIds,
      userId: this.user.content.id,
      startDate: moment(this.analytics.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.analytics.endDate).format('YYYY-MM-DD'),
      companyIds: this.analytics.availableCompanyIds,
    });

    return calendarEvents.map((event: MeetingEventTypeResponse) => {
      const loc = event.meetingEventLocation;
      const physicalLocation = loc?.location?.nameOrCity;
      const videoApp =
        loc?.videoMeeting?.appName || loc?.videoMeeting?.providerName;

      return {
        ...event,
        date: moment(event.bookedSlot.startsAt).format('YYYY-MM-DD'),
        candidateName: event.meeting.candidates[0]?.nameOrEmail,
        candidateId: event.meeting.candidates[0]?.id,
        candidate: event.meeting.candidates[0],
        companyName: event.meeting.candidates[0]?.company?.name,
        jobTitle: event.meeting.job?.title,

        location: physicalLocation
          ? `${this.intl.t('reports.meetings.in_office')} (${physicalLocation})`
          : videoApp
            ? `${this.intl.t('reports.meetings.video')} (${videoApp})`
            : null,

        numberOfUserParticipants: (event.meetingEventAttendees || []).filter(
          (a) => !!a.user
        ).length,

        durationInMinutes: moment(event.bookedSlot.endsAt).diff(
          moment(event.bookedSlot.startsAt),
          'minutes'
        ),
      };
    });
  });
}
