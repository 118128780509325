import { inject as service } from '@ember/service';
import { get } from 'teamtailor/utils/get';
import CandidateModalRoute from 'teamtailor/routes/candidate-modal';
import { jsonToBase64 } from 'teamtailor/utils/base-64';

export default class IndexRoute extends CandidateModalRoute {
  @service user;

  async model(queryParams) {
    const candidate = this.modelFor('candidates.candidate');

    let fromRoute = get(this.router, 'currentRoute');
    if (
      fromRoute.name.startsWith('jobs.job.stages.') &&
      fromRoute.attributes.job
    ) {
      const candidateHasAppliedToJob = await this.candidateHasAppliedToJob(
        fromRoute.attributes.job,
        candidate
      );

      if (candidateHasAppliedToJob) {
        this.router.replaceWith(
          'jobs.job.stages.index.candidate',
          fromRoute.attributes.job,
          candidate.id,
          {
            queryParams,
          }
        );
        return {};
      }
    }

    return candidate;
  }

  afterModel(candidate, { queryParams }) {
    let fromRoute = get(this, 'router.currentRouteName');
    const q =
      queryParams && Object.keys(queryParams).length
        ? jsonToBase64(queryParams)
        : null;

    if (fromRoute.indexOf('candidates.segment.') === 0) {
      this.router.replaceWith(
        'candidates.segment.candidate',
        'all',
        candidate.id,
        {
          queryParams: {
            q,
          },
        }
      );
    }

    this.recentVisits.log(candidate);
  }

  async candidateHasAppliedToJob(job, candidate) {
    const jobApplications = await get(candidate, 'jobApplications');
    return !!jobApplications.find((ja) => {
      return ja.belongsTo('job').id() === job.id;
    });
  }
}
