import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.onDelete}}\n  {{#ember-smooth/if this.showDeleteButton}}\n    <div class=\"p-[1px]\">\n      <Button\n        @isDestructive={{true}}\n        @onClick={{this.onDelete}}\n        @text={{t \"common.delete\"}}\n      />\n    </div>\n  {{/ember-smooth/if}}\n{{/if}}\n\n<div class=\"c-form-expandable-box__footer__actions flex items-center space-x-8\">\n  {{#if this.onCancel}}\n    <Button\n      @appearance=\"tertiary\"\n      @onClick={{this.onCancel}}\n      @text={{t \"common.cancel\"}}\n    />\n  {{/if}}\n  {{#if this.onSave}}\n    {{#ember-smooth/if-x this.showSaveButton}}\n      <StatusButton\n        @action={{this.onSave}}\n        @text={{if @saveText @saveText (t \"common.save\")}}\n      />\n    {{/ember-smooth/if-x}}\n  {{/if}}\n</div>", {"contents":"{{#if this.onDelete}}\n  {{#ember-smooth/if this.showDeleteButton}}\n    <div class=\"p-[1px]\">\n      <Button\n        @isDestructive={{true}}\n        @onClick={{this.onDelete}}\n        @text={{t \"common.delete\"}}\n      />\n    </div>\n  {{/ember-smooth/if}}\n{{/if}}\n\n<div class=\"c-form-expandable-box__footer__actions flex items-center space-x-8\">\n  {{#if this.onCancel}}\n    <Button\n      @appearance=\"tertiary\"\n      @onClick={{this.onCancel}}\n      @text={{t \"common.cancel\"}}\n    />\n  {{/if}}\n  {{#if this.onSave}}\n    {{#ember-smooth/if-x this.showSaveButton}}\n      <StatusButton\n        @action={{this.onSave}}\n        @text={{if @saveText @saveText (t \"common.save\")}}\n      />\n    {{/ember-smooth/if-x}}\n  {{/if}}\n</div>","moduleName":"teamtailor/components/form-expandable-box/footer.hbs","parseOptions":{"srcName":"teamtailor/components/form-expandable-box/footer.hbs"}});
import Component from '@ember/component';

export default Component.extend({
  classNames: ['c-form-expandable-box__footer'],

  onCancel: null,
  onDelete: null,
  onSave: null,

  showDeleteButton: true,
  showSaveButton: true,
});
