import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import MeetingRoomController from 'teamtailor/controllers/settings/meeting-rooms';
import Store from '@ember-data/store';

export default class MeetingRoomsRoute extends ScrollToTopRoute {
  @service declare store: Store;

  async model() {
    const meetingRoomResources = await this.store.findAll(
      'meeting-room-resource'
    );
    const meetingRooms = await this.store.findAll('meeting-room');
    const authorizations = await this.store.findAll('authorization', {
      reload: true,
    });

    return {
      meetingRoomResources,
      meetingRooms,
      authorizations,
    };
  }

  resetController(_controller: MeetingRoomController, isExiting: boolean) {
    if (isExiting) {
      this.store.unloadAll('meeting-room-resource');
    }
  }
}
