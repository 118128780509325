import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { query } from 'ember-data-resources';
import { TableColumn } from 'teamtailor/components/table/container';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

export default class SettingOrganizationDepartmentsIndexController extends Controller {
  @service declare current: Current;

  @tracked search = '';
  @tracked page = 1;

  @tracked sort_column = 'name';
  @tracked sort_direction = 'asc';

  queryParams = ['search', 'page', 'sort_direction'];

  get careerSites() {
    return get(this.current.company, 'careerSites');
  }

  get departments() {
    return this.departmentsQuery.records;
  }

  get hasCareerSiteLanguages() {
    return (
      this.current.company.hasFeature('career_site_languages') &&
      this.careerSites.length > 1
    );
  }

  get tableColumns() {
    const columns: TableColumn[] = [
      {
        label: 'common.department',
        sortable: true,
        sortColumn: 'name',
        defaultSortOrder: 'asc',
        sortOrder: 1,
      },
      {
        label: 'user.roles',
        sortable: false,
        sortOrder: 2,
      },
      {
        label: 'user.manager',
        sortable: false,
        sortOrder: 3,
      },
    ];

    if (this.hasCareerSiteLanguages) {
      columns.push({
        label: 'common.languages',
        sortable: false,
        sortOrder: 4,
      });
    }

    if (this.current.company.hasFeature('divisions')) {
      columns.push({
        label: 'settings.departments.division',
        sortable: false,
        sortOrder: 5,
      });
    }

    return columns;
  }

  handleSearchInput = restartableTask(async (value) => {
    await timeout(1000);
    this.search = value;
    this.page = 1;
  });

  departmentsQuery = query(this, 'department', () => {
    return {
      per_page: 20,
      query: this.search,
      page: this.page || 1,
      sort_column: this.sort_column,
      sort_direction: this.sort_direction,
      no_no_department: true,
    };
  });
}

declare module '@ember/controller' {
  interface Registry {
    'settings.organization.departments.index': SettingOrganizationDepartmentsIndexController;
  }
}
