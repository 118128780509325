import { get } from 'teamtailor/utils/get';
import ScrollToTopRoute from 'teamtailor/routes/scroll-to-top';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import SettingsOrganizationDivisionsEditController from 'teamtailor/controllers/settings/organization/divisions/edit';

export default class SettingsOrganizationDivisionsEditRoute extends ScrollToTopRoute {
  @service declare store: Store;

  model(params: { division_id: string }) {
    return this.store.findRecord('division', params.division_id);
  }

  resetController(
    controller: SettingsOrganizationDivisionsEditController,
    isExiting: boolean
  ) {
    if (isExiting && !get(controller.model, 'isDeleted')) {
      controller.model.rollbackAttributes();
    }
  }
}
